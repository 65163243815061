<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-step-1">
          <div class="modal-header">
            <span class="modal-header-txt">{{ $t('therapy_settings') }}</span>
            <span class="modal-header-description">{{ $t('therapy_settings_here') }}</span>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{ $t('published') }}
              </span>
              <span class="setting-text">
                {{ $t('published_or_draft') }}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.isPublish" />
            </div>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{ $t('therapy_image') }}
              </span>
              <span class="setting-text">
                {{ $t('therapy_card_image') }}
              </span>
            </div>
            <div class="setting-right">
              <FileDrop :on-file-selected="handleTherapyImage" :selected-file="settings.avatarUrl" :attach="settings.avatar" :on-delete="handleTherapyImageDelete" />
            </div>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('start_at')}}
              </span>
              <span class="setting-text">
                {{$t('program_available_from')}}
              </span>
            </div>
            <div class="setting-right">
              <input type="datetime-local" class="form-input" @input="handleStartDate" :value="getStartDate" />
            </div>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('finish_at')}}
              </span>
              <span class="setting-text">
                {{$t('program_until_from')}}
              </span>
            </div>
            <div class="setting-right">
              <input type="datetime-local" class="form-input" @input="handleFinishDate" :value="getFinishDate" />
            </div>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('allow_retake')}}
              </span>
              <span class="setting-text">
                {{$t('respondent_can_retake_program')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.isRetry" />
            </div>
          </div>


          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('hr_module')}}
              </span>
              <span class="setting-text">
                {{$t('see_journey_user')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch :model-value="settings.type === '1'" :on-toggle="handleHrToggle"/>
            </div>
          </div>

          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import Switch from "@/components/Switch.vue";
import FileDrop from "@/components/FileDrop.vue";

export default {
  components: {
    FileDrop,
    Switch,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    settings: {
      type: Object,
      default: () => ({
        isRetry: false,
        retryTime: 90,
        startAt: null,
        finishAt: null,
      }),
    },
  },
  data() {
    return {
      startDate: '',
      finishDate: '',
      startSet: false,
      finishSet: false,
    }
  },
  methods: {
    handleStartDate(event) {
      this.startSet = true;
      this.settings.startAt = this.formatDate(event.target.value);
    },
    handleFinishDate(event) {
      this.finishSet = true;
      this.settings.finishAt = this.formatDate(event.target.value);
    },
    handleTherapyImage(file) {
      this.settings.avatarDelete = false;
      this.settings.avatar = file;
      this.settings.avatarUrl = null
    },
    handleTherapyImageDelete() {
      this.settings.avatarDelete = true;
      this.settings.avatar = null;
      this.settings.avatarUrl = null;
    },
    handleHrToggle(value) {
      this.settings.type = value ? '1' : '0';
    },
    formatDate(date) {
      if (!date) return '';
      if (date === '') return '';

      const selectedDate = new Date(date);
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
      const formatter = new Intl.DateTimeFormat("en-GB", options);
      return formatter.format(selectedDate).replace(/\//g, '.').replace(/[/,]/g, '');
    },
    formattedToIso(inputString) {
      if (!inputString) return '';
      if (inputString === '') return '';

      let datePart, timePart;

      if (inputString.includes('T')) {
        [datePart, timePart] = inputString.split('T');
      } else if (inputString.includes(' ')) {
        [datePart, timePart] = inputString.split(' ');
      } else {
        return '';
      }

      if (!datePart || !timePart) return '';

      let year, month, day;

      if (datePart.includes('.')) {
        [day, month, year] = datePart.split('.');
      } else if (datePart.includes('-')) {
        [year, month, day] = datePart.split('-');
      } else {
        return '';
      }

      const [hours, minutes] = timePart.split(':');

      const formattedDate = new Date(year, month - 1, day, hours, minutes);

      return formattedDate.toISOString().slice(0, 16);
    },
  },
  computed: {
    getStartDate() {
      return this.formattedToIso(this.settings.startAt);
    },
    getFinishDate() {
      return this.formattedToIso(this.settings.finishAt);
    }
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
h5 {
  font-family: euclid_medium, sans-serif;
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.modal-header-description {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.setting-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.setting-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.setting-name {
  font-family: euclid_medium, sans-serif;
  color: #060B27;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
}

.setting-text {
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.condition-div span {
  position: absolute;
  display: block;
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  font-size: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}

.condition-dropdown img, .range-dropdown img {
  width: 18px;
  cursor: pointer;
}

.condition-dropdown .line, .range-dropdown .line {
  position: absolute;
  left: -12px;
  top: 50%;
  width: 12px;
  transform: translateY(-50%);
  height: 1px;
  background-color: #1f2b3b;
}

.range-dropdown .range-left {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-right: 1px solid #e9ecf0;
  padding-right: 20px;
}

.range-left input {
  width: 100%;
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  background-color: transparent;
  line-height: 20px;
  padding: 0;
}

.range-dropdown .range-right {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-left: 20px;
}

</style>
