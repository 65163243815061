<template>
  <Transition name="slide-fade-rtl" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-step-1">
          <div class="modal-header">
            <span class="modal-header-txt">{{$t('module_settings')}}</span>
            <span class="modal-header-description">{{$t('module_settings_two')}}</span>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('module_image')}}
              </span>
              <span class="setting-text">
                {{$t('module_background')}}
              </span>
            </div>
            <div class="setting-right">
              <FileDrop :on-file-selected="handleImage" :selected-file="module.avatarUrl" :attach="module.avatar" :on-delete="handleImageDelete" />
            </div>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('delete_module')}}
              </span>
              <span class="setting-text">
                {{$t('delete_all_module')}}
              </span>
            </div>
            <div class="setting-right">
              <button :class="deleteSure ? 'delete-button sure' : 'delete-button'" @click="handleDelete">
                {{ deleteSure ? this.$t('are_you_sure') : this.$t('delete_button') }}
              </button>
            </div>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('sub_modules')}}
              </span>
              <span class="setting-text">
                {{$t('sub_modules_settings')}}
              </span>
            </div>
            <div class="setting-right">
            </div>
          </div>

          <table>
            <thead>
            <tr>
              <th>{{$t('sub_module')}}</th>
              <th>{{$t('duration_enabled')}}</th>
              <th>{{$t('duration')}}</th>
              <th>{{$t('action')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="sub in module.subModules">
              <td>{{ sub.name }}</td>
              <td>
                <Switch v-model="sub.durationEnabled" :on-toggle="(e) => handleSubDurationChange(e, sub)"/>
              </td>
              <td>
                <input v-if="sub.durationEnabled" class="form-input-sm" v-model="sub.duration" type="number"/>
              </td>
              <td>
                <img src="@/assets/images/trashOutline.svg" @click="onDeleteSubModule(sub)"/>
              </td>
            </tr>
            </tbody>
          </table>


          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import Switch from "@/components/Switch.vue";
import FileDrop from "@/components/FileDrop.vue";
import blogService from "@/service/blogService";

export default {
  components: {
    FileDrop,
    Switch,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    module: {
      type: Object,
      default: {
        avatar: null,
        avatarUrl: null,
        avatarDelete: null,
        subModules: [],
      }
    },
    onDeleteModule: {
      type: Function,
      default: () => {}
    },
    onDeleteSubModule: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      deleteSure: false,
      deleteTimer: null,
    };
  },
  methods: {
    handleImage(file) {
      this.module.avatar = file;
      this.module.avatarUrl = null
      this.avatarDelete = false;
    },
    handleImageDelete() {
      this.module.avatar = null;
      this.module.avatarUrl = null;
      this.avatarDelete = true;
    },
    handleSubDurationChange(e, sub) {
      if (!e) {
        sub.duration = null;
      } else {
        sub.duration = 0;
      }
    },
    handleDelete() {
      if (this.deleteSure) {
        clearTimeout(this.deleteTimer);
        this.deleteSure = false;
        this.onDeleteModule();
      } else {
        this.deleteSure = true;

        this.deleteTimer = setTimeout(() => {
          this.deleteSure = false;
        }, 3000);
      }
    }
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
h5 {
  font-family: euclid_medium, sans-serif;
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.modal-header-description {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.setting-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.setting-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.setting-name {
  font-family: euclid_medium, sans-serif;
  color: #060B27;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
}

.setting-text {
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.condition-div span {
  position: absolute;
  display: block;
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  font-size: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}

.condition-dropdown img, .range-dropdown img {
  width: 18px;
  cursor: pointer;
}

.condition-dropdown .line, .range-dropdown .line {
  position: absolute;
  left: -12px;
  top: 50%;
  width: 12px;
  transform: translateY(-50%);
  height: 1px;
  background-color: #1f2b3b;
}

.range-dropdown .range-left {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-right: 1px solid #e9ecf0;
  padding-right: 20px;
}

.range-left input {
  width: 100%;
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  background-color: transparent;
  line-height: 20px;
  padding: 0;
}

.range-dropdown .range-right {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-left: 20px;
}

table {
  margin-top: 20px;
}

table thead {
  background-color: #F2F4F7;
  border-radius: 12px;
}

table thead th {
  font-family: euclid_medium, sans-serif;
  color: #1f2b3b;
  font-size: 14px;
  line-height: 20px;
}

table tbody {
  border-radius: 12px;
}

table tbody td {
  font-size: 12px;
  font-family: euclid_regular, sans-serif;
  color: #1f2b3b;
}

.delete-button {
  font-family: euclid_regular, sans-serif;
  color: #F44336;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  border: 1px solid #F44336;
  background: none;
  cursor: pointer;
  padding: 5px 20px;
  border-radius: 8px;
  transition: all 0.2s;
}

.delete-button:hover {
  background-color: #F44336;
  color: white;
}

.delete-button.sure {
  background-color: #F44336;
  color: white;
  border: 1px solid #F44336;
}
</style>
