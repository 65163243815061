<template>
  <div class="flex-row">
    <div class="row-item no-text-select" v-if="showTherapyNumberFilter" v-click-outside="() => closeFilter('therapy_number')">
      <div @click="toggleTherapyNumberFilter">
        <img src="../../../assets/images/filtertherapynumber.svg" class="row-item-icon" />
        <span class="row-item-txt">
          {{$t('therapy_number')}}{{programNumberFilterData.min !== undefined || programNumberFilterData.max !== undefined ? ':' : ''}} <span class="show-filters">{{programNumberFilterData.min ? 'Min: ' + programNumberFilterData.min : ''}} {{programNumberFilterData.min !== undefined && programNumberFilterData.max !== undefined ? ' - ' : ''}} {{programNumberFilterData.max ? 'Max: ' + programNumberFilterData.max : ''}}</span>
          </span>
      </div>
      <MinMaxNumberFilter
          v-if="showTherapyNumberDropdown"
          :visible.sync="showTherapyNumberDropdown"
          :on-filter="handleTherapyNumberFilter"
      />
      <img src="../../../assets/images/cross.svg" @click="() => removeFilter('therapy_number')" />
    </div>

    <div class="row-item no-text-select" v-if="showUserAmountFilter"  v-click-outside="() => closeFilter('user_amount')">
      <div @click="toggleUserAmountFilter" >
        <img src="../../../assets/images/fitleruser.svg" class="row-item-icon" />
        <span class="row-item-txt">
          {{$t('user_amount')}}{{userAmountFilterData.min !== undefined || userAmountFilterData.max !== undefined ? ':' : ''}} <span class="show-filters">{{userAmountFilterData.min ? 'Min: ' + userAmountFilterData.min : ''}} {{userAmountFilterData.min !== undefined && userAmountFilterData.max !== undefined ? ' - ' : ''}} {{userAmountFilterData.max ? 'Max: ' + userAmountFilterData.max : ''}}</span>
        </span>
      </div>
      <MinMaxNumberFilter
          v-if="showUserAmountDropdown"
          :visible.sync="showUserAmountDropdown"
          :on-filter="handleUserAmountFilter"
      />
      <img src="../../../assets/images/cross.svg" @click="() => removeFilter('user_amount')" />
    </div>

    <div class="row-item no-text-select" v-if="showDateRangeFilter" v-click-outside="() => closeFilter('date_range')">
      <div @click="toggleDateRangeFilter">
        <img src="../../../assets/images/filterdaterange.svg" class="row-item-icon"/>
        <span class="row-item-txt">
          {{$t('calendar')}}{{dateRangeFilterData.startDate || dateRangeFilterData.endDate ? ':' : ''}} <span class="show-filters"> {{dateRangeFilterData.startDate ? dateRangeFilterData.startDate : ''}}{{dateRangeFilterData.startDate && dateRangeFilterData.endDate ? ' / ' : ''}}{{dateRangeFilterData.endDate ? dateRangeFilterData.endDate : ''}}</span>
          </span>
      </div>
      <DateFilter
          v-if="showDateRangeDropdown"
          :visible.sync="showDateRangeDropdown"
          :on-filter="handleApplyDateFilters"
      />
      <img src="../../../assets/images/cross.svg" @click="() => removeFilter('date_range')" />
    </div>

    <div class="row-item filter" v-click-outside="() => closeFilter('main')" @click="toggleFilter">
      <img src="../../../assets/images/filter.svg">
      <span class="row-item-txt no-text-select">
            {{ $t('filter_therapy') }}
        </span>
      <FilterModal :visible.sync="showFilter" @filter-selected="handleFilterSelected"/>
    </div>
  </div>
</template>

<script>
import DateFilter from "@/components/table-filters/DateFilter.vue";
import FilterModal from "@/components/tables/Company/CompanyFilterModal.vue";
import MinMaxNumberFilter from "@/components/table-filters/MinMaxNumberFilter.vue";

export default {
  components: {
    MinMaxNumberFilter,
    DateFilter,
    FilterModal
  },
  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {
        if (this.$route.query.programNumberMin) {
          this.showTherapyNumberFilter = true;
          this.programNumberFilterData.min = this.$route.query.programNumberMin;
        } else {
          this.programNumberFilterData.min = undefined
        }

        if (this.$route.query.programNumberMax) {
          this.showTherapyNumberFilter = true;
          this.programNumberFilterData.max = this.$route.query.programNumberMax;
        } else {
          this.programNumberFilterData.max = undefined
        }

        if (this.$route.query.addedDate) {
          this.showDateRangeFilter = true;
          this.dateRangeFilterData.startDate = this.$route.query.addedDate;
        } else {
          this.dateRangeFilterData.startDate = undefined
        }

        if (this.$route.query.addedDateEnd) {
          this.showDateRangeFilter = true;
          this.dateRangeFilterData.endDate = this.$route.query.addedDateEnd;
        } else {
          this.dateRangeFilterData.endDate = undefined
        }

        if (this.$route.query.userAmountMin) {
          this.showUserAmountFilter = true;
          this.userAmountFilterData.min = this.$route.query.userAmountMin;
        } else {
          this.userAmountFilterData.min = undefined
        }

        if (this.$route.query.userAmountMax) {
          this.showUserAmountFilter = true;
          this.userAmountFilterData.max = this.$route.query.userAmountMax;
        } else {
          this.userAmountFilterData.max = undefined
        }
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      showFilter:false,

      showTherapyNumberFilter:false,
      showTherapyNumberDropdown:false,

      programNumberFilterData: [{
        min: undefined,
        max: undefined,
      }],

      showUserAmountFilter:false,
      showUserAmountDropdown:false,
      userAmountFilterData: [{
        min: undefined,
        max: undefined,
      }],

      showDateRangeFilter: false,
      showDateRangeDropdown: false,
      dateRangeFilterData: [{
        startDate: undefined,
        endDate: undefined,
      }],
    }
  },
  methods: {
    handleFilterSelected(filterName) {
      this.closeFilter('main');
      if (filterName === 'therapy_number') {
        this.showTherapyNumberFilter = true;
        this.showTherapyNumberDropdown = true;
      } else if (filterName === 'user_amount') {
        this.showUserAmountFilter = true;
        this.showUserAmountDropdown = true;
      } else if (filterName === 'date_range') {
        this.showDateRangeFilter = true;
        this.showDateRangeDropdown = true;
      }
    },
    formatDateForBackend(dateString) {
      if (!dateString) return undefined;
      const [day, month, year] = dateString.split('-');
      return `${year}-${month}-${day}`;
    },

    handleTherapyNumberFilter(filter){
      this.programNumberFilterData = filter;
      this.showTherapyNumberDropdown = false;

      this.$router.push({
        query: {
          ...this.$route.query,
          programNumberMin: this.programNumberFilterData.min,
          programNumberMax: this.programNumberFilterData.max
        }
      });
    },
    handleUserAmountFilter(filter){
      this.userAmountFilterData.max = filter.max;
      this.userAmountFilterData.min = filter.min;
      this.showUserAmountDropdown = false;

      this.$router.push({
        query: {
          ...this.$route.query,
          userAmountMin: this.userAmountFilterData.min,
          userAmountMax: this.userAmountFilterData.max
        }
      });
    },
    handleApplyDateFilters(filters){
      this.dateRangeFilterData.startDate = filters.startDate;
      this.dateRangeFilterData.endDate = filters.endDate;
      this.showDateRangeDropdown = false;

      this.$router.push({
        query: {
          ...this.$route.query,
          addedDate: this.formatDateForBackend(filters.startDate),
          addedDateEnd: this.formatDateForBackend(filters.endDate)
        }
      });
    },

    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    toggleTherapyNumberFilter() {
      this.showTherapyNumberDropdown = !this.showTherapyNumberDropdown;
    },
    toggleUserAmountFilter() {
      this.showUserAmountDropdown = !this.showUserAmountDropdown;
    },
    toggleDateRangeFilter() {
      this.showDateRangeDropdown = !this.showDateRangeDropdown;
    },

    closeFilter(filter) {
      if (filter === 'main' && this.showFilter === true) {
        this.showFilter = false;
      } else if (filter === 'therapy_number' && this.showTherapyNumberDropdown === true) {
        this.showTherapyNumberDropdown = false;
      } else if (filter === 'user_amount' && this.showUserAmountDropdown === true) {
        this.showUserAmountDropdown = false;
      } else if (filter === 'date_range' && this.showDateRangeDropdown === true) {
        this.showDateRangeDropdown = false;
      }
    },
    removeFilter(filter) {
      if (filter === 'therapy_number') {
        this.showTherapyNumberFilter = false;
        this.programNumberFilterData = [{
          min: undefined,
          max: undefined,
        }];

        this.$router.push({
          query: {
            ...this.$route.query,
            programNumberMin: undefined,
            programNumberMax: undefined
          }
        })
      } else if (filter === 'user_amount') {
        this.showUserAmountFilter = false;
        this.userAmountFilterData = [{
          min: undefined,
          max: undefined,
        }];

        this.$router.push({
          query: {
            ...this.$route.query,
            userAmountMin: undefined,
            userAmountMax: undefined
          }
        })
      } else if (filter === 'date_range') {
        this.showDateRangeFilter = false;
        this.dateRangeFilterData = [{
          start: undefined,
          end: undefined,
        }];
        this.$router.push({
          query: {
            ...this.$route.query,
            addedDate: undefined,
            addedDateEnd: undefined
          }
        })
      }
    },
  }
}
</script>

<style scoped>

.flex-row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
}
.row-item{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}

.row-item.filter {
  background-color: transparent;
}

.row-item-txt{
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
}

.show-filters{
  font-family: euclid_medium, sans-serif;
  color: #4A68F8;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
}
</style>
