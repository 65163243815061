<template>
  <div class="filter-view">
    <ExpertFilters />

    <div class="justify-end-div">
      <div class="justify-end-search">
        <ExpandableSearch :searchText="searchText" />
      </div>
      <div class="justify-end-add-btn no-text-select" @click="addNewUser">
        <img src="../../../assets/images/plus.svg">
        <span class="justify-end-addbtn-txt">{{$t('add_new')}}</span>
      </div>
    </div>

  </div>

  <div class="table-container">
    <table class="content-table">
      <thead class="header-table">
      <tr class="trhead">
        <th><input type="checkbox"></th>
        <th>{{$t('name_payment')}}</th>
        <th>{{$t('title_expert')}}</th>
        <th>{{$t('language_settings')}}</th>
        <th style="text-align: start">{{$t('total_therapies')}} <img src="../../../assets/images/status.svg"></th>
        <th style="text-align: center;justify-content: start;display: flex">{{$t('scheduled_status')}} <img src="../../../assets/images/status.svg"></th>
        <th>{{$t('categories_therapy')}}</th>
      </tr>
      </thead>
      <tbody class="tbody-container">
      <tr class="tr-class" v-if="!loading" v-for="expert in experts" :key="expert.expertId">
        <td class="td-style"><input type="checkbox"></td>
        <td class="td-style">
          <router-link :to="'/experts/detail/' + expert.expertId" class="name-row">
            <img class="company-icon" :src="expert.avatarUrl" v-if="expert.avatarUrl" />
            <span class="test-class">{{ expert.name }} {{expert.surname}}</span>
          </router-link>
        </td>
        <td class="td-style"> <span class="test-class">  </span></td>
        <td class="td-style flags">
          <img src="../../../assets/flags/tr.png" />
          <img src="../../../assets/flags/en.png" />
        </td>
        <td style="text-align: center" class="td-style"> <span class="test-class"> <img src="../../../assets/images/therapies.svg">0 programs</span></td>
        <td class="td-style"><span class="test-class"><img src="../../../assets/images/calendarTickGreen.svg">0 programs</span></td>
        <td class="td-style">
          <span class="category-tag">{{$t('tags_therapy')}}</span>
        </td>
      </tr>
      <tr v-else>
        <td colspan="7" class="table-loading">
          <VueSpinner size="16" height="100%" color="#8c8c8c" />
          <span class="text">{{$t('loading')}}</span>
        </td>
      </tr>
      <tr v-if="!loading && experts.length === 0 ">
        <td colspan="7" class="table-loading">
          <span class="text">{{$t('no_data_found')}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AddUserModal from '@/components/modals/InviteUserModal.vue';
import FilterModal from '@/components/tables/Company/CompanyFilterModal.vue';
import CompanyFieldFilter from '@/components/table-filters/CompanyFieldFilter.vue';
import TherapyFilter from "@/components/table-filters/NumberFilter.vue";
import UserAmountFilter from "@/components/table-filters/MinMaxNumberFilter.vue";
import PaymentVolume from "@/components/table-filters/PaymentVolume.vue";
import DateFilter from "@/components/table-filters/DateFilter.vue";
import {VueSpinner} from "vue3-spinners";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import ExpertFilters from "@/components/tables/Expert/ExpertFilters.vue";

export default {
  components: {
    ExpertFilters,
    DateFilter,
    PaymentVolume,
    UserAmountFilter,
    AddUserModal,
    FilterModal,
    CompanyFieldFilter,
    TherapyFilter,
    ExpandableSearch,
    VueSpinner
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    addNewUser: {
      type: Function,
      default: () => {}
    },
    experts: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      showModal: false,
      selectAll:false,
      showFilter:false,
      searchText: '',
    }
  },
  methods: {


  },

}
</script>

<style>
.table-container {
  width: 100%;
  height: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: left;
  padding: 8px;
}
.trhead{
  background-color: transparent;
}
.tr-class{
  border-bottom-width: 1px;
  border-bottom-color: #F2F4F7;
}

tr td {
  padding-bottom: 12px;
  padding-top: 12px;
  align-items: center;
}
.table-container {
  width: 100%;
}
tr th{
  align-items: center;
  font-size: 12px;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.header-table,
.content-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.header-table {
  background-color: #F9FAFB;
  border-radius: 8px;
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #F2F4F7;
}

.tbody-container {
  overflow-y: auto;
}
.tr-class td tr {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.test-class{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
}
.flex-row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
}
.row-item{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}

.row-item.filter {
  background-color: transparent;
}

.row-item-txt{
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
}

.filter-view{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  min-height: 52px;
}
.justify-end-div{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.justify-end-search{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
}
.justify-end-add-btn{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 8px;
  background-color: #040C2D;
  cursor: pointer;
}
.justify-end-addbtn-txt{
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #FFF;
}

.company-icon {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.show-filters{
  font-family: euclid_medium, sans-serif;
  color: #4A68F8;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
}

.row-item-icon {
  height: 16px;
  width: 16px;
}

.name-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.name-row span {
  font-family: euclid_medium, sans-serif;
  color: #000;
}

.name-row:hover.name-row span{
  color: #4A68F8;
}

.flags {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  height: 100%;
}

.flags img {
  height: 18.66px;
  width: 28px;
  border-radius: 4px;
}

.category-tag {
  margin-right: 5px;
}

</style>
