<template>
  <div class="total-rating-wrapper">
    <div class="left-column" v-if="!loading">
      <span class="total-number">4.8</span>
      <div class="stars-row">
        <img src="@/assets/images/starFill.svg" />
        <img src="@/assets/images/starFill.svg" />
        <img src="@/assets/images/starFill.svg" />
        <img src="@/assets/images/starFill.svg" />
        <img src="@/assets/images/starFill.svg" />
      </div>
      <span class="description-text">Expert Rating</span>
    </div>

    <div class="left-column" v-else>
      <free-style-shimmer width="100px" height="50px" border-radius="9px" color="#EAECF0" />
    </div>


    <div class="right-column" v-if="!loading">
      <div class="progress-bar-row">
        <div class="progress-bar">
          <div class="progress-bar-fill" :style="`width: ${scoreAverages[1].percentage}%`"></div>
        </div>

        <div class="progress-bar-label-wrapper">
          <span class="progress-bar-label">5</span>
          <img src="@/assets/images/starFill.svg" />
        </div>

        <span class="progress-bar-percent">
          {{scoreAverages[1].percentage}}%
        </span>
      </div>

      <div class="progress-bar-row">
        <div class="progress-bar">
          <div class="progress-bar-fill" :style="`width: ${scoreAverages[2].percentage}%`"></div>
        </div>

        <div class="progress-bar-label-wrapper">
          <span class="progress-bar-label">4</span>
          <img src="@/assets/images/starFill.svg" />
        </div>

        <span class="progress-bar-percent">
          {{scoreAverages[2].percentage}}%
        </span>
      </div>

      <div class="progress-bar-row">
        <div class="progress-bar">
          <div class="progress-bar-fill" :style="`width: ${scoreAverages[3].percentage}%`"></div>
        </div>

        <div class="progress-bar-label-wrapper">
          <span class="progress-bar-label">3</span>
          <img src="@/assets/images/starFill.svg" />
        </div>

        <span class="progress-bar-percent">
          {{scoreAverages[3].percentage}}%
        </span>
      </div>

      <div class="progress-bar-row">
        <div class="progress-bar">
          <div class="progress-bar-fill" :style="`width: ${scoreAverages[4].percentage}%`"></div>
        </div>

        <div class="progress-bar-label-wrapper">
          <span class="progress-bar-label">2</span>
          <img src="@/assets/images/starFill.svg" />
        </div>

        <span class="progress-bar-percent">
          {{scoreAverages[4].percentage}}%
        </span>
      </div>

      <div class="progress-bar-row">
        <div class="progress-bar">
          <div class="progress-bar-fill" :style="`width: ${scoreAverages[5].percentage}%`"></div>
        </div>

        <div class="progress-bar-label-wrapper">
          <span class="progress-bar-label">1</span>
          <img src="@/assets/images/starFill.svg" />
        </div>

        <span class="progress-bar-percent">
          {{scoreAverages[5].percentage}}%
        </span>
      </div>
    </div>

    <div class="right-column" v-else>
      <free-style-shimmer width="450px" height="8px" border-radius="24px" color="#EAECF0" />
      <free-style-shimmer width="450px" height="8px" border-radius="24px" color="#EAECF0" />
      <free-style-shimmer width="450px" height="8px" border-radius="24px" color="#EAECF0" />
      <free-style-shimmer width="450px" height="8px" border-radius="24px" color="#EAECF0" />
      <free-style-shimmer width="450px" height="8px" border-radius="24px" color="#EAECF0" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    scoreAverages: {
      type: Object,
      default: ({
        1: {
          averageScore: 0,
          percentage: 0
        },
        2: {
          averageScore: 0,
          percentage: 0
        },
        3: {
          averageScore: 0,
          percentage: 0
        },
        4: {
          averageScore: 0,
          percentage: 0
        },
        5: {
          averageScore: 0,
          percentage: 0
        },
      })
    }
  },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped>
.total-rating-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.left-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px
}

.total-number {
  font-family: euclid_medium, sans-serif;
  font-size: 56px;
  color: #101828;
  line-height: 1;
}

.stars-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.description-text {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #98A2B3;
}

.right-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.progress-bar-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.progress-bar {
  width: 450px;
  height: 6px;
  background-color: #EAECF0;
  border-radius: 24px;
  position: relative;
}

.progress-bar-fill {
  height: 100%;
  background-color: #F09460;
  border-radius: 24px;
}

.progress-bar-label-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.progress-bar-label {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #F09460;
}

.progress-bar-percent {
  font-family: euclid_semi_bold, sans-serif;
  font-size: 12px;
  color: #667085;
}
</style>
