<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">

        <div class="modal-header">
          <span class="modal-header-txt">Availability</span>
        </div>

        <div class="selectbox-row">
          <SelectBox
              class="selectbox"
              :options="yearList"
              placeholder="Year"
              :selected-value="availabilityData.year.toString()"
              :on-select="handleYearSelect"
          />

          <SelectBox
              class="selectbox"
              :options="monthList"
              placeholder="Month"
              :selected-value="availabilityData.month"
              :on-select="handleMonthSelect"
          />

          <SelectBox
              class="selectbox"
              :options="durationList"
              placeholder="Duration"
              :selected-value="availabilityData.duration"
              :on-select="handleDurationSelect"
          />
        </div>

        <div class="availability-row no-text-select">
          <div class="availability-box" v-for="item in dayList" :class="{'checked': item.value === availabilityData.day}" @click="handleDaySelect(item)">
            <span class="date-name">{{item.name}}</span>
          </div>
        </div>

        <div class="time-slot-list">
          <div class="time-slot-row" v-for="(timeSlot, timeSlotId) in timeSlotList" :key="timeSlotId">
            <div @click="checkTimeSlot(timeSlotId)">
              <img src="../../assets/images/checkboxChecked.svg" v-if="checkIsSelected(timeSlotId)" />
              <img src="../../assets/images/checkbox.svg" v-if="!checkIsSelected(timeSlotId)" />
            </div>
            <input type="time" class="form-input" :value="timeSlot.startTime" disabled />
            <span class="time-slot-separator">-</span>
            <input type="time" class="form-input" :value="timeSlot.endTime" disabled />
          </div>
        </div>

        <button class="add-slot-button" @click="onAddSlot">
          <img src="@/assets/images/plusBlue.svg" class="plus-icon" />
          Add Slot
        </button>

        <div class="btn-div2">
          <div></div>
          <div class="action-button" @click="onSave">
            <span v-if="!isLoading">Save</span>
            <VueSpinnerIos v-if="isLoading" size="20" height="100%" color="#fff" />
          </div>
        </div>
        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import expertService from "@/service/expertService";

import {VueSpinnerIos} from 'vue3-spinners'
import SelectBox from "@/components/SelectBox.vue";

export default {
  components: {
    SelectBox,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {
      }
    },
    expertId: {
      type: String,
      default: ""
    },
  },
  data() {
    return {
      isLoading: false,
      yearList: [
        {value: "2024", text: "2024"},
        {value: "2025", text: "2025"},
        {value: "2026", text: "2026"},
        {value: "2027", text: "2027"},
        {value: "2028", text: "2028"},
        {value: "2029", text: "2029"},
        {value: "2030", text: "2030"},
      ],
      monthList: [
        {value: "1", text: "January"},
        {value: "2", text: "February"},
        {value: "3", text: "March"},
        {value: "4", text: "April"},
        {value: "5", text: "May"},
        {value: "6", text: "June"},
        {value: "7", text: "July"},
        {value: "8", text: "August"},
        {value: "9", text: "September"},
        {value: "10", text: "October"},
        {value: "11", text: "November"},
        {value: "12", text: "December"},
      ],
      durationList: [
        {value: "30", text: "30 minutes"},
        {value: "45", text: "45 minutes"},
        {value: "60", text: "1 hour"},
      ],
      dayList: [
        {
          value: "1",
          name: "Mon",
        },
        {
          value: "2",
          name: "Tue",
        },
        {
          value: "3",
          name: "Wed",
        },
        {
          value: "4",
          name: "Thu",
        },
        {
          value: "5",
          name: "Fri",
        },
        {
          value: "6",
          name: "Sat",
        },
        {
          value: "7",
          name: "Sun",
        },
      ],
      timeSlotList: [],
      availabilityData: {
        year: "",
        month: "",
        day: "",
        duration: "30",
        type: "recurring",
        slotTimes: {},
      },
      isDaySaved: true,
      isUpdate: false,
      lastIdCount: 0,
    };
  },
  mounted() {
    this.handleTimeSlotDuration();
  },

  methods: {
    handleDaySelect(day) {
      if (!this.isDaySaved) {
        this.$snackbar.add({
          text: "Please save the changes for selected day first.",
          type: "warning",
        });

        return;
      }

      if (this.availabilityData.month === "" || this.availabilityData.year === "") {
        this.$snackbar.add({
          text: "Please select month and year first.",
          type: "warning",
        });
        return;
      }

      expertService.getSlotDetail({
        expertId: this.expertId,
        day: day.value,
        month: this.availabilityData.month,
        year: this.availabilityData.year,
      }).then((response) => {
        console.log(response.data.data.slotTimes);
        if (response.data.data.slotTimes !== undefined && response.data.data.slotTimes !== null) {
          this.availabilityData.slotTimes = response.data.data.slotTimes;
          this.timeSlotList = response.data.data.slotTimes;
          this.isUpdate = true;
        } else {
          this.isUpdate = false;
          this.handleTimeSlotDuration();
        }
      }).catch(() => {
        this.$snackbar.add({
          text: "Something went wrong",
          type: "error",
        });
      });

      this.availabilityData.day = day.value;
    },
    handleDurationSelect(value) {
      this.availabilityData.duration = value;
      this.handleTimeSlotDuration();
    },
    handleYearSelect(value) {
      this.availabilityData.year = value;
      this.availabilityData.day = "";
    },
    handleMonthSelect(value) {
      this.availabilityData.month = value;
      this.availabilityData.day = "";
    },
    handleTimeSlotDuration() {
      this.timeSlotList = [];
      this.availabilityData.slotTimes = [];

      const startTime = '08:00';
      const endTime = '18:00';

      const startDate = new Date(`2000-01-01 ${startTime}`);
      const endDate = new Date(`2000-01-01 ${endTime}`);

      const durationInMinutes = parseInt(this.availabilityData.duration);

      let currentTime = startDate;

      while (currentTime <= endDate) {
        const endTimeSlot = new Date(currentTime.getTime() + durationInMinutes * 60000);

        const formattedStartTime = currentTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
        const formattedEndTime = endTimeSlot.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });

        this.timeSlotList = ({
          ...this.timeSlotList,
          [`${this.lastIdCount}`]: {
            startTime: formattedStartTime,
            endTime:formattedEndTime,
          }
        });

        this.lastIdCount++;

        currentTime.setHours(currentTime.getHours() + 1);

        if (currentTime > endDate) {
          currentTime = startDate;
        }
      }
    },
    onAddSlot() {
      if (this.timeSlotList.length === 0) {
        this.$snackbar.add({
          text: "Please generate time slots first.",
          type: "warning",
        });
        return;
      }

      const latestTimeSlot = this.latestEvent;
      const durationInMinutes = parseInt(this.availabilityData.duration );

      const endTimeComponents = latestTimeSlot.endTime.split(':');
      const endHour = parseInt(endTimeComponents[0]);
      const endMinute = parseInt(endTimeComponents[1]);

      let newStartHour = endHour;
      let newStartMinute = endMinute + durationInMinutes;

      if (newStartMinute >= 60) {
        newStartHour += Math.floor(newStartMinute / 60);
        newStartMinute %= 60;
      }

      if (durationInMinutes === 45 && endMinute >= 15 && newStartMinute > 0) {
        newStartHour = endHour + 1;
        newStartMinute = 0;
      }

      if (durationInMinutes === 60 && endMinute === 0) {
        newStartMinute = 0;
        newStartHour -= 1;
      } else if (durationInMinutes === 60) {
        newStartHour += 1;
        newStartMinute = 0;
      }

      const newStartTime = `${String(newStartHour).padStart(2, '0')}:${String(newStartMinute).padStart(2, '0')}`;

      const newEndTime = new Date(
          new Date(`2000-01-01 ${newStartTime}`).getTime() + durationInMinutes * 60000
      ).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });


      this.timeSlotList = ({
        ...this.timeSlotList,
        [`${this.lastIdCount}`]: {
          startTime: newStartTime,
          endTime: newEndTime,
        }
      });

      this.lastIdCount++;
    },
    checkIsSelected(slotId) {
      return this.availabilityData.slotTimes.hasOwnProperty(slotId);
    },
    checkTimeSlot(timeSlotId) {
      if (this.availabilityData.year === "") {
        this.$snackbar.add({
          text: "Please select year first.",
          type: "warning",
        });
        return;
      }

      if (this.availabilityData.month === "") {
        this.$snackbar.add({
          text: "Please select month first.",
          type: "warning",
        });
        return;
      }

      if (this.availabilityData.day === "") {
        this.$snackbar.add({
          text: "Please select day first.",
          type: "warning",
        });
        return;
      }

      if (this.checkIsSelected(timeSlotId)) {
        delete this.availabilityData.slotTimes[timeSlotId];
      } else {
        console.log(this.timeSlotList[timeSlotId]);
        this.availabilityData.slotTimes = ({
          ...this.availabilityData.slotTimes,
          [`${timeSlotId}`]: this.timeSlotList[timeSlotId],
        });
      }

      console.log(this.availabilityData.slotTimes);
      this.isDaySaved = false;
    },
    onSave() {
      if (this.isLoading) {
        return;
      }

      if (this.availabilityData.year === "") {
        this.$snackbar.add({
          text: "Please select year.",
          type: "warning",
        });
        return;
      }

      if (this.availabilityData.month === "") {
        this.$snackbar.add({
          text: "Please select month.",
          type: "warning",
        });
        return;
      }

      if (this.availabilityData.day === "") {
        this.$snackbar.add({
          text: "Please select day.",
          type: "warning",
        });
        return;
      }

      if (this.availabilityData.type === "") {
        this.$snackbar.add({
          text: "Please select type.",
          type: "warning",
        });
        return;
      }

      const tempData = {
        expertId: this.expertId,
        ...this.availabilityData,
      }

      if (this.isUpdate) {
        expertService.updateSlot(tempData).then(() => {
          this.$snackbar.add({
            text: "Availability updated successfully",
            type: "success",
          });
          this.isDaySaved = true;
        }).catch(() => {
          this.$snackbar.add({
            text: "Something went wrong",
            type: "error",
          });
        });
      } else {
        expertService.addSlot(tempData).then(() => {
          this.$snackbar.add({
            text: "Availability updated successfully",
            type: "success",
          });
          this.isDaySaved = true;
        }).catch(() => {
          this.$snackbar.add({
            text: "Something went wrong",
            type: "error",
          });
        });
      }

    },
  },

  computed: {
    latestEvent() {
      const eventArray = Object.values(this.timeSlotList);

      return eventArray.reduce((latest, current) => {
        return latest.startTime > current.startTime ? latest : current;
      }, {});
    },
  },
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.action-button{
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 48px;
  cursor: pointer;
}

.action-button span {
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.btn-div2{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  bottom: 30px;
  left: 24px;
  right: 24px;
}

.selectbox-row{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 10px;
}

.selectbox{
  width: 100%;
}


.availability-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.availability-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #FFFFFF;
  border: 1px solid #D0D5DD;
  padding: 10px;
  width: 100%;
  cursor: pointer;
}

.availability-box.checked {
  background-color: #040C2D;
}

.availability-box.checked .date-name {
  color: #FFFFFF;
}

.availability-box .date-name {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
}

.availability-box .date-number {
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  color: #667085;
}

.time-slot-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.time-slot-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  gap: 15px;
}

.time-slot-row img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.form-input {
  max-width: 150px;
}

.add-slot-button {
  width: 140px;
  display: flex;
  border: none;
  outline: none;
  flex-direction: row;
  align-items: center;
  border-radius: 24px;
  padding: 10px;
  height: 48px;
  cursor: pointer;
  background-color: transparent;
  color: #4A68F8;
}

.add-slot-button .plus-icon {
  width: 24px;
  height: 24px;
}
</style>
