// i18n.js
import { createI18n } from 'vue-i18n';
import tr from "./lang/tr.json"
import en from "./lang/en.json"

// Tarayıcının tercih ettiği dillerin listesi
const preferredLanguages = navigator.languages;
// İlk sırada bulunan dil
const primaryLanguage = preferredLanguages[0];
// Dil kısaltması alma işlemi
const languageCode = primaryLanguage.split('-')[0];
// localStorage'da kullanıcının tercih ettiği dili kontrol et
const userLanguage = localStorage.getItem('userLanguage');
// Eğer kullanıcı daha önce tercih ettiyse ve localStorage'da saklandıysa, bu tercihi kullan
const selectedLanguage = userLanguage || languageCode;



const i18n = createI18n({
    locale: selectedLanguage,
    fallbackLocale: 'tr',
    messages: {
        en: en,
        tr: tr
    }
});

export default i18n;
