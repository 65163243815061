<template>
  <div class="therapy-card">
    <div class="therapy-card-header" >
      <img :src="image" v-if="!isLoading" class="header-image" />
      <free-style-shimmer
          class="header-image"
          :is-loading="isLoading"
          color="#EAECF0"
          height="160px"
          width="100%"
      />
      <router-link :to="to">
        <button class="details-button" v-if="!isLoading">
          {{$t('details')}}
        </button>
      </router-link>
    </div>


    <div class="therapy-card-body">
      <h2 v-if="!isLoading">
        {{ title }}
      </h2>
      <free-style-shimmer
          :is-loading="isLoading"
          height="12px"
          width="120px"
          border-radius="50px"
          color="#EAECF0" />

      <h4 v-if="!isLoading">
        {{ description }}
      </h4>

      <free-style-shimmer
          class="paragraph-shimmer top"
          :is-loading="isLoading"
          height="8px"
          width="170px"
          border-radius="50px"
          color="#EAECF0" />

      <free-style-shimmer
          class="paragraph-shimmer"
          :is-loading="isLoading"
          height="8px"
          width="140px"
          border-radius="50px"
          color="#EAECF0" />


      <div class="tag-column" v-if="!isLoading">
      <span class="therapy-tag"  v-for="tag in tags">
        {{ tag }}
      </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    image : {
      type: String,
      default: 'https://i.hizliresim.com/lyhe30g.jpg'
    },
    title: {
      type: String,
      default: 'Blog Name'
    },
    description: {
      type: String,
      default: 'Lorem ipsum dolor sit amet consectetur. Elit suspendisse habitant vitae...'
    },
    tags: {
      type: Array,
      default: () => ['Relationship', 'Marriage']
    },
    to: {
      type: String,
      default: '/therapies'
    }
  },
  methods: {
    formatDate(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        return date.toLocaleDateString("en", options);
      } catch (e) {
        return '-';
      }
    },
  }
};
</script>

<style scoped>
.therapy-card {
  width: 100%;
  height: 100%;
  background-color: #F9FAFB;
  border: 1px solid #EAECF0;
  border-radius: 16px;
}

.therapy-card-header {
  position: relative;
}

.details-button {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: #4A68F8;
  border-radius: 16px;
  padding: 5px 20px;
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #FFFFFF;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
}

.therapy-card:hover .details-button{
  opacity: 1;
  visibility: visible;
}

.header-image {
  width: 100%;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  height: 160px;
  object-fit: cover;
}

.therapy-card-body {
  padding: 15px;
}

h2 {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #101828;
  margin-bottom: 10px;
}

h4 {
  font-family: euclid_regular, sans-serif;
  color: #667085;
  font-size: 12px;
  margin-bottom: 15px;
}

.therapy-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #101828;
  background-color: #E9EAEF;
  border-radius: 8px;
  padding: 3px 12px;
}

.therapy-info {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.therapy-info-text {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #667085;
}

.dot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #D0D5DD;
}

.therapy-footer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
}

.therapy-info-icon {
  width: 24px;
  height: 24px;
}

.paragraph-shimmer.top {
  margin-top: 15px;
}

.paragraph-shimmer {
  margin-bottom: 5px;
}

.tag-column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 15px;
}
</style>
