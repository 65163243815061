<template>
  <div v-show="visible" class="filter-modal">
    <div class="filter-div">
      <div class="filter-item" @click="selectFilter('therapy_number')">
        <img src="../../../assets/images/filtertherapynumber.svg" />
        <span class="filter-title">{{$t('therapy_number')}}</span>
      </div>
      <div class="filter-item" @click="selectFilter('user_amount')">
        <img src="../../../assets/images/fitleruser.svg" />
        <span class="filter-title">{{$t('user_amount')}}</span>
      </div>
      <div class="filter-item" @click="selectFilter('date_range')">
        <img src="../../../assets/images/filterdaterange.svg" />
        <span class="filter-title">{{$t('date_range')}}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectFilter(filterName) {
      this.$emit('filter-selected', filterName);
      event.stopPropagation();
    },
  },

  data() {
    return {
      textValue: "",
      stepValue:1
    };
  }
};
</script>

<style scoped>
.filter-modal {
  position: absolute;
  top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 999;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.08);
  width: max-content;
  padding: 8px 12px 8px 12px;
}
.filter-div{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  overflow: visible;
}

.filter-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 10px;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
}

.filter-item:hover {
  background: #F5F5F5;
}

.filter-title{
  font-family: euclid_medium, sans-serif;
  color: #475467;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>
