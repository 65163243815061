import axios from '../axios';

const chatService = {
    getChats(page, status, expertID) {
        return axios.get(`/v1/admin/experts/chat/store?page=${page}&limit=20&status=${status}&expertId=${expertID}`);
    },
    getChatDetails(chatId) {
        return axios.get(`/v1/admin/experts/chat/getFromId/${chatId}`);
    },
    addMessage(data) {
        return axios.post('/v1/admin/experts/chat/add', data);
    }
}

export default chatService;
