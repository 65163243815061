<script setup>
import { Handle, Position } from '@vue-flow/core'
import {onMounted, ref, watch} from "vue";

const props = defineProps(['id', 'data', 'onSelect', 'editDisabled']);

const selectedCondition = ref(null);
const selectedScore = ref(null);

const getType = (type) => {
  if (type === 'multiple') {
    return "Multiple";
  } else if (type === 'single') {
    return "Single";
  } else  if (type === 'yesNo') {
    return "Yes / No";
  } else if (type === 'single') {
    return "Single";
  } else if (type === 'number') {
    return "Number";
  } else if (type === 'text') {
    return "Text";
  } else if (type === 'grading') {
    return "Grading";
  }  else if (type === 'rating') {
    return "Rating"
  } else if (type === 'dragAndDrop') {
    return "Drag & Drop";
  } else {
    return "Undefined type";
  }
};

const handleConditionSelect = (event) => {
  selectedCondition.value = event.target.value;
}


const handleScoreSelect = (event) => {
  const option = event.target.value;
  if (option === '-1') return;

  selectedScore.value = option;
}

onMounted(async () => {
  if (props.data) {
    selectedCondition.value = props.data.condition;
    selectedScore.value = props.data.score;
  }
});

watch(
    [selectedCondition, selectedScore],
    ([newCondition, newScore], [oldCondition, oldScore]) => {
      if (newCondition === oldCondition && newScore === oldScore) return;

      const questionData = {
        ...props.data,
        condition: newCondition,
        score: newScore
      }

      props.onSelect(questionData, props.id);
    }
);
</script>

<template>
  <div class="question-node">
    <Handle id="input" type="target" :position="Position.Top"/>

    <div class="node-info">
      <span class="node-title">{{props.data?.name ? props.data?.name : 'No name'}}</span>
      <span class="node-description">{{ getType(props.data.content?.type) }}</span>

      <div class="select-row">
        <select @change="handleConditionSelect" :value="selectedCondition ? selectedCondition : '='" :disabled="editDisabled">
          <option value='='>=</option>
          <option value = '<'>&lt;</option>
          <option value = '>'>></option>
          <option value= '<='>&lt;=</option>
          <option value = '>='>>=</option>
        </select>

        <input class="answer-input" type="number" @change="handleScoreSelect"  placeholder="Enter score" :value="selectedScore" :disabled="editDisabled"/>
      </div>


    </div>

    <Handle id="output" type="source" :position="Position.Bottom" class="handle-true"/>
    <Handle id="false" type="source" :position="Position.Bottom" class="handle-false"/>
  </div>
</template>

<style scoped>
.question-node {
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #101828;
  color: white;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  font-size: 10px;
}

.question-node.selected {
  background: #1C3CD5;
  border: none;
}


.node-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 10px;
}

.node-title {
  font-size: 10px;
  font-weight: 500;
}

.node-description {
  font-size: 8px;
  color: #cacaca;
}

.handle-true {
  position: absolute;
  bottom: -5px;
  left: calc(50% - 10px) ;
  transform: translateX(-50%);
}

.handle-false {
  position: absolute;
  bottom: -5px;
  right: calc(50% - 10px) ;
  transform: translateX(50%);
  background-color: #F44336 !important;
}

select, input {
  border-radius: 8px;
  border: 1px solid #F5F5F5;
  background: transparent;
  color: #F5F5F5;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 10px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}



</style>
