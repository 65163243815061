<template>
  <div v-if="visible" class="filter-modal">
    <div class="filter-div">
      <div class="filter-item">
        <span class="header-txt">Status</span>
      </div>
      <div
          class="filter-item"
          v-for="item in filterStatus"
          :key="item.id"
          @click="toggleCheckStatus(item.name)"
      >
        <span class="filter-title">{{ item.name }}</span>
        <img v-if="selectedStatusFilter === item.name" src="../../assets/images/checkboxchecked-circle.svg">
        <img v-else src="../../assets/images/checkbox-circle.svg">
      </div>
      <div class="filter-item">
        <span class="header-txt">Amount</span>
      </div>
      <div class="text-input">
        <input type="number" class="text-input-primary" placeholder="Min number" @input="handleMin" :value="inputMin" />
        <input type="number" class="text-input-primary" placeholder="Max number"  @input="handleMax" :value="inputMax" />
      </div>
      <div
          class="filter-item"
          v-for="item in filterItems"
          :key="item.id"
          @click="toggleCheck(item.name)"
      >
        <span class="filter-title">{{ item.name }}</span>
        <img v-if="selectedFilter === item.name" src="../../assets/images/checkboxchecked-circle.svg">
        <img v-else src="../../assets/images/checkbox-circle.svg">
      </div>
      <div class="btn-style" @click="applyFilters">
        <span class="filter-txt">Filter</span>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";

export default {
  components: {
    TextInput
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onFilter: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      selectedFilter: undefined,
      selectedStatusFilter: undefined,
      inputMin: '',
      inputMax: '',
      filterItems:[
        {
          id: 1,
          name: "1.000₺-9.999₺",
          min: 1000,
          max: 9999,
        },
        {
          id: 2,
          name: "10.000₺-29.999₺",
          min: 10000,
          max: 29999,
        },
        {
          id: 3,
          name:"30.000₺-49.999₺",
          min: 30000,
          max: 49999,
        },
        {
          id: 4,
          name: "50.000₺ +",
          min: 50000,
        },
      ],
      filterStatus:[
        {
          id: 1,
          name: "Paid",
        },
        {
          id: 2,
          name: "Unpaid"
        }
      ]
    };
  },
  methods: {
    toggleCheck(itemName) {
      if (this.selectedFilter === itemName) {
        this.selectedFilter = undefined;
      } else {
        this.selectedFilter = itemName;
      }
    },
    toggleCheckStatus(statusName){
      if(this.selectedStatusFilter === statusName){
        this.selectedStatusFilter = undefined;
      }else{
        this.selectedStatusFilter = statusName
      }
    },
    handleMin(event) {
      this.selectedFilter = undefined;
      this.inputMin = event.target.value;
    },
    handleMax(event) {
      this.selectedFilter = undefined;
      this.inputMax = event.target.value;
    },
    applyFilters() {
      if (this.inputMin !== '' || this.inputMax !== '') {
        this.onFilter({
          status: this.selectedStatusFilter,
          min: this.inputMin !== '' ? parseInt(this.inputMin) : undefined,
          max: this.inputMax !== '' ? parseInt(this.inputMax) : undefined,
        });
      } else if (this.selectedFilter) {
        this.filterItems.map(item => {
          if (item.name === this.selectedFilter) {
            this.onFilter({
              status: this.selectedStatusFilter,
              min: item.min,
              max: item.max,
            });
          }
        });
      } else {
        this.onFilter({
          status: this.selectedStatusFilter,
        });
      }
    },
  },
  computed:{
  },
};
</script>

<style scoped>
.filter-modal {
  position: absolute;
  top: 40px;
  display: flex;
  left: 0;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.08);
  min-width: 300px;
}
.filter-div{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
  width: 100%;
}
.filter-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap:4px;
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
}
.filter-title{
  color: #475467;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}
.btn-style{
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: #040C2D;
  margin: 12px;
  cursor:pointer;
}
.filter-txt{
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.text-input{
  display: flex;
  padding: 12px;
  align-items: center;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}
.textinp{
  height: 32px;
}
.header-txt{
  font-family: euclid_medium, sans-serif;
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
</style>
