import axios from '../axios';

const expertService = {
    getExperts(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/experts/store?limit=20&page=${page}${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/experts/store?page=${page}`);
        }
    },
    addExpert(data) {
        return axios.post('/v1/admin/experts/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateExpert(data) {
        return axios.post('/v1/admin/experts/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteExpert(id) {
        return axios.delete(`/v1/admin/experts/delete/${id}`);
    },
    getExpertDetails(id) {
        return axios.get(`/v1/admin/experts/getFromId/${id}`);
    },
    saveAboutExpert(expertId, about) {
        return axios.put('/v1/admin/experts/about/update', {
            expertId: expertId,
            about: about
        });
    },
    addSchool(data) {
        return axios.post('/v1/admin/experts/education/add', data);
    },
    updateSchool(data) {
        return axios.put('/v1/admin/experts/education/update', data);
    },
    addCertificate(data) {
        return axios.post('/v1/admin/experts/certificate/add', data);
    },
    updateCertificate(data) {
        return axios.put('/v1/admin/experts/certificate/update', data);
    },
    addSlot(data) {
        return axios.post('/v1/admin/experts/slot/add', data);
    },
    updateSlot(data) {
        return axios.post('/v1/admin/experts/slot/update', data);
    },
    getExpertReviews(page, expertId) {
        return axios.get(`/v1/admin/experts/reviews/store?page=${page}&limit=20&expertId=${expertId}`);
    },
    getSlotDetail(data) {
        return axios.post('/v1/admin/experts/slot/getSlotDetail/', data);
    },
    getReservationAvailability(expertId, date) {
        return axios.get(`/v1/admin/experts/reservation/availableHours?expertId=${expertId}&date=${date}`);
    },
    addReservation(data) {
        return axios.post('/v1/admin/experts/reservation/add', data);
    }
}

export default expertService;
