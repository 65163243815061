<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-step-1">
          <div class="modal-header">
            <span class="modal-header-txt">{{$t('question_setting')}}</span>
            <span class="modal-header-description">{{$t('specific_question_settings')}}</span>
          </div>

          <div class="setting-wrapper">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('compulsory')}}
              </span>
              <span class="setting-text">
                {{$t('respondent_has_to_answer')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.required" />
            </div>
          </div>

          <div class="setting-wrapper" v-if="selectedType === 1 || selectedType === 2">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('random_order')}}
              </span>
              <span class="setting-text">
                {{$t('change_the_order')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.randomOrder" />
            </div>
          </div>

          <div class="setting-wrapper" v-if="selectedType === 5">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('multiple_paragraph')}}
              </span>
              <span class="setting-text">
                {{$t('participant_paragraph')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.multipleParagraph" />
            </div>
          </div>

          <div class="setting-wrapper" v-if="selectedType === 1 || selectedType === 2 || selectedType === 3">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('other_option')}}
              </span>
              <span class="setting-text">
                {{$t('other_option_shown')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.other" />
            </div>
          </div>

          <div class="setting-wrapper" v-if="isPartOfTherapy">
            <div class="setting-left">
              <span class="setting-name">
                {{$t('add_condition')}}
              </span>
              <span class="setting-text">
                {{$t('add_condition_to_answers')}}
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="addConnectionVisible" />
            </div>
          </div>

          <div v-if="addConnectionVisible && isPartOfTherapy">

            <div class="action-wrapper">
              <div class="action-div orange">
                <span>{{$t('condition')}}</span>
              </div>

              <div class="action-input" v-for="(condition, index) in conditionData" :key="index">
                <span class="line" />
                <div class="input-wrapper no-text-select">
                  <div class="input-dropdown" @click="condition.testDropdownVisible = !condition.testDropdownVisible" v-click-outside="() => condition.testDropdownVisible = false">
                    <span v-if="condition.selectedTest === null">Select Test</span>
                    <span v-else>{{shortenString(condition.selectedTest.name)}}</span>
                    <img src="@/assets/images/arrowdown.svg" />
                  </div>

                  <div class="dropdown-body scroll-design" v-if="condition.testDropdownVisible">
                    <span v-for="item in testList" @click="onSelectTest(condition, item)">{{item.name}}</span>
                  </div>
                </div>

                <div class="input-wrapper no-text-select">
                  <div class="input-dropdown" @click="condition.questionDropdownVisible = !condition.questionDropdownVisible" v-click-outside="() => condition.questionDropdownVisible = false">
                    <span v-if="condition.selectedQuestion === null">Select Question</span>
                    <span v-else>{{shortenString(condition.selectedQuestion.name)}}</span>
                    <img src="@/assets/images/arrowdown.svg" />
                  </div>

                  <div class="dropdown-body questions scroll-design" v-if="condition.questionDropdownVisible">
                    <span v-for="item in testQuestionList" @click="onSelectQuestion(condition, item)">{{item.name}}</span>
                    <span v-if="condition.selectedTest === null">{{$t('select_test_first')}}</span>
                  </div>
                </div>

                <div class="input-wrapper condition no-text-select">
                  <div class="input-dropdown" @click="condition.conditionDropdownVisible = !condition.conditionDropdownVisible" v-click-outside="() => condition.conditionDropdownVisible = false">
                    <span>{{condition.condition}}</span>
                    <img src="@/assets/images/arrowdown.svg" />
                  </div>

                  <div class="dropdown-body condition scroll-design" v-if="condition.conditionDropdownVisible">
                    <span @click="condition.condition = '='">=</span>
                    <span @click="condition.condition = '!='">!=</span>
                    <span v-if="!checkConditionMC(condition)" @click="condition.condition = '<'">&lt;</span>
                    <span v-if="!checkConditionMC(condition)" @click="condition.condition = '>'">></span>
                    <span v-if="!checkConditionMC(condition)" @click="condition.condition = '<='">&lt;=</span>
                    <span v-if="!checkConditionMC(condition)" @click="condition.condition = '>='">>=</span>
                  </div>
                </div>

                <div class="input-wrapper" v-if="!checkConditionMC(condition) || condition.selectedQuestion === null">
                  <input class="input-text" placeholder="Value" v-model="condition.value" />
                </div>

                <div class="input-wrapper no-text-select" v-else>
                  <div class="input-dropdown" @click="condition.optionDropdownVisible = !condition.optionDropdownVisible" v-click-outside="() => condition.optionDropdownVisible = false">
                    <span v-if="condition.value === null">Select Option</span>
                    <span v-else>{{shortenString(condition.selectedQuestion.options.find(option => option.id === condition.value).name)}}</span>
                    <img src="@/assets/images/arrowdown.svg" />
                  </div>

                  <div class="dropdown-body right scroll-design" v-if="condition.optionDropdownVisible">
                    <span v-for="item in condition.selectedQuestion.options" @click="condition.value = item.id">{{ item.name }}</span>
                  </div>
                </div>
              </div>
            </div>

            <button class="add-button" @click="addCondition">
              <img src="../../assets/images/plusBlue.svg" />
              Add condition
            </button>

          </div>

          <div class="setting-wrapper" v-if="isPartOfTherapy">
            <div class="setting-left">
              <span class="setting-name">
                Drawer option
              </span>
              <span class="setting-text">
                After part add a drawer
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="addActionVisible"/>
            </div>
          </div>

          <div v-if="addActionVisible && isPartOfTherapy">
            <div class="action-text-row">
              <div class="textarea-wrapper">
                <span class="tertiary-title">Drawer Header</span>
                <textarea class="form-input textarea" placeholder="Drawer Header" :value="settings.drawer.header" @input="handleDrawerHeader"/>
              </div>

              <div class="textarea-wrapper">
                <span class="tertiary-title">Drawer Body</span>
                <textarea class="form-input textarea" placeholder="Drawer Body" :value="settings.drawer.body" @input="handleDrawerBody" />
              </div>
            </div>

            <div class="action-wrapper">
              <div class="action-div">
                <span>ACTION</span>
              </div>

              <div class="action-input" v-for="action in settings.drawer.actions" :key="action.name">
                <span class="line" />
                <div class="input-wrapper no-text-select">
                  <div class="input-dropdown"  @click="action.typeDropdownVisible = !action.typeDropdownVisible" v-click-outside="() => action.typeDropdownVisible = false">
                    <span v-if="action.actionType === 'primary'">Primary Action</span>
                    <span v-else-if="action.actionType === 'secondary'">Secondary Action</span>
                    <span v-else-if="action.actionType === 'tertiary'">Tertiary Action</span>
                    <span v-else>Action type</span>
                    <img src="@/assets/images/arrowdown.svg" />
                  </div>

                  <div class="dropdown-body" v-if="action.typeDropdownVisible">
                    <span @click="action.actionType = 'primary'">Primary Action</span>
                    <span @click="action.actionType = 'secondary'">Secondary Action</span>
                    <span @click="action.actionType = 'tertiary'">Tertiary Action</span>
                  </div>
                </div>

                <div class="input-wrapper center">
                  <input class="input-text" placeholder="Action name" v-model="action.actionText"/>
                </div>

                <div class="input-wrapper no-text-select">
                  <div class="input-dropdown"  @click="action.connectToDropdownVisible = !action.connectToDropdownVisible" v-click-outside="() => action.connectToDropdownVisible = false">
                    <span v-if="action.action === 'next'">Next Step</span>
                    <span v-else-if="action.action === 'prev'">Previous Step</span>
                    <span v-else-if="action.action === 'repeat'">Repeat</span>
                    <span v-else-if="action.action === 'askExpert'">Ask to expert</span>
                    <span v-else>Connect to</span>

                    <img src="@/assets/images/arrowdown.svg" />
                  </div>

                  <div class="dropdown-body right" v-if="action.connectToDropdownVisible">
                    <span @click="action.action = 'next'">Next Step</span>
                    <span @click="action.action = 'prev'">Previous Step</span>
                    <span @click="action.action = 'repeat'">Repeat</span>
                    <span @click="action.action = 'askExpert'">Ask to expert</span>
                  </div>
                </div>

              </div>
            </div>

            <button class="add-button" @click="addAction">
              <img src="../../assets/images/plusBlue.svg" />
              Add action
            </button>
          </div>

          <div class="setting-wrapper" v-if="isPartOfTherapy && (selectedType === 1 || selectedType === 2 || selectedType === 3)">
            <div class="setting-left">
              <span class="setting-name">
                Add Chat
              </span>
              <span class="setting-text">
                Add a robot chat based on the answers
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="settings.chat"/>
            </div>
          </div>

          <div class="setting-wrapper" v-if="selectedType === 2 || selectedType === 3">
            <div class="setting-left">
              <span class="setting-name">
                Grading
              </span>
              <span class="setting-text">
                Add grading to selected answer
              </span>
            </div>
            <div class="setting-right">
              <Switch v-model="gradingVisible"/>
            </div>
          </div>
          <input
              v-if="gradingVisible"
              type="text"
              placeholder="Add grading"
              class="form-input mt-2"
              v-model="settings.grading"
          />

          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import Switch from "@/components/Switch.vue";
import testService from "@/service/testService";

export default {
  components: {
    Switch,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    settings: {
      type: Object,
      default: () => ({
        required: true,
        randomOrder: false,
        other: false,
        grading: 0,
        where: false,
        chat: false,
        drawer: false,
      }),
    },
    selectedType: {
      type: Number,
      default: 1,
    },
    testList: {
      type: Array,
      default: []
    },
    isPartOfTherapy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gradingVisible: this.settings.grading > 0,
      addConnectionVisible: false,
      conditionData: [
        {
          selectedTest: null,
          selectedQuestion: null,
          questionID: null,
          condition: '=',
          value: null,
          type: 'test',
          testDropdownVisible: false,
          questionDropdownVisible: false
        }
      ],
      conditionsLoading: true,
      testQuestionList: [],
      addActionVisible: false,
    };
  },
  created() {
    this.conditionsLoading = true;


    if (this.settings.where && this.settings.where.length > 0) {
      this.addConnectionVisible = true;

      this.conditionData = [];

      this.settings.where.forEach((condition, index) => {
        const tempData = {};

        if (!condition.id) {
          this.conditionsLoading = false;
          return;
        }

        const selectedTest = this.testList.find(test => test.id === condition.id);

        tempData.selectedQuestion = null;
        tempData.selectedTest = selectedTest;

        if (!selectedTest) {
          this.conditionsLoading = false;
          return;
        }

        this.questionsLoading = true;

        testService.getQuestion(condition.id, condition.type).then((res) => {
          this.testQuestionList = res.data.data.questions;
          this.questionsLoading = false;

          const selectedQuestion = this.testQuestionList.find(question => question.questionId === condition.questionID);
          this.onSelectQuestion(tempData, selectedQuestion);

          tempData.condition = condition.condition;
          tempData.value = parseInt(condition.value) || 0;
          tempData.type = condition.type;
          tempData.testDropdownVisible = false;
          tempData.questionDropdownVisible = false;

          this.conditionData.push(tempData);
        });
      });

      this.conditionsLoading = false;

    } else {
      this.conditionsLoading = false;
    }


    if (this.settings.drawer && (this.settings.drawer.header || this.settings.drawer.body)) {
      this.addActionVisible = true;
    }

    if (this.settings.drawer && this.settings.drawer.actions && this.settings.drawer.actions.length > 0) {
      this.addActionVisible = true;
    }
  },
  watch: {
    gradingVisible: {
      handler(newVal) {
        if (!newVal) {
          this.settings.grading = 0;
        }
      }
    },
    conditionData: {
      handler(newVal) {
        if (this.conditionsLoading) {
          return;
        }

        const tempWhere = [];
        newVal.forEach((condition, index) => {
          if (condition.questionID && condition.condition && condition.value >= 0) {
            tempWhere.push({
              questionID: condition.questionID,
              condition: condition.condition,
              value: condition.value,
              type: condition.type
            });
          }
        });

        this.settings.where = tempWhere;
      },
      deep: true
    },
    addActionVisible: {
      handler(newVal) {
        if (!newVal) {
          this.settings.drawer = false;
        } else if (!this.settings.drawer.actions || this.settings.drawer.actions.length === 0)  {
          this.settings.drawer = {
            header: '',
            body: '',
            actions: [
              {
                actionType: "",
                actionText: "",
                action: "",
                typeDropdownVisible: false,
                connectToDropdownVisible: false
              }
            ]
          };
        }
      }
    },
    addConnectionVisible: {
      handler(newVal) {
        if (!newVal) {
          this.settings.where = false;
        } else {
          this.settings.where = [];
        }
      }
    }
  },
  methods: {
    checkConditionMC(condition) {
      if (condition.selectedQuestion) {
        if (condition.selectedQuestion.type === 'single' || condition.selectedQuestion.type === 'multiple' || condition.selectedQuestion.type === 'yesNo') {
          return true;
        }
      }
      return false;
    },
    shortenString(str) {
      if (str.length > 20) {
        return str.substring(0, 20) + "...";
      } else {
        return str;
      }
    },
    addCondition() {
      this.conditionData.push({
        selectedTest: null,
        selectedQuestion: null,
        condition: '=',
        value: null,
        testDropdownVisible: false,
        questionDropdownVisible: false,
        conditionDropdownVisible: false,
        optionDropdownVisible: false
      });
    },
    async onSelectTest(condition, item) {
      condition.selectedQuestion = null;
      condition.selectedTest = item;
      condition.type = item.type;
      this.questionsLoading = true;

      await testService.getQuestion(item.id, item.type).then((res) => {
        this.testQuestionList = res.data.data.questions;
        this.questionsLoading = false;
      });
    },
    onSelectQuestion(condition, item) {
      condition.value = null;
      condition.selectedQuestion = item;
      condition.questionID = item.questionId;
    },
    handleDrawerHeader(event) {
      this.settings.drawer.header = event.target.value;
    },
    handleDrawerBody(event) {
      this.settings.drawer.body = event.target.value;
    },
    addAction() {
      this.settings.drawer.actions.push({
        actionType: "",
        actionText: "",
        action: "",
        type: "test",
        typeDropdownVisible: false,
        connectToDropdownVisible: false
      });
    },
  }
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  z-index: 9999;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
h5 {
  font-family: euclid_medium, sans-serif;
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}
.modal-header-description {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.setting-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.setting-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.setting-name {
  font-family: euclid_medium, sans-serif;
  color: #060B27;
  font-size: 18px;
  font-style: normal;
  line-height: 20px;
}

.setting-text {
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.add-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  color: #4478FF;
  border-radius: 8px;
  padding: 5px 10px;
  transition: background-color 0.2s ease-in-out;
}

.add-button:hover {
  background-color: #f3f4ff;
}

.tertiary-title {
  color: #282A36;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.action-text-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 20px;
  margin-top: 15px;
}
.textarea-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}
.form-input.textarea {
  min-height: 140px;
}
.action-wrapper {
  position: relative;
  margin-top: 30px;
  min-height: 124px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.action-div {
  position: absolute;
  height: 100%;
  min-height: 124px;
  width: 28px;
  border-radius: 8px;
  background-color: #4478ff;
}

.action-div.orange {
  background-color: #FFA500;
}

.action-div span {
  position: absolute;
  display: block;
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  font-size: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
}

.action-input {
  position: relative;
  border: 1px solid #e9ecf0;
  padding: 10px;
  margin-left: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.action-input .input-wrapper {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.action-input .input-wrapper.center {
  border-left: 1px solid #e9ecf0;
  border-right: 1px solid #e9ecf0;
}


.action-input .input-wrapper.condition {
  width: auto;
}

.line {
  position: absolute;
  left: -14px;
  top: 50%;
  width: 14px;
  transform: translateY(-50%);
  height: 1px;
  background-color: #98a2b3;
}

.input-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.input-dropdown span {
  font-family: euclid_medium, sans-serif;
  color: #344054;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  white-space: nowrap;
}

.input-dropdown img {
  width: 18px;
  height: 18px;
}

.dropdown-body {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  min-width: 34%;
  max-height: 240px;
  overflow-y: auto;
  background-color: #FFF;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 99999;
}

.dropdown-body.right {
  left: auto;
  right: 0;
}

.dropdown-body.questions {
  left: 25%;
}

.dropdown-body.condition {
  left: 50%;
}

.dropdown-body span {
  font-family: euclid_medium, sans-serif;
  display: block;
  color: #475467;
  font-size: 12px;
  font-style: normal;
  line-height: 20px;
  cursor: pointer;
}

.input-text {
  border: none;
  outline: none;
  background-color: transparent;
  font-family: euclid_medium, sans-serif;
  color: #344054;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  width: 100%;
  text-align: center;
}


</style>
