import axios from '../axios';


const dashboardService = {
    getDashboard() {
        return axios.get(`/v1/admin/dashboard/store`);
    },
    getChartData() {
        return axios.get(`/v1/admin/dashboard/storeData`);
    },
    saveDashboard(data) {
        return axios.post(`/v1/admin/dashboard/storeSave`, data);
    },
    getDashboardBackStore() {
        return axios.get(`/v1/admin/dashboard/backStore`);
    }
}

export default dashboardService;
