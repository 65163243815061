<template>
  <Layout>
    <div class="row">
      <div class="col-sm">
        <div class="card">
          <div class="card-header">
            <div class="icon-title">
              <div class="icon-holder">
                <img src="../assets/images/buildings.svg" />
              </div>
              <span>{{$t('paid_company')}}</span>
            </div>
          </div>
          <div class="card-body">
            <div class="chart-stats">
              <span class="chart-value">32</span>
              <img src="../assets/images/upTrend.svg" alt="Up Trend"/>
              <span class="chart-percent up">48%</span>
            </div>
            <BarChart :data="barData" suffix="compaines" bar-color="#FAD9C6" bar-color-active="#F09460" />
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="card">
          <div class="card-header">
            <div class="icon-title">
              <div class="icon-holder">
                <img src="../assets/images/userTick.svg" />
              </div>
              <span>{{$t('payment_receiveds')}}</span>
            </div>
          </div>
          <div class="card-body">
            <div class="chart-stats">
              <span class="chart-value">$12,847</span>
              <img src="../assets/images/upTrend.svg" alt="Up Trend"/>
              <span class="chart-percent up">32%</span>
            </div>
            <BarChart :data="barData" suffix="USD" bar-color="#D1E7D2" bar-color-active="#3DA33E" />
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="card">
          <div class="card-header">
            <div class="icon-title">
              <div class="icon-holder">
                <img src="../assets/images/therapies.svg" />
              </div>
              <span>{{$t('upcoming_payments')}}</span>
            </div>
          </div>
          <div class="card-body">
            <div class="chart-stats">
              <span class="chart-value">$3,838</span>
            </div>
            <BarChart :data="barData" suffix="USD" bar-color="#EBEDFB" bar-color-active="#4A68F8" />
          </div>
        </div>
      </div>
    </div>
    <div class="table-holder">
      <h3 class="table-title">{{$t('all_companies_title')}}</h3>
      <AccountingTable />
    </div>
  </Layout>
</template>

<script>
import BarChart from '@/components/charts/BarChart.vue'
import Layout from "@/components/layouts/Layout.vue";
import AvatarArray from "@/components/AvatarArray.vue";
import AccountingTable from "@/components/tables/AccountingTable.vue";


export default {
  name: 'Home',
  components: {
    AccountingTable,
    AvatarArray,
    Layout,
    BarChart,
  },
  data() {
    return {
      barData: [
        {
          name: "Aug 01",
          value: 5
        },
        {
          name: "Aug 02",
          value: 6
        },
        {
          name: "Aug 03",
          value: 2
        },
        {
          name: "Aug 04",
          value: 8
        },
        {
          name: "Aug 05",
          value: 12
        },
        {
          name: "Aug 06",
          value: 7
        },
        {
          name: "Aug 07",
          value: 3
        },
        {
          name: "Aug 08",
          value: 10
        },
        {
          name: "Aug 09",
          value: 9
        },
        {
          name: "Aug 10",
          value: 14
        },
        {
          name: "Aug 11",
          value: 13
        },
        {
          name: "Aug 12",
          value: 7
        },
        {
          name: "Aug 13",
          value: 4
        },

        {
          name: "Aug 14",
          value: 8
        },
        {
          name: "Aug 15",
          value: 12
        }
      ],
      avatarImages: [
        'https://i.hizliresim.com/ieyeqdo.png',
        'https://i.hizliresim.com/goy9r8a.png',
        'https://i.hizliresim.com/qegeuw6.png',
        'https://i.hizliresim.com/taym08o.png',
        'https://i.hizliresim.com/b0rsio2.png'
      ]
    }
  }
}
</script>

<style scoped>
.icon-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.icon-title span {
  font-family: 'euclid_medium', sans-serif;
  font-size: 18px;
  white-space: nowrap
}

.card {
  margin-bottom: 10px;
}

.icon-holder {
  background-color: #EAECF0;
  border-radius: 8px;
  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.icon-holder img {
  cursor: default;
}

.chart-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}

.chart-value {
  font-family: 'euclid_medium', sans-serif;
  font-size: 28px;
  margin-right: 10px;
}

.chart-percent {
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 12px;
  margin-left: 5px;
}

.chart-percent.up {
  color: #3DA33E;
}

.chart-percent.down {
  color: #F09460;
}

.table-holder {
  margin-top: 20px;
}

.table-title {
  font-family: 'euclid_medium', sans-serif;
  font-size: 20px;
  color: #040C2D;
  margin-bottom: 20px;
}
</style>
