import axios from '../axios';

const commonService = {
    getSchools() {
        return axios.get('/v1/admin/getFromData?type[]=school');
    },
    getDegrees() {
        return axios.get('/v1/admin/getFromData?type[]=degree');
    },
    getUsers() {
        return axios.get('/v1/admin/getFromData?type[]=users');
    },
    getTags() {
        return axios.get('/v1/admin/getFromData?type[]=tag');
    },
    getExperts() {
        return axios.get('/v1/admin/getFromData?type[]=experts');
    },
    getCountries() {
        return axios.get('/v1/common/default?type[]=country');
    },
    getCities() {
        return axios.get('/v1/common/default?type[]=cities');
    },
    getRoles() {
        return axios.get('/v1/admin/getFromData?type[]=role');
    }
}

export default commonService;
