<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-header-txt" v-if="!editMode">{{$t('add_a_new_company')}}</span>
          <span class="modal-header-txt" v-else>{{$t('edit_company')}}</span>
        </div>
        <div class="upload-image-main-div">
          <div class="upload-image-div">
            <span class="header-span">{{$t('image')}}</span>
            <div class="upload-image-div">
              <span class="upload-image-txt">{{$t('profile_photo_explanation')}}</span>
              <span class="upload-image-txt">{{$t('recommended_format')}}</span>
            </div>
          </div>
          <FileDrop :on-file-selected="onFileSelect"/>
        </div>
        <div class="forms">
          <div class="double-form">
            <div class="form-div">
              <span class="form-title">{{$t('company_name')}}</span>
              <input class="form-input" :placeholder="this.$t('enter_company_name')" v-model="companyData.name"  />
            </div>
            <div class="form-div">
              <span class="form-title">{{$t('authorized')}}</span>
              <input class="form-input" :placeholder="this.$t('enter_company_name')" v-model="companyData.authorized" />
            </div>

          </div>
          <div class="double-form">
            <div class="form-div">
              <span class="form-title">{{$t('work_phone')}}</span>
              <input class="form-input" :placeholder="this.$t('enter_work_phone_number')" v-model="companyData.phone"/>
            </div>
            <div class="form-div">
              <span class="form-title">{{$t('mobile_phone_number')}}</span>
              <input class="form-input" :placeholder="this.$t('enter_mobile_phone_number')" v-model="companyData.mobilePhone" />
            </div>

          </div>
          <div class="double-form">
            <div class="form-div">
              <span class="form-title">{{$t('e_mail')}}</span>
              <input class="form-input" :placeholder="this.$t('enter_mail')" v-model="companyData.email"/>
            </div>
          </div>
          <div class="double-form">
            <div class="form-div">
              <span class="form-title">{{$t('address_person')}}</span>
              <input class="form-input" :placeholder="this.$t('enter_address')" v-model="companyData.address" />
            </div>
          </div>
        </div>
        <div class="btn-div" @click="onCompanyAction">
          <div class="btn-style">
            <span class="next-txt" v-if="!loading && !editMode">{{$t('add_company')}}</span>
            <span class="next-txt" v-if="!loading && editMode">{{$t('save_button')}}</span>
            <VueSpinnerIos v-if="loading" size="20" height="100%" color="#fff" />
          </div>
        </div>
        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import FileDrop from "@/components/FileDrop.vue";
import {
  VueSpinnerIos
} from 'vue3-spinners';
import companyService from "@/service/companyService";
import {handleErrorResponse} from "@/utils/utils";

export default {
  components: {
    FileDrop,
    SuccessModal,
    TextInput,
    SelectBox,
    VueSpinnerIos
  },
  computed:{
    usersDataWithRoles() {
      return this.usersData.map(user => ({
        ...user,
        selectedRoleId: this.getRoleIdByRoleText(user.role),
      }));
    }
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: {
        name: '',
        phone: '',
        mobilePhone: '',
        address: '',
        taxNumber: '',
        taxOffice: '',
        authorized: '',
        email: '',
      }
    },
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    refreshCallback: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      companyData: {
        name: '',
        phone: '',
        mobilePhone: '',
        address: '',
        authorized: '',
        email: '',
      },
      uploadFile: null,
      loading: false,
    };
  },
  watch: {
    editData: {
      handler() {
        if (this.editMode) {
          this.companyData = {
            companyId: this.editData.companyId,
            name: this.editData.name,
            phone: this.editData.phone,
            mobilePhone: this.editData.mobilePhone,
            address: this.editData.address,
            authorized: this.editData.authorizedName,
            email: this.editData.authorizedemail,
          }
        }
      },
      deep: true,
    }
  },
  methods: {
    onCompanyAction() {
      if (this.editMode) {
        this.onEditCompany();
      } else {
        this.onSaveCompany();
      }
    },
    onSaveCompany() {
      this.loading = true;

      const formData = new FormData();

      Object.entries(this.companyData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (this.uploadFile) {
        formData.append('logo', this.uploadFile);
      }

      companyService.addCompany(formData).then(response => {
        this.loading = false;
        this.$snackbar.add({
          type: 'success',
          text: 'Company added successfully.'
        });
        this.refreshCallback();
      }).catch(error => {
        this.loading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },
    onEditCompany() {
      this.loading = true;

      const formData = new FormData();

      Object.entries(this.companyData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      if (this.uploadFile) {
        formData.append('logo', this.uploadFile);
      }


      companyService.updateCompany(formData).then(response => {
        this.loading = false;
        this.$snackbar.add({
          type: 'success',
          text: 'Company updated successfully.'
        });
        this.refreshCallback();
      }).catch(error => {
        this.loading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },
    onFileSelect(file) {
      this.uploadFile = file;
    },
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
.upload-image-main-div{
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-top: 24px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  height: auto;
}
.upload-image-div{
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
}
.header-span{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 16px;
  line-height: 24px;
}
.upload-image-txt{
  color: #667085;
  font-size: 14px;
  line-height: 20px;
}
.forms{
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  align-items: flex-start;
  width: 100%;
}
.double-form{
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
}
.form-div{
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
}
.form-title{
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.btn-style{
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 48px;
  cursor: pointer;
}
.next-txt{
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.btn-div{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

@media (max-width: 678px) {
  .upload-image-main-div {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
