<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-header">
          <span class="modal-header-txt">{{$t('add_user')}}</span>
        </div>
        <div class="add-user-div">
          <input type="text" class="form-input"  :placeholder="this.$t('enter_user_e_mail')" v-model="inviteData.email" />
          <SelectBox  class="select-box" :options="selectboxOptions" :on-select="handleInviteSelect" :selected-value="inviteData.roleId" placeholder="Select a role"/>
          <div class="btn-style-add" @click="onInvite">
            <span class="btn-txt-add" v-if="!addLoading">{{$t('add')}}</span>
            <VueSpinnerIos v-if="addLoading" size="20" height="100%" color="#fff" />
          </div>
        </div>
        <div class="import-csv-div">
          <div class="import-toggle" @click="csvImport = !csvImport">
            <span>{{$t('or_import_csv')}}</span>
            <img src="@/assets/images/chevronDownBlue.svg" />
          </div>

          <Transition name="slide-up">
            <div v-if="csvImport" class="import-csv-div">
              <FileDrop on-file-selected="onFileSelected" />

              <button class="dark-button-sm">
                {{$t('import')}}
              </button>
            </div>
          </Transition>
        </div>
        <div class="list-user-title">
          <span class="list-user-txt">{{$t('users_title')}}</span>
        </div>
        <div class="list-user-div" >
          <div class="list-user-item" v-for="user in usersData" :key="user.userId">
            <div class="user-avatar-div initials">
              {{ getUserInitials(user.email) }}
            </div>
            <span class="user-email">{{ user.email }}</span>

            <span class="user-role">
              {{ user.role }}
            </span>
            <div class="user-avatar-div" @click="showDeleteModal(user.userId)">
              <img src="../../assets/images/trash.svg">
            </div>
          </div>
        </div>

        <div class="btn-div2">
          <div></div>
          <div class="btn-style" @click="this.onHide">
            <span class="next-txt" >{{$t('done')}}</span>
          </div>
        </div>
        <SuccessModal :is-Visible="showSuccessModal" :on-close="onClose" :on-action="onClose"/>
        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>
    </div>
  </Transition>

  <ConfirmDeleteModal
      :title="this.$t('alert_messages')"
      :message="this.$t('alert_messages_two')"
      :is-visible="deleteModalVisible"
      :on-action="onDeleteInvite"
      :on-close="handleDeleteModalClose"
  />



</template>


<script>
import TextInput from "@/components/TextInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
import inviteService from "@/service/inviteService";
import {
  VueSpinnerIos
} from 'vue3-spinners'
import {handleErrorResponse} from "@/utils/utils";
import ConfirmDeleteModal from "@/components/modals/ConfirmDeleteModal.vue";
import userService from "@/service/userService";
import FileDrop from "@/components/FileDrop.vue";

export default {
  components: {
    FileDrop,
    ConfirmDeleteModal,
    SuccessModal,
    TextInput,
    SelectBox,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    companyId: {
      type: Number,
      default: null
    },
    roleList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      addLoading: false,
      isModalVisible: false,
      showSuccessModal: false,
      deleteModalVisible: false,
      csvImport: false,
      userId:0,
      selectboxOptions: [],
      usersData:[],
      inviteData: {
        email: "",
        roleId: "",
      }
    };
  },
  created() {
    if (this.companyId === null || this.companyId === undefined) {
      this.$snackbar.add({
        text: 'Company id is not set',
        type: 'error',
      });
      this.onClose();
    } else {
      inviteService.getInvitedUsers(1, this.companyId).then(response => {
        this.usersData = response.data.data.data;
      });
    }

    this.selectboxOptions = this.roleList.map(role => ({
      text: role.name,
      value: role.id,
    }));
  },
  methods: {
    getUserInitials(name) {
      return name.substr(0, 1).toUpperCase();
    },
    onClose() {
      this.showSuccessModal = false;
    },
    onSuccessModalClose() {
      this.showSuccessModal = false;
    },
    onSave() {
      this.showSuccessModal = true;
      event.stopPropagation();
    },
    handleInviteSelect(value) {
      this.inviteData.roleId = value;
    },
    onInvite() {
      if (this.inviteData.email === "") {
        this.$snackbar.add({
          text: "Please enter user email",
          type: 'error',
        });
        return;
      }

      const tempData = {
        ...this.inviteData,
        companyId: this.companyId,
      }

      this.addLoading = true;

      inviteService.inviteUser(tempData).then(response => {
        this.showSuccessModal = true;
        this.inviteData.email = "";
        this.inviteData.roleId = "";

        inviteService.getInvitedUsers(1, this.companyId).then(response => {
          this.addLoading = false;
          this.usersData = response.data.data.data;
        });
      }).catch(error => {
        this.addLoading = false;
        handleErrorResponse(error, this.$snackbar);
      });
    },
    showDeleteModal(id) {
      this.userId = id;
      this.deleteModalVisible = true;
      event.stopPropagation();
    },
    handleDeleteModalClose() {
      this.deleteModalVisible = false;
    },
    onDeleteInvite() {
      inviteService.deleteInvite(this.userId).then(() => {
        inviteService.getInvitedUsers(1, this.companyId).then(response => {
          this.usersData = response.data.data.data;
          this.deleteModalVisible = false;
        }).catch(() => {
          this.$snackbar.add({
            text: 'An error occurred while deleting the user',
            type: 'error',
          });
        });
      });
    }
  },

};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
.btn-style{
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 48px;
  cursor: pointer;
}
.btn-style-add{
  display: flex;
  border-radius: 8px;
  background: #1C3CD5;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 48px;
  cursor: pointer;
}
.btn-txt-add{
  color: #FFF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
.next-txt{
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.form-input {
  flex: 1;
}
.btn-div2{
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  bottom: 30px;
  left: 24px;
  right: 24px;
}
.add-user-div{
  display: flex;
  flex-direction: row;
  gap: 14px;
  margin-top: 24px;
  align-items: flex-start;
  width: 100%;
}

.user-avatar-div{
  display: flex;
  width: 40px;
  height: 40px;
  padding: 6.667px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 41.667px;
  background: #EAECF0;
  gap: 6.667px;
  flex-shrink: 0;
}
.list-user-div{
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  margin-bottom: 62px;
  margin-top: 10px;
}
.list-user-title{
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
  width: 100%;
  margin-top: 48px;
}
.list-user-txt {
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
}
.list-user-item{
  display: flex;
  flex-direction: row;
  gap: 14px;
  align-items: center;
  width: 100%;
}
.user-email, .user-role {
  flex: 1;
  font-size: 14px;
}

.user-role {
  color: #667085;
}

.import-csv-div{
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-items: center;
  width: 100%;
  margin-top: 5px;
}

.import-toggle{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.import-toggle img{
  width: 10px;
  height: 10px;
}

.import-toggle span {
  font-family: euclid_regular, sans-serif;
  color: #1C3CD5;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
}
@media (max-width: 498px) {
  .initials {
    display: none;
  }
  .btn-style {
    width: 50%;
  }
}
</style>
