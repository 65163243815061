<template>
  <div class="loading-overlay" v-if="isLoading">
    <LottieAnimation
        :animation-data="loadingAnim"
        :auto-play="true"
        :loop="true"
        :speed="1"
    />
  </div>
</template>

<script>
import {LottieAnimation} from "lottie-web-vue";
import loadingAnim from "@/assets/animations/loading.json";


export default {
  name: 'LoadingOverlay',
  components: {
    LottieAnimation,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loadingAnim,
    }
  },
}
</script>
