<template>
  <ReportLayout>
    <div class="wrapper">
      <div class="left-wrapper">
        <div class="editor">
          <draggable
              class="draggable-area"
              group="components"
              :list="pageContent"
              item-key="id"
              :sort="true"
          >
            <template #item="{element}">
              <div
                  class="element-wrapper"
                  :class="selectedElement?.id === element.id && 'selected'"
                  @click.stop="() => handleSelect(element)"
              >
                <div class="element-controls" v-if="selectedElement?.id === element.id">
                  <button @click.stop="() => removeElement(element)">
                    <img src="@/assets/images/trashOutline.svg" />
                  </button>
                </div>
                <template v-if="element.type === 'row'">
                  <div class="row-wrapper">
                    <draggable
                        class="row-draggable"
                        group="components"
                        :list="element.content"
                        item-key="id"
                        :sort="true"
                    >
                      <template #item="{element: childElement}">
                        <div
                            class="element-wrapper"
                            :class="selectedElement?.id === childElement.id && 'selected'"
                            @click.stop="() => handleSelect(childElement)"
                        >
                          <div class="element-controls" v-if="selectedElement?.id === childElement.id">
                            <button @click.stop="() => removeElement(childElement, element.content)">
                              <img src="@/assets/images/trashOutline.svg" />
                            </button>
                          </div>
                          {{childElement.content}}
                        </div>
                      </template>
                    </draggable>
                  </div>
                </template>
                <template v-else-if="element.type === 'col'">
                  <div class="col-wrapper">
                    <draggable
                        class="col-draggable"
                        group="components"
                        :list="element.content"
                        item-key="id"
                        :sort="true"
                    >
                      <template #item="{element: childElement}">
                        <div
                            class="element-wrapper"
                            :class="selectedElement?.id === childElement.id && 'selected'"
                            @click.stop="() => handleSelect(childElement)"
                        >
                          <div class="element-controls" v-if="selectedElement?.id === childElement.id">
                            <button @click.stop="() => removeElement(childElement, element.content)">
                              <img src="@/assets/images/trashOutline.svg" />
                            </button>
                          </div>
                          {{childElement.content}}
                        </div>
                      </template>
                    </draggable>
                  </div>
                </template>
                <template v-else-if="element.type === 'image'">
                  <img :src="element.content" v-bind="element.props"/>
                </template>
                <template v-else>
                  {{element.content}}
                </template>
              </div>
            </template>
          </draggable>
        </div>
      </div>

      <div class="right-wrapper">
        <div class="toolbox">
          <div class="toolbox-header">
            <div class="toolbox-tab" :class="activeTab === 'components' && 'active'" @click="activeTab = 'components'">
              <img src="@/assets/images/componentsActive.svg" v-if="activeTab === 'components'" />
              <img src="@/assets/images/components.svg" v-else />
            </div>

            <div class="toolbox-tab" :class="activeTab === 'constraints' && 'active'"  @click="activeTab = 'constraints'">
              <img src="@/assets/images/constraintsActive.svg"  v-if="activeTab === 'constraints'"/>
              <img src="@/assets/images/constraints.svg" v-else />
            </div>
          </div>
          <div class="toolbox-components" v-if="activeTab === 'components'">
            <div class="toolbox-content-header">
              <span>{{$t('essential')}}</span>
            </div>
            <draggable
                :group="{ name: 'components', pull: 'clone', put: false }"
                :list="availableComponents"
                :clone="cloneComponent"
                @move="onMove"
                class="components-grid"
            >
              <template #item="{element}">
                <div class="draggable-item">
                  <img :src="element.icon" class="draggable-icon" />
                  <span>{{element.name}}</span>
                </div>
              </template>
            </draggable>

            <div class="toolbox-content-header">
              <span>{{$t('charts')}}</span>
            </div>
          </div>

          <div class="toolbox-constraints" v-else>
            <div class="toolbox-content-header">
              <span>{{$t('padding')}}</span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </ReportLayout>
</template>

<script>
import draggable from 'vuedraggable'
import ReportLayout from "@/components/layouts/ReportLayout.vue";
import TextIcon from "@/assets/images/text.svg";
import RowIcon from "@/assets/images/row.svg";
import ColIcon from "@/assets/images/col.svg";
import ImageIcon from "@/assets/images/picture.svg";


export default {
  components: {
    draggable,
    ReportLayout
  },
  data() {
    return {
      activeTab: 'components',
      availableComponents: [
        {
          id: 0,
          name: 'Text',
          type: 'text',
          icon: TextIcon,
          props: {},
          content: "Text content",
          canHaveChildren: false,
        },
        {
          id: 0,
          name: 'Image',
          type: 'image',
          icon: ImageIcon,
          props: {
            style: {
              "width": '150px',
              "height": '130px',
              "object-fit": 'contain',
            }
          },
          content: "https://api.ibilityapp.com/img/ibility.jpeg",
          canHaveChildren: false,
        },
        {
          id: 0,
          name: 'Row',
          type: 'row',
          icon: RowIcon,
          props: {},
          content: [],
          canHaveChildren: true,
        },
        {
          id: 0,
          name: 'Column',
          type: 'col',
          icon: ColIcon,
          props: {},
          content: [],
          canHaveChildren: true,
        },
      ],
      pageContent: [],
      selectedElement: null,
    }
  },
  methods: {
    handleSelect(element) {
      this.selectedElement = element;
    },
    cloneComponent(original) {
      const clone = JSON.parse(JSON.stringify(original));
      clone.id = `component-${Date.now()}`;
      if (clone.type === 'Row') {
        clone.content = [];
      }
      this.handleSelect(clone);
      return clone;
    },
    removeElement(element, parentContent = this.pageContent) {
      const index = parentContent.findIndex(el => el.id === element.id);
      if (index !== -1) {
        parentContent.splice(index, 1);
      }
      if (this.selectedElement && this.selectedElement.id === element.id) {
        this.selectedElement = null;
      }
    },
    onMove(event) {
      console.log(event);
    }
  }
}
</script>

<style scoped>
.wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.left-wrapper {
  width: 70%;
}

.right-wrapper {
  width: 30%;
}

.editor {
  height: 100vh;
  background-color: #fff;
  border: 1px solid #EAECF0;
  border-radius: 12px;
}

.toolbox {
  height: 100vh;
  background-color: #fff;
  border: 1px solid #EAECF0;
  border-radius: 12px;
}

.toolbox-header {
  height: 60px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  border-bottom: 1px solid #EAECF0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  gap: 10px;
}

.toolbox-tab {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 10px;
  cursor: pointer;
  width: 60px;
  height: 50px;
  margin-bottom: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbox-tab.active {
  background-color: #fff;
  border-top: 1px solid #EAECF0;
  border-left: 1px solid #EAECF0;
  border-right: 1px solid #EAECF0;
}

.toolbox-components, .toolbox-constraints {
  padding: 10px;
}

.draggable-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  border: 1px solid #EAECF0;
  border-radius: 8px;
  padding: 10px;
  cursor: move;
  gap: 10px;
}

.draggable-item span {
  font-size: 12px;
  font-family: euclid_medium, sans-serif;
  color: #040C2D;
}

.draggable-icon {
  width: 24px;
  height: 24px;
}

.draggable-area {
  height: 100%;
  width: 100%;
  border: 1px solid transparent;
}

.element-wrapper {
  cursor: pointer;
  display: block;
  position: relative;
}

.element-wrapper.selected {
  outline: 1px dashed blue;
}

.element-controls {
  background-color: #f5f5f5;
  height: 25px;
  position: absolute;
  right: 0;
  bottom: -30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  z-index: 999;
}

.element-controls button {
  border: none;
  outline: none;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  font-size: 10px;
  margin: 0;
}

.element-controls button img {
  width: 15px;
  height: 15px;
}

.row-draggable {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 15px;
  padding: 10px;
}

.col-draggable {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 15px;
  padding: 10px;
}


.components-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding: 10px;
}


.toolbox-content-header {
  border-bottom: 1px solid #EAECF0;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.toolbox-content-header span {
  font-size: 14px;
  font-family: euclid_medium, sans-serif;
  color: #040C2D;
}

</style>
