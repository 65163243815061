<template>
  <div class="review-wrapper">
    <img :src="avatar" class="avatar" v-if="!loading" />
    <free-style-shimmer height="64px" width="64px" border-radius="50%" v-if="loading" color="#EAECF0" />

    <div class="right-column">
      <div class="stars-row" v-if="!loading">
        <img src="@/assets/images/starFill.svg" v-if="score >= 1" />
        <img src="@/assets/images/starFill.svg" v-if="score >= 2" />
        <img src="@/assets/images/starFill.svg" v-if="score >= 3" />
        <img src="@/assets/images/starFill.svg" v-if="score >= 4" />
        <img src="@/assets/images/starFill.svg" v-if="score >= 5" />
      </div>

      <free-style-shimmer height="10px" width="80px" v-if="loading" color="#EAECF0" />

      <span class="username" v-if="!loading">{{name}}</span>
      <free-style-shimmer height="10px" width="120px" v-if="loading" color="#EAECF0" />

      <span class="review" v-if="!loading">{{review}}</span>
      <free-style-shimmer height="6px" width="200px" v-if="loading" color="#EAECF0" />
      <free-style-shimmer height="6px" width="180px" v-if="loading" color="#EAECF0" />

    </div>

    <div class="end-column">
      <Switch v-if="!loading && !statusPostLoading" v-model="statusCheck" :on-toggle="handleStatusToggle"/>
      <VueSpinnerIos v-if="statusPostLoading" size="16" height="100%" color="#8c8c8c" />
    </div>
  </div>
</template>

<script>
import Switch from "@/components/Switch.vue";
import {VueSpinnerIos} from "vue3-spinners";
import reviewService from "@/service/reviewService";
import {handleErrorResponse} from "@/utils/utils";

export default {
  components: {
    Switch,
    VueSpinnerIos
  },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    reviewId: {
      type: String,
      default: '',
    },
    publishedStatus: {
      type: Boolean,
      default: false,
    },
    avatar: {
      type: String,
      default: '',
    },
    score: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    review: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      statusPostLoading: false,
      statusCheck: false,
    }
  },
  created() {
    this.statusCheck = this.publishedStatus;
  },
  watch: {
    publishedStatus: {
      handler: function (val) {
        this.statusCheck = val;
      },
      deep: true
    }
  },
  methods: {
    async handleStatusToggle(status) {
      if (this.statusPostLoading) return;

      if (this.reviewId === '') return;

      this.statusPostLoading = true;

      console.log('status', status);

      await reviewService.updateReviewStatus(this.reviewId, status)
          .catch((error) => {
            handleErrorResponse(error, this.$snackbar);
          })
          .finally(() => {
            this.statusPostLoading = false;
          });
      this.statusCheck = status;
    }

  }
}
</script>

<style scoped>
.review-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

.avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  object-fit: cover;
}

.right-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}


.end-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  align-self: center;
  gap: 5px;
}

.stars-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.username {
  font-family: 'euclid_medium', sans-serif;
  font-size: 16px;
  color: #101828;
}

.review {
  font-family: 'euclid_regular', sans-serif;
  font-size: 14px;
  color: #667085;
  line-height: 1.5;
}

</style>
