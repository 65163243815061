<template>
  <div class="radial-widget">
    <apexchart type="donut" width="380" :options="{
      ...chartOptions,
      labels: data.labels,
    }" :series="data.series"></apexchart>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: {
        series: [],
        labels: [],
      },
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'donut',
        },

        stroke: {
          lineCap: "round",
        },
        plotOptions: {
          pie: {
            donut: {
              size: '80%',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'euclid_medium',
                  color: undefined,
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'euclid_medium',
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: 'Total',
                  color: '#373D3F',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }
              }
            }
          }
        }
      },

    }
  }
}
</script>

<style scoped>
.radial-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85%;
}
</style>
