<template>
  <Layout padding="0">
    <JourneyUserHeader
        :is-loading="isLoading"
        :title="data.general?.name + ' ' + data.general?.surname"
        :status="data.general?.status"
        :created-at="data.general?.createdAt"
        :photo="data.general?.avatarUrl"
        :journey-id="journeyId"
        :user-id="data.general?.userId"
        :on-end-journey="handleEndJourney"
        :program-name="data.therapyName"
        :general-point="data.general?.generalPoint"
    />
    <div class="main-content">
      <div class="tab-panel-row">
        <div class="tab-panel">
          <button class="tab-button" :class="{'active': tabIndex === 0}" @click="() => changeTab(0)">
            <span>{{$t('general_tab')}}</span>
          </button>

          <button class="tab-button" :class="{'active': tabIndex === 1}" @click="() => changeTab(1)">
            <span>{{$t('flow')}}</span>
          </button>

          <button class="tab-button" :class="{'active': tabIndex === 2}" @click="() => changeTab(2)">
            <span>{{$t('interview')}}</span>
          </button>

          <button class="tab-button" :class="{'active': tabIndex === 3}" @click="() => changeTab(3)">
            <span>{{$t('files')}}</span>
          </button>

          <button class="tab-button" :class="{'active': tabIndex === 4}" @click="() => changeTab(4)">
            <span>{{$t('tests')}}</span>
          </button>
        </div>
      </div>

      <div v-if="tabIndex === 0" class="tab-container">
        <h3>{{$t('user_details')}}</h3>
        <div class="company-details">
          <div class="company-details-col">

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/avatar.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('full_name')}}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{data.general?.name + ' ' + data.general?.surname}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/message.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('email_address')}}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{data.general?.email}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/buildings.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title" v-if="!isLoading">
                  {{$t('city_person')}}
                </span>
                <span class="detail-text">
                  {{data.general?.city}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

          </div>

          <div class="company-details-col">

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/phone.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('phone')}}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{data.general?.phone}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/cake.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('birthday')}}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{data.general?.birthday}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/education.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('education')}}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{data.general?.educationLevel}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

          </div>

          <div class="company-details-col">

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/twoUser.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('marital_status')}}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{data.general?.maritalStatus}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/medal.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('general_point')}}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{data.general?.generalPoint}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/notifications.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('notifications_title')}}
                </span>
                <a :href="'/notifications?journeyId=' + journeyId" class="detail-text link" v-if="!isLoading">
                  {{$t('see_all')}}
                </a>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>


          </div>
        </div>
      </div>

      <div v-if="tabIndex === 1" class="tab-container">
        <div class="right-action">
          <SelectBox :options="flowList" placeholder="Select module" :selected-value="currentFlow.moduleId" :on-select="handleModuleSelect"/>

        </div>

          <div class="flow-wrapper">
            <VueFlow
                :nodes="currentFlow.conditions?.nodes"
                :edges="currentFlow.conditions?.edges"

                :default-edge-options="{animated: false, deletable: false}"
                :nodes-draggable="false"
                :edges-connectable="false"
                :edges-focusable="false"
                :selection-mode="'partial'"
            >
              <Background />

              <template #node-question="nodeProps">
                <QuestionNode v-bind="nodeProps" />
              </template>

              <template #node-number-question="nodeProps">
                <NumberQuestionNode
                    v-bind="nodeProps"
                    :on-select="() => {}"
                    :edit-disabled="true"
                />
              </template>

              <template #node-start="nodeProps">
                <StartNode v-bind="nodeProps" />
              </template>

              <template #node-end="nodeProps">
                <EndNode v-bind="nodeProps" />
              </template>

              <template #node-and="nodeProps">
                <AndNode v-bind="nodeProps" :refresh-internals="() => {}" :edit-disabled="true"  />
              </template>

              <template #node-or="nodeProps">
                <OrNode v-bind="nodeProps" :refresh-internals="() => {}" :edit-disabled="true"  />
              </template>

              <template #node-answer-condition="nodeProps">
                <AnswerConditionNode
                    v-bind="nodeProps"
                    :test-list="testList"
                    :on-select="() => {}"
                    :edit-disabled="true"
                />
              </template>

              <template #node-test-score-condition="nodeProps">
                <TestScoreCondition
                    v-bind="nodeProps"
                    :test-list="testList"
                    :on-select="() => {}"
                    :edit-disabled="true"
                />
              </template>

              <template #node-assign="nodeProps">
                <AssignNode
                    v-bind="nodeProps"
                    :test-list="testList"
                    :on-select="() => {}"
                    :edit-disabled="true"
                />
              </template>

              <template #node-notification="nodeProps">
                <NotificationNode
                    v-bind="nodeProps"
                    :notification-list="notificationList"
                    :notification-select="() => {}"
                    :edit-disabled="true"
                />
              </template>

              <template #node-part="nodeProps">
                <PartNode v-bind="nodeProps" />
              </template>
            </VueFlow>
          </div>

      </div>

      <div v-if="tabIndex === 2" class="tab-container two-row">
        <div class="left-card">
          <div class="card-header">
            <h4>{{$t('interviews')}}</h4>
          </div>

          <div class="interview-card" v-for="interview in data.interview">
            <img src="@/assets/images/linkOutline.svg" />

            <div class="interview-info">
              <span class="info-tag" :class="interview.status === 2 ? 'green' : interview.status === 3 &&  'red'">
                {{ interview.status === 1 ? 'Pending' : interview.status === 2 ? 'Done' : 'Unattended' }}
              </span>
              <span class="info-title">{{ interview.content?.label }}</span>
              <span class="info-date">{{ interview.content?.interviewDate }}</span>
            </div>

            <ActionDropdown :actions="interviewActions" :on-action="(action) => handleInterviewAction(action, interview)" :placeholder="$t('actions')"/>
          </div>

        </div>

        <div class="right-card">
          <div class="card-header">
            <h4>{{$t('notes')}}</h4>
            <button class="dark-button-sm" @click="noteModalVisible = !noteModalVisible">{{$t('add')}}</button>
          </div>

          <div class="note-card" v-for="note in data.notes">
            <div class="note-header">
              <span class="note-title">{{ note.title }}</span>
              <span class="note-date">{{note.createdAt}}</span>
            </div>

            <span class="note-writer">{{note.addNoteUserInfo.fullName}}</span>
            <p class="note-content">
              {{note.note}}
            </p>

            <div class="note-file-list">
              <template v-for="file in note.files">
                <a class="note-file-item" :href="file.attachUrl" target="_blank" >
                  <img src="@/assets/images/attachOutline.svg" />
                  <span>{{file.name}}</span>
                </a>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div v-if="tabIndex === 3" class="tab-container"  v-click-outside="removeSelectedFiles">
        <div class="title-row">
          <h3>{{$t('uploaded_files')}}</h3>

          <div class="select-actions"  v-if="selectedFiles.length > 0">
            <span class="selected-count">{{$t('selected')}} {{selectedFiles.length}} {{$t('files_title')}}</span>
            <ActionDropdown :actions="fileActions" :on-action="handleFileAction" :placeholder="$t('actions')"/>
          </div>
        </div>

        <div class="file-grid">
          <FileCard
              v-for="file in data.files"
              :file="{
                fileStatus: file.status === '1' ? 'approved' : file.status === '2' ? 'rejected' : 'pending',
                fileName: file.name,
                fileCover: file.coverImageUrl,
                fileAttachment: file.attachUrl,
                fileSize: file.info?.size,
                fileType: file.info?.extension
              }"
              :user="{
                avatarUrl: file.userInfo?.avatarUrl,
                name: file.userInfo?.name
              }"
              :on-click="() => selectFile(file.fileId)"
              :selected="selectedFiles.includes(file.fileId)"
          />
        </div>
      </div>

      <div v-if="tabIndex === 4" class="tab-container">
        <div class="test-grid">
          <div class="test-card" v-for="test in data.tests">
            <div class="title-holder">
              <span class="test-name">{{test.name}}</span>
              <MorePane :pane-items="[
                  {
                    text: $t('download_pdf'),
                    icon: DocumentOutline,
                    action: () => {
                      openPdfUrl(test.pdfUrl);
                    }
                  },
                  {
                    text: $t('send_report_to_user'),
                    icon: NotificationOutline,
                    action: () => {
                      sendReportNotification(test.testId);
                    }
                  }
              ]" />
            </div>

            <div class="result-row">
              <div class="result-block">
                <span class="block-title">
                  {{$t('weight_note')}}
                </span>
                <span class="block-result">
                  {{test.weightNote}}
                </span>
              </div>

              <div class="result-block">
                <span class="block-title">
                  {{$t('user_score')}}
                </span>
                <span class="block-result">
                  {{test.userScore}}
                </span>
              </div>

              <div class="result-block">
                <span class="block-title">
                  {{$t('total_score')}}
                </span>
                <span class="block-result">
                  {{test.totalScore}}
                </span>
              </div>

              <div class="result-block">
                <span class="block-title">
                  {{$t('solved')}}
                </span>
                <span class="block-result">
                  {{test.solved === 1 ? 'Yes' : 'No'}}
                </span>
              </div>
            </div>
          </div>

        </div>

      </div>

      <NotesModal :visible="noteModalVisible" :on-hide="hideNoteModal" :journey-id="journeyId"/>
      <LoadingOverlay :is-loading="overlayVisible" />
    </div>
  </Layout>
</template>

<script>
import BarChart from '@/components/charts/BarChart.vue'
import Layout from "@/components/layouts/Layout.vue";
import AvatarArray from "@/components/AvatarArray.vue";
import Pagination from "@/components/Pagination.vue";
import NotificationNode from "@/components/condition-flow/NotificationNode.vue";
import PartNode from "@/components/condition-flow/PartNode.vue";
import DropzoneBackground from "@/components/condition-flow/DropzoneBackground.vue";
import AnswerConditionNode from "@/components/condition-flow/AnswerConditionNode.vue";
import StartNode from "@/components/condition-flow/StartNode.vue";
import EndNode from "@/components/condition-flow/EndNode.vue";
import QuestionNode from "@/components/condition-flow/QuestionNode.vue";
import {VueFlow} from "@vue-flow/core";
import testService from "@/service/testService";
import notificationContentService from "@/service/notificationContentService";
import {Background} from "@vue-flow/background";
import JourneyUserHeader from "@/components/JourneyUserHeader.vue";
import ActionDropdown from "@/components/ActionDropdown.vue";
import FileCard from "@/components/FileCard.vue";
import NotesModal from "@/components/modals/NotesModal.vue";
import journeyService from "@/service/journeyService";
import SelectBox from "@/components/SelectBox.vue";
import AndNode from "@/components/condition-flow/AndNode.vue";
import OrNode from "@/components/condition-flow/OrNode.vue";
import AssignNode from "@/components/condition-flow/AssignNode.vue";
import TestScoreCondition from "@/components/condition-flow/TestScoreCondition.vue";
import fileService from "@/service/fileService";
import {handleErrorResponse} from "@/utils/utils";
import NumberQuestionNode from "@/components/condition-flow/NumberQuestionNode.vue";
import MorePane from "@/components/MorePane.vue";
import DocumentOutline from '@/assets/images/documentOutline.svg'
import NotificationOutline from '@/assets/images/notificationOutline.svg';
import LoadingOverlay from "@/components/LoadingOverlay.vue";

export default {
  name: 'JourneyDetails',
  components: {
    LoadingOverlay,
    MorePane,
    NumberQuestionNode,
    TestScoreCondition, AssignNode, OrNode, AndNode,
    SelectBox,
    NotesModal,
    FileCard,
    ActionDropdown,
    JourneyUserHeader,
    Background,
    VueFlow,
    QuestionNode, EndNode, StartNode, AnswerConditionNode, DropzoneBackground, PartNode, NotificationNode,
    Pagination,
    AvatarArray,
    Layout,
    BarChart,
  },
  data() {
    return {
      NotificationOutline,
      DocumentOutline,
      isLoading: true,
      journeyId: '',
      actionDropVisible: false,
      testList: [],
      notificationList: [],
      tabIndex: 0,
      data: {},
      nodes: [],
      edges: [],
      interviewActions: [
        {
          name: 'Cancel interview',
          action: 'cancel'
        },
        {
          name: 'Mark as done',
          action: 'done'
        },
        {
          name: 'Mark unattended',
          action: 'unattended'
        }
      ],
      fileList: [
        {
          fileId: 1,
          fileStatus: 'pending',
          fileName: 'File 01',
          fileSize: '10MB',
          fileType: 'mp4'
        },
        {
          fileId: 2,
          fileStatus: 'approved',
          fileName: 'File 02',
          fileSize: '8MB',
          fileType: 'mp4'
        },
        {
          fileId: 3,
          fileName: 'File 03',
          fileStatus: 'rejected',
          fileSize: '10MB',
          fileType: 'mp4'
        },
        {
          fileId: 4,
          fileName: 'File 04',
          fileStatus: 'approved',
          fileSize: '10MB',
          fileType: 'mp4'
        },
        {
          fileId: 5,
          fileName: 'File 05',
          fileStatus: 'approved',
          fileSize: '10MB',
          fileType: 'mp4'
        },
      ],
      flowList: [],
      currentFlow: {},
      selectedFiles: [],
      fileActions: [
        {
          name: 'Set as approved',
          action: 'approve'
        },
        {
          name: 'Set as rejected',
          action: 'cancel'
        }
      ],
      noteModalVisible: false,
      overlayVisible: false,
    }
  },
  async mounted() {
    const routeParams = this.$route.params;

    await this.fetchData();

    if (routeParams.id !== undefined && routeParams.id !== 'undefined') {
      this.isLoading = true;
      this.journeyId = routeParams.id;
      await this.fetchJourneyDetails(routeParams.id);
    } else {
      window.location.href = "/journeys";
    }

  },
  methods: {
    async fetchJourneyDetails(journeyId) {
      this.isLoading = true;
      journeyService.getJourneyDetails(journeyId).then((response) => {
        this.data = response.data.data;
        this.data.flow?.modules?.forEach((module) => {
          this.flowList.push({
            text: module.moduleName ? module.moduleName : 'No name',
            value: module.moduleId ? module.moduleId : '-1',
          });
        });
        this.currentFlow = this.data.flow?.modules[0];
        this.isLoading = false;
      }).catch(() => {
        this.$snackbar.add({
          text: "Fetching journey details failed",
          type: "error",
        });
      });
    },
    async fetchData() {
      await testService.getTestList().then((response) => {
        this.testList = response.data.data;
      }).catch(() => {
        this.$snackbar.add({
          text: "Fetching test list failed",
          type: "error",
        });
      });

      await notificationContentService.getNotificationContentList().then((response) => {
        this.notificationList = response.data.data;
      }).catch(() => {
        this.$snackbar.add({
          text: "Fetching notification list failed",
          type: "error",
        });
      });
    },
    changeTab(index) {
      this.tabIndex = index;
    },
    removeSelectedFiles() {
      this.selectedFiles = [];
    },
    selectFile(fileId) {
      if (this.selectedFiles.includes(fileId)) {
        this.selectedFiles = this.selectedFiles.filter((id) => id !== fileId);
      } else {
        this.selectedFiles.push(fileId);
      }
    },
    hideNoteModal() {
      this.fetchJourneyDetails(this.journeyId);
      this.noteModalVisible = false;
    },
    handleModuleSelect(moduleId) {
      this.currentFlow = this.data.flow?.modules.find((module) => module.moduleId === moduleId);
    },
    handleFileAction(action) {
      this.fileActionLoading = true;
      fileService.setFileStatus({
        fileIds: this.selectedFiles,
        status: action === 'approve' ? '1' : '2'
      }).then(() => {
        this.selectedFiles = [];
        this.fetchJourneyDetails(this.journeyId);
      }).catch((err) => {
        handleErrorResponse(err, this.$snackbar);
      }).finally(() => {
        this.fileActionLoading = false;
      });
    },
    handleEndJourney() {
      journeyService.endJourney([this.journeyId]).then(() =>
          this.$snackbar.add({
            text: "Journey ended successfully",
            type: "success",
          })
      ).catch(() =>
          this.$snackbar.add({
            text: "Journey end failed",
            type: "error",
          })
      );
    },
    handleInterviewAction(action, interview) {
      const status = action === 'cancel' ? '1' : action === 'done' ? '2' : '3';

      this.isLoading = true;
      journeyService.updateInterviewStatus(interview.notificationId, status).then(() => {
        this.fetchJourneyDetails(this.journeyId);
      }).catch(err => {
        handleErrorResponse(err, this.$snackbar);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    openPdfUrl(url) {
      if (!url || !url.startsWith('http')) {
        return;
      }
      window.open(url, '_blank');
    },
    sendReportNotification(testId) {
      if (!testId || this.overlayVisible) {
        return;
      }

      this.overlayVisible = true;

      testService.sendReport(testId).then(() => {
        this.$snackbar.add({
          text: "Report sent successfully",
          type: "success",
        });
      }).catch(() => {
        this.$snackbar.add({
          text: "Sending report failed",
          type: "error",
        });
      }).finally(() => {
        this.overlayVisible = false;
      });
    }
  },

}
</script>

<style scoped>
.flow-wrapper {
  display:flex;
  height:100%;
}

.tab-panel {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}
.main-content {
  padding: 25px;
  height: 100%;
  display: block;
}
.tab-panel-row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #F2F4F7;
  border-bottom: 1px solid #F2F4F7;
  padding: 10px 0;
  margin-bottom: 20px;
}
.tab-button {
  padding: 6px 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.tab-button span {
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;
  color: #344054;
}
.tab-button.active, .tab-button:hover {
  background-color: #F2F4F7;
}

.title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
  height: 40px;
}

h3 {
  font-family: euclid_medium, sans-serif;
  font-size: 18px;
  color: #040C2D;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}

.title-row h3 {
  margin-bottom: 0;
  margin-top: 0;
}

h4 {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #040C2D;
  padding: 0;
  margin: 0;
}
.tab-container {
  height: 100%;
  width: 100%;
}
.tab-container.two-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.tab-container.two-row .left-card, .tab-container.two-row .right-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tab-container .tab-sidebar {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.interview-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #F2F4F7;
}

.interview-card img {
  width: 32px;
  height: 32px;
}

.interview-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
}

.interview-info .info-title {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #040C2D;
}

.interview-info .info-date {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #667085;
}

.company-details {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.company-details-col {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 300px;
}
.company-detail-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.company-detail-icon {
  background-color: #F5F5F5;
  border-radius: 16px;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-detail-icon img {
  cursor: default;
  width: 20px;
  height: 20px;
}
.detail-title {
  font-family: euclid_medium, sans-serif;
  display: block;
  color: #667085;
  font-size: 12px;
}
.detail-text {
  font-family: euclid_medium, sans-serif;
  display: block;
  color: #101828;
  font-size: 14px;
}
.detail-text.link {
  color: #4A68F8;
  cursor: pointer;
}
.info-tag {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #fff;
  padding: 2px 10px;
  border-radius: 8px;
  background-color: #ffa123;
  flex-wrap: wrap;
  align-self: flex-start;
}
.info-tag.blue {
  background-color: #4A68F8;
}
.info-tag.green {
  background-color: #3da33e;
}
.info-tag.red {
  background-color: #ed4343;
}

.note-card {
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #F2F4F7;
  display: flex;
  flex-direction: column;
}

.note-title {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #040C2D;
}

.note-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.note-date {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #667085;
}

.note-writer {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #667085;
}

.note-content {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #667085;
  margin-top: 10px;
}

.note-file-list {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.note-file-item {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  border: 1px solid #F2F4F7;
  padding: 5px 10px;
  border-radius: 8px;
  cursor: pointer;
}

.note-file-item img {
  width: 18px;
  height: 18px;
}

.note-file-item span {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #4A68F8;
}

.note-file-item:hover {
  background-color: #F2F4F7;
}

.file-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.select-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.selected-count {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #667085;
}

.right-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.select-style {
  min-width: 200px;
}

.test-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.test-card {
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #EAECF0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.test-name {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #040C2D;
}

.result-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.result-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
  border: 1px solid #EAECF0;
  padding: 10px;
  border-radius: 12px;
  flex: 1;
}

.block-title {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #667085;
}

.block-result {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #040C2D;
}

.title-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

</style>
