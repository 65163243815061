<template>
  <div
      :id="data.id"
      :gs-id="data.id"
      :gs-x="data.grid.x"
      :gs-y="data.grid.y"
      :gs-w="data.grid.w"
      :gs-h="data.grid.h"
  >
    <div
        class="default-card grid-stack-item-content group relative "
        v-if="!isLoading"
    >
      <h5>{{ data.title }}</h5>
      <NumberWidget v-if="data?.type === 'number'" :data="getData(data?.id)" />
      <RadialWidget v-else-if="data?.type === 'radialChart'" :data="getData(data?.id)" />
      <LinearPercentageBarWidget v-else-if="data?.type === 'linearPercentageBar'" :data="getData(data?.id)" />
      <TableWidget v-else-if="data?.type === 'table'" :data="getData(data?.id)" />
      <FunnelWidget v-else-if="data?.type === 'funnelChart'" :data="getData(data?.id)" />
      <SemiPieChart v-else-if="data?.type === 'semiPieChart'" :data="getData(data?.id)" />
      <LineChart v-else-if="data?.type === 'lineChart'" :data="getData(data?.id)" />
      <ColumnChart v-else-if="data?.type === 'columnChart'" :data="getData(data?.id)" />
    </div>
    <free-style-shimmer
        v-else
        class="grid-stack-item-content group relative"
        border-radius="20px"
        :is-loading="true"
        color="#EAECF0"
        height="100%"
        width="100%"
    />
  </div>
</template>
<script>
import NumberWidget from "@/components/dashboard-components/NumberWidget.vue";
import RadialWidget from "@/components/dashboard-components/RadialWidget.vue";
import LinearPercentageBarWidget from "@/components/dashboard-components/LinearPercentageBarWidget.vue";
import TableWidget from "@/components/dashboard-components/TableWidget.vue";
import FunnelWidget from "@/components/dashboard-components/FunnelWidget.vue";
import SemiPieChart from "@/components/dashboard-components/SemiPieChart.vue";
import LineChart from "@/components/dashboard-components/LineChart.vue";
import ColumnChart from "@/components/dashboard-components/ColumnChart.vue";

export default {
  components: {
    ColumnChart,
    LineChart,
    SemiPieChart,
    FunnelWidget,
    TableWidget,
    LinearPercentageBarWidget,
    RadialWidget,
    NumberWidget
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    widgetsList: {
      type: Array,
      default: [],
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      generalData: {
        totalCompanies: {
          total: 50,
          trend: 'up',
          percentage: 3,
          info: '+50 companied added'
        },
        totalSales: {
          total: 500,
          trend: 'up',
          percentage: 24,
          info: '+20,3K $ sales'
        },
        totalExperts: {
          total: 100,
          trend: 'down',
          percentage: 5,
          info: '80 Active - 20 Passive'
        },
        totalPrograms: {
          total: 10,
          trend: 'up',
          percentage: 10,
          info: '20 Assigned - 4 Drafts\n'
        },
        mostActiveCompanies: {
          series: [44, 55, 41, 17, 15],
          labels: ['Company A', 'Company B', 'Company C', 'Company D', 'Company E']
        },
        whereUsersCameFrom: {
          list: [
            {
              name: 'Google',
              percentage: 40,
              count: 400,
              color: '#F09460',
            },
            {
              name: 'Facebook',
              percentage: 40,
              count: 400,
              color: '#3DA33E',
            },
            {
              name: 'Twitter',
              percentage: 20,
              count: 200,
              color: '#4A68F8',
            }
          ]
      },
        allTransactions: {
          labels: [
              "Company",
              "Payment",
              "Amount",
              "Status",
              "Date"
          ],
          content: [
            [
              {
                icon: 'https://i.hizliresim.com/hwiqqi7.png',
                name: 'Netflix',
              },
              {
                name: '**** 5282',
              },
              {
                name: '$ 12.99',
              },
              {
                name: 'Success',
              },
              {
                name: '12.12.2021',
              },
            ],
            [
              {
                icon: 'https://i.hizliresim.com/enlyjil.png',
                name: 'Google',
              },
              {
                name: '**** 5282',
              },
              {
                name: '$ 200.99',
              },
              {
                name: 'Success',
              },
              {
                name: '11.12.2021',
              },
            ],
          ]
        },
        mostActiveUsers: {
          content: [
            [
              {
                name: 'Berkay Zelyurt',
              },
              {
                name: '25 Programs',
              },
            ],
            [
              {
                name: 'Pınar Bilgin',
              },
              {
                name: '25 Programs',
              },
            ],
            [
              {
                name: 'Ecem Arıkan',
              },
              {
                name: '25 Programs',
              },
            ],
          ]
        },
        testFunnel: {
          series: [
            {
              name: "Data type",
              data: [1380, 1100, 990, 880, 740],
            },
          ],
          labels: ['Funnel A', 'Funnel B', 'Funnel C', 'Funnel D', 'Funnel E']
        },
      },
    };
  },
  methods: {
    deleteWidget() {
      this.$emit('delete', this.data.id);
    },
    getData(id) {
      try {
        if (this.widgetsList.find((widget) => widget.reportId === id)) {
          return this.widgetsList.find((widget) => widget.reportId === id);
        } else {
          return {};
        }
      } catch (error) {
        return {};
      }
    }
  },
}
</script>

<style scoped>

.default-card {
  border: 1px solid #EAECF0;
  border-radius: 20px;
  padding: 20px;
}

.default-card h5 {
  color: #101828;
  font-size: 16px;
  margin-bottom: 15px;
}

.ui-resizable-handle {
  display: none;
}

</style>
