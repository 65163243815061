<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-step-1">
          <div class="modal-header">
            <span class="modal-header-txt">Add Link</span>
          </div>

          <h5>Text to be shown</h5>
          <input class="form-input" placeholder="Text to be shown">

          <h5>Link</h5>
          <input class="form-input" placeholder="Link">

          <div class="btn-div" @click="onSave">
            <div class="btn-style">
              <span class="next-txt">Save</span>
            </div>
          </div>
          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg" />
          </div>
        </div>
      </div>
      <SuccessModal :is-visible="showSuccessModal" :on-close="onSuccessModalClose" />
    </div>
  </Transition>
</template>

<script>
import TextInput from "@/components/TextInput.vue";
import SelectBox from "@/components/SelectBox.vue";
import SuccessModal from "@/components/modals/SuccessModal.vue";
export default {
  components: {
    SuccessModal,
    TextInput,
    SelectBox
  },
  computed:{
    usersDataWithRoles() {
      return this.usersData.map(user => ({
        ...user,
        selectedRoleId: this.getRoleIdByRoleText(user.role),
      }));
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      textValue: "",
      isModalVisible: false,
      showSuccessModal: false,
      selectboxOptions:[
        {
          "id":1,
          "text":"Basic user"
        },
        {
          "id":2,
          "text":"Premium user"
        },
        {
          "id":3,
          "text":"Admin"
        },
      ],
      usersData:[
        {
          "id":1,
          "name":"Ellen Rose",
          "email":"ellenrose@ibility.com",
          "role":"Admin",
          "selectedRoleId": 3,
        },
        {
          "id":2,
          "name":"Ellen Page",
          "email":"ellenpage@ibility.com",
          "role":"Basic user",
          "selectedRoleId": 1,

        },
        {
          "id":3,
          "name":"Tarık Amir ",
          "email":"tarikamir@deu.com",
          "role":"Premium user",
          "selectedRoleId": 2,
        },
      ]
    };
  },
  created() {
    this.usersData = this.usersData.map(user => ({
      ...user,
      selectedRoleId: this.selectboxOptions.find(option => option.text === user.role)?.id || null,
    }));
  },
  methods: {
    getRoleIdByRoleText(roleText) {
      const role = this.selectboxOptions.find(option => option.text === roleText);
      return role ? role.id : null;
    },
    showModal() {
      this.isModalVisible = true;
    },
    getUserInitials(name) {
      return name.split(' ').map(n => n[0]).join('').toUpperCase().substr(0, 2);
    },
    onClose() {
      this.isModalVisible = false;
    },
    onSuccessModalClose() {
      this.showSuccessModal = false;
    },
    onSave() {
      this.showSuccessModal = true;
      event.stopPropagation();
    },
  },

};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
.btn-style{
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 48px;
  cursor: pointer;
}

.next-txt{
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.btn-div{
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  bottom: 20px;
  right: 20px;
}
h5 {
  font-family: euclid_medium, sans-serif;
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}
</style>
