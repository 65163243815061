<template>
  <div class="array-holder" :style="style">
    <div class="avatar" v-for="item in data" :key="item.id">
      <img :src="item" alt="avatar"/>
    </div>
    <div class="avatar-more">
      <span>+20</span>
    </div>
  </div>

</template>

<script>

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    style: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
}

</script>

<style scoped>
.array-holder {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.avatar {
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border-radius: 50%;
  margin-right: -9px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar img {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.avatar-more {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  background-color: #EAECF0;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.avatar-more span {
  font-family: 'euclid_regular', sans-serif;
  font-size: 8px;
  color: #101828;
}
</style>
