<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content scroll-design" v-if="!addVisible && editData === null" v-scroll-end="handleScrollEnd">

        <div class="modal-header">
          <span class="modal-header-txt">GameLabs Groups</span>
        </div>


        <div class="group-list">
          <div class="group-item" v-for="group in groups">
            <div class="left">
              <span class="name">{{ group.name }}</span>
              <span class="short-description">{{ group.shortDescription }}</span>
            </div>
            <div class="right-actions">
              <img src="@/assets/images/editPenOutline.svg" @click="handleEdit(group)"/>
              <img src="@/assets/images/trashOutline.svg" @click="handleDelete(group)"/>
            </div>
          </div>
        </div>

        <div class="modal-action">
          <button class="primary-button" @click="addVisible = true">Add New</button>
        </div>
        <div @click="handleClose" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>

      <div class="modal-content" v-if="addVisible">
        <div class="modal-header">
          <div class="title-w-icon">
            <img src="@/assets/images/arrowLeft.svg" @click="addVisible = false"/>
            <span class="modal-header-txt">Add New Group</span>
          </div>
        </div>

        <div class="modal-body">
          <label class="form-label">Name</label>
          <input class="form-input" v-model="addData.name" placeholder="Name" />

          <label class="form-label mt-3">Short Description</label>
          <input class="form-input" v-model="addData.shortDescription" placeholder="Short Description" />

          <label class="form-label mt-3">Description</label>
          <textarea class="form-input textarea" v-model="addData.description" placeholder="Description" />
        </div>

        <div class="btn-div2">
          <div></div>
          <div class="action-button" @click="onAdd">
            <span v-if="!isLoading">Save</span>
            <VueSpinnerIos v-if="isLoading" size="20" height="100%" color="#fff" />
          </div>
        </div>
        <div @click="handleClose" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>

      <div class="modal-content" v-if="!addVisible && editData !== null">
        <div class="modal-header">
          <div class="title-w-icon">
            <img src="@/assets/images/arrowLeft.svg" @click="editData = null"/>
            <span class="modal-header-txt">Edit Group</span>
          </div>
        </div>

        <div class="modal-body">
          <label class="form-label">Name</label>
          <input class="form-input" v-model="editData.name" placeholder="Name" />

          <label class="form-label mt-3">Short Description</label>
          <input class="form-input" v-model="editData.shortDescription" placeholder="Short Description" />

          <label class="form-label mt-3">Description</label>
          <textarea class="form-input textarea" v-model="editData.description" placeholder="Description" />
        </div>


        <div class="btn-div2">
          <div></div>
          <div class="action-button" @click="onUpdate">
            <span v-if="!isLoading">Save</span>
            <VueSpinnerIos v-if="isLoading" size="20" height="100%" color="#fff" />
          </div>
        </div>
        <div @click="handleClose" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>
    </div>
  </Transition>

  <ConfirmDeleteModal :is-visible="deleteModalVisible" :is-loading="isLoading" :on-close="handleDeleteClose" :on-action="onDelete"/>

</template>

<script>
import {
  VueSpinnerIos
} from 'vue3-spinners'
import {handleErrorResponse} from "@/utils/utils";
import gameLabService from "@/service/gameLabService";
import ConfirmDeleteModal from "@/components/modals/ConfirmDeleteModal.vue";

export default {
  components: {
    ConfirmDeleteModal,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      isLoading: false,
      groups: [],
      addVisible: false,
      currentPage: 1,
      editData: null,
      addData: {
        name: '',
        shortDescription: '',
        description: '',
      },
      deleteModalVisible: false,
      deleteData: null,
    };
  },
  mounted() {
    this.getGroups(this.currentPage);
  },
  methods: {
    onUpdate() {
      if (!this.editData.gameLabsGroupId || this.editData.gameLabsGroupId === '') {
        this.$snackbar.add({
          text: 'Invalid group',
          type: 'error'
        });
        return;
      }

      if (this.editData.name === '' || this.editData.shortDescription === '' || this.editData.description === '') {
        this.$snackbar.add({
          text: 'Please fill all fields',
          type: 'warning'
        });
        return;
      }
      this.isLoading = true;

      gameLabService.updateGroup(this.editData).then(() => {
        this.isLoading = false;
        this.editData = null;
        this.getGroups(this.currentPage);
      }).catch((error) => {
        handleErrorResponse(this, error);
      });
    },
    onAdd() {
      if (this.addData.name === '' || this.addData.shortDescription === '' || this.addData.description === '') {
        this.$snackbar.add({
          text: 'Please fill all fields',
          type: 'warning'
        });
        return;
      }

      this.isLoading = true;
      gameLabService.addGroup(this.addData).then(() => {
        this.isLoading = false;
        this.addVisible = false;
        this.addData = {
          name: '',
          shortDescription: '',
          description: '',
        };
        this.getGroups(this.currentPage);
      }).catch((error) => {
        handleErrorResponse(this, error);
      });
    },
    onDelete() {
      if (!this.deleteData.gameLabsGroupId || this.deleteData.gameLabsGroupId === '') {
        this.$snackbar.add({
          text: 'Invalid group',
          type: 'error'
        });
        return;
      }

      this.isLoading = true;
      gameLabService.deleteGroup(this.deleteData.gameLabsGroupId).then(() => {
        this.isLoading = false;
        this.deleteData = null;
        this.getGroups(this.currentPage);
        this.handleDeleteClose();
      }).catch((error) => {
        handleErrorResponse(this, error);
      });
    },
    getGroups(page) {
      gameLabService.getGroups(page).then((response) => {
        this.groups = response.data.data.data;
      }).catch((error) => {
        handleErrorResponse(this, error);
      });
    },
    handleScrollEnd() {
      this.currentPage++;
      this.getGroups(this.currentPage);
    },
    handleEdit(group) {
      this.editData = group;
    },
    handleClose() {
      this.addVisible = false;
      this.editData = null;
      this.onHide();
    },
    handleDelete(group) {
      this.deleteModalVisible = true;
      this.deleteData = group;
      event.stopPropagation();
    },
    handleDeleteClose() {
      this.deleteModalVisible = false;
      this.deleteData = null;
    }
  },

};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.action-button{
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 48px;
  cursor: pointer;
}

.action-button span {
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.btn-div2{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  bottom: 30px;
  left: 24px;
  right: 24px;
}

.group-list {
  margin-top: 15px;
  height: 100%;
  overflow-x: auto;
}

.group-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 0;
  gap: 5px;
  border-bottom: 1px solid #EAECF0;
}

.group-item:last-child {
  border-bottom: none;
}

.group-item .name {
  font-family: euclid_medium, sans-serif;
  color: #040C2D;
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
}

.group-item .short-description {
  font-family: euclid_regular, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 16px;
}

.group-item .left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.right-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.right-actions img {
  cursor: pointer;
  height: 18px;
  width: 18px;
}

.title-w-icon{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.form-input {
  font-size: 14px;
}

.textarea {
  height: 100px;
}

.modal-action {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
