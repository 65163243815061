<template>
  <div class="chart-stats">
    <span class="chart-value">{{  data.total }}</span>
    <img src="@/assets/images/upTrend.svg" alt="Up Trend" v-if="data.trend === 'up'"/>
    <img src="@/assets/images/downTrend.svg" alt="Up Trend" v-else/>

    <span class="chart-percent" :class="data.trend === 'up' ? 'up' : 'down'">{{  data.percentage }}%</span>
  </div>
  <span class="stat-description">{{  data.info }}</span>
</template>

<script>
export default {
  components: {

  },
  props: {
    data: {
      type: Object,
      default: {
        total: 0,
        trend: 'up',
        percentage: 0,
        info: ''
      },
    },
  },
}
</script>

<style scoped>
.chart-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chart-value {
  font-family: 'euclid_medium', sans-serif;
  font-size: 28px;
  margin-right: 10px;
  white-space: nowrap;
}

.chart-percent {
  font-family: 'euclid_semi_bold', sans-serif;
  font-size: 12px;
  margin-left: 5px;
}

.chart-percent.up {
  color: #3DA33E;
}

.chart-percent.down {
  color: #F09460;
}

.stat-description {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 12px;
  display: block;
}
</style>
