export default {
    mounted(el, binding) {
        const tooltip = document.createElement('div');
        tooltip.className = 'tooltip-wrapper';
        tooltip.textContent = binding.value;
        tooltip.style.visibility = 'hidden'; // Hide it initially
        document.body.appendChild(tooltip);

        const positionTooltip = () => {
            const rect = el.getBoundingClientRect();
            const tooltipRect = tooltip.getBoundingClientRect();

            const left = rect.left + (rect.width / 2) - (tooltipRect.width / 2);

            tooltip.style.left = `${left + window.scrollX}px`;
            tooltip.style.top = `${rect.bottom + window.scrollY + 5}px`;
            tooltip.style.visibility = 'visible';
        };

        el.addEventListener('mouseenter', () => {
            tooltip.style.display = 'block';
            setTimeout(positionTooltip, 0);
        });

        el.addEventListener('mouseleave', () => {
            tooltip.style.display = 'none';
            tooltip.style.visibility = 'hidden';
        });

        el._cleanup = () => {
            document.body.removeChild(tooltip);
        };
    },
    unmounted(el) {
        el._cleanup();
    }
}

