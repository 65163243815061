import axios from 'axios';

const baseDomain = import.meta.env.VITE_API_URL;



//const userLanguage = localStorage.getItem('userLanguage');
// Eğer kullanıcı daha önce tercih ettiyse ve localStorage'da saklandıysa, bu tercihi kullan

const instance = axios.create({
    baseURL: baseDomain,
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Accept-Language": "tr",
    },
});

instance.interceptors.request.use(
    (config) => {
        const clientToken = localStorage.getItem('clientToken');
        const authToken = localStorage.getItem('authToken');

        if (authToken) {
            config.headers.Authorization = `Bearer ${authToken}`;
        } else if (clientToken) {
            config.headers.Authorization = `Bearer ${clientToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('authToken');
            window.location.href = '/login';
        }

        return Promise.reject(error);
    }
);

export default instance;
