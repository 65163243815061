<template>
  <div class="pagination-wrapper">
    <vue-awesome-paginate
        :total-items="totalItems"
        v-model="page"
        :items-per-page="perPage"
        :max-pages-shown="5"
    >
      <template #prev-button>
        <img src="@/assets/images/chevronLeft.svg" class="chevron-svg" />
      </template>

      <template #next-button>
        <img src="@/assets/images/chevronRight.svg" class="chevron-svg" />
      </template>
    </vue-awesome-paginate>
  </div>
</template>

<script>
import {VueAwesomePaginate} from "vue-awesome-paginate";

export default {
  components: {VueAwesomePaginate},
  props: {
    totalItems: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 20,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    onPageChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  watch: {
    page: {
      handler: function (val) {
        this.onPageChange(val);
      },
      immediate: true,
    },
    currentPage: {
      handler: function (val) {
        this.page = val;
      },
      immediate: true,
    },
  },
};
</script>
<style>
.pagination-wrapper .pagination-container {
  column-gap: 5px;
  align-items: center;
}
.pagination-wrapper .paginate-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  border: none;
  color: black;
  font-size: 12px;
}

.pagination-wrapper .back-button,
.pagination-wrapper .next-button {
  background-color: #040C2D;
  color: white;
  border-radius: 8px;
  height: 36px;
  width: 36px;
  padding: 0;
}
.pagination-wrapper .active-page {
  background-color: #e5e5e5;
}
.pagination-wrapper .paginate-buttons:hover {
  background-color: #f5f5f5;
}
.pagination-wrapper .active-page:hover {
  background-color: #e5e5e5;
}

.pagination-wrapper .back-button svg {
  transform: rotate(180deg) translateY(-2px);
}
.pagination-wrapper .next-button svg {
  transform: translateY(2px);
}

.pagination-wrapper .back-button:hover,
.pagination-wrapper .next-button:hover {
  background-color: #091c6b;
}

.pagination-wrapper .back-button:active,
.pagination-wrapper .next-button:active {
  background-color: #091c6b;
}
.chevron-svg {
  width: 16px;
  height: 16px;
}
</style>
