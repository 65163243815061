<template>
  <div class="therapy-card">

    <div class="therapy-card-header">
      <img :src="image" v-if="!isLoading" class="header-image" />
      <free-style-shimmer
          class="header-image"
          :is-loading="isLoading"
          color="#EAECF0"
          height="160px"
          width="100%"
      />
      <router-link :to="to">
        <button class="details-button" v-if="!isLoading">{{ $t('details') }}</button>
      </router-link>

      <div class="status-tag" :class="isPublish ? 'Published' : 'Draft'">
        <div class="status-dot" :class="isPublish ? 'Published' : 'Draft'"></div>
        <span>{{isPublish ? $t('published') : $t('draft')}}</span>
      </div>

      <div class="therapy-card-body">
        <h2 v-if="!isLoading">
          {{ title }}
        </h2>
        <free-style-shimmer
            :is-loading="isLoading"
            height="12px"
            width="120px"
            border-radius="50px"
            color="#EAECF0"
        />

        <h4 v-if="!isLoading">
          {{ description }}
        </h4>

        <div class="info-row">
          <span class="info-tag">{{ $t('companies_count', {companiesCount: companiesAssignedCount}) }}</span>
          <span class="info-tag">{{ $t('user_count', {userCount: userAssignedCount}) }}</span>
        </div>

        <free-style-shimmer
            class="paragraph-shimmer top"
            :is-loading="isLoading"
            height="8px"
            width="170px"
            border-radius="50px"
            color="#EAECF0"
        />

        <free-style-shimmer
            class="paragraph-shimmer"
            :is-loading="isLoading"
            height="8px"
            width="140px"
            border-radius="50px"
            color="#EAECF0"
        />

        <router-link v-for="item in tests"  v-if="!isLoading" :to="`/test/${item.testId}`">

        <div class="tag-column" v-if="!isLoading">
        <span class="therapy-tag" v-for="tag in tags">
          {{ tag }}
        </span>
        </div>
        <div class="therapy-footer" v-if="!isLoading">
          <div class="therapy-info">
            <img src="@/assets/images/datepicker.svg" class="therapy-info-icon" />
            <span class="therapy-info-text">
            {{ formatDate(startAt) }} - {{ formatDate(finishAt) }}
          </span>
          </div>
          <div class="dot"></div>

          <div class="therapy-info" v-if="remaining.isPast === 0">
            <img
                src="@/assets/images/clockOutlineRed.svg"
                class="therapy-info-icon"
            />
            <span class="therapy-info-text red">Time is Up</span>
          </div>

          <div class="therapy-info" v-if="remaining.isPast === 1">
            <img
                src="@/assets/images/clockOutlineGreen.svg"
                class="therapy-info-icon"
            />
            <span class="therapy-info-text green">{{ remaining.time }}</span>
          </div>

          <div class="therapy-info" v-else-if="remaining.isPast === 2">
            <img
                src="@/assets/images/clockOutlineYellow.svg"
                class="therapy-info-icon"
            />
            <span class="therapy-info-text yellow">{{ remaining.time }}</span>
          </div>
        </div>

        </router-link>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    image: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "Therapy Name",
    },
    description: {
      type: String,
      default:
          "Lorem ipsum dolor sit amet consectetur. Elit suspendisse habitant vitae...",
    },
    tags: {
      type: Array,
      default: () => ["Relationship", "Marriage"],
    },
    remainingDays: {
      type: Number,
      default: 0,
    },
    remaining: {
      type: Array,
      default: () => ["isPast", "time"],
    },
    assignedAt: {
      type: String,
      default: "",
    },
    startAt: {
      type: String,
      default: "",
    },
    finishAt: {
      type: String,
      default: "",
    },
    isPublish: {
      type: Boolean,
      default: false,
    },
    companiesAssignedCount: {
      type: Number,
      default: 0,
    },
    userAssignedCount: {
      type: Number,
      default: 0,
    },
    to: {
      type: String,
      default: "/therapies",
    },
  },
  methods: {
    formatDate(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = { year: "numeric", month: "short", day: "numeric" };
        return date.toLocaleDateString("en", options);
      } catch (e) {
        return "-";
      }
    },
  },
};
</script>

<style scoped>
.therapy-card {
  position: relative;
  width: 100%;
  background-color: #f9fafb;
  border: 1px solid #eaecf0;
  border-radius: 16px;
}

.therapy-card-header {
  position: relative;
}

.details-button {
  visibility: hidden;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: #4a68f8;
  border-radius: 16px;
  padding: 5px 20px;
  border: none;
  outline: none;
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #ffffff;
  opacity: 0;
  transition: opacity 0.2s, visibility 0.2s;
}

.therapy-card:hover .details-button {
  opacity: 1;
  visibility: visible;
}

.header-image {
  width: 100%;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  height: 160px;
  object-fit: cover;
}

.therapy-card-body {
  padding: 15px;
}

h2 {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #101828;
  margin-bottom: 10px;
}

h4 {
  font-family: euclid_regular, sans-serif;
  color: #667085;
  font-size: 12px;
  margin-bottom: 15px;
}

.therapy-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #101828;
  background-color: #e9eaef;
  border-radius: 8px;
  padding: 3px 12px;
}

.therapy-info {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.therapy-info-text {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #667085;
}

.therapy-info-text.red {
  color: #ff2121;
}

.therapy-info-text.yellow {
  color: #f1c40f;
}

.therapy-info-text.green {
  color: #088701;
}

.dot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #d0d5dd;
}

.therapy-footer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  margin-top: 20px;
}

.therapy-info-icon {
  width: 24px;
  height: 24px;
}

.paragraph-shimmer.top {
  margin-top: 15px;
}

.paragraph-shimmer {
  margin-bottom: 5px;
}

.tag-column {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 15px;
}

.status-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #040c2d;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.status-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.status-tag.Draft {
  background-color: #fad9c6;
}

.status-tag.Published {
  background-color: #ddfbde;
}

.status-dot.Draft {
  background-color: #f09460;
}

.status-dot.Published {
  background-color: #3da33e;
}

.therapies-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

@media (max-width: 1024px) {
  .therapies-row {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .therapies-row {
    grid-template-columns: 1fr;
  }
}

.info-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 10px;
  color: #040C2D;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 12px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #F2F4F7;
}

.info-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-top: auto;
}

</style>
