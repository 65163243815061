<template>
  <Transition name="fade">
    <div v-if="isVisible" class="modal-overlay">

      <div class="modal-content">
        <div class="modalheader">
          <h2 v-if="!editMode">{{$t('add_new_tag')}}</h2>
          <h2 v-else>{{$t('edit_tag')}}</h2>

          <div @click="onClose" class="close-btn">
            <img class="cross" src="@/assets/images/cross.svg" />
          </div>
        </div>
        <div class="modalbody">

          <input class="form-input" :placeholder="this.$t('tag_name')" v-model="tagData.name">
          <SelectBox class="select-box" :options="tagTypes" :placeholder="this.$t('tag_type')" :selected-value="tagData.color" :on-select="handleTypeSelect" />
          <div class="status-row">
            <span class="status-text">{{$t('status')}}</span>
            <Switch v-model="tagData.status" />
          </div>

          <div class="action-row">
            <button class="dark-button" @click="onAction">
              <span v-if="!loading && !editMode">{{$t('add_tag')}}</span>
              <span v-if="!loading && editMode">{{$t('update_tag')}}</span>
              <VueSpinnerIos v-if="loading" size="20" height="100%" color="#fff" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import SelectBox from "@/components/SelectBox.vue";
import {
  VueSpinnerIos
} from 'vue3-spinners'
import tagService from "@/service/tagService";
import {handleErrorResponse} from "@/utils/utils";
import Switch from "@/components/Switch.vue";

export default {
  components: {
    Switch,
    SelectBox,
    VueSpinnerIos
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    editMode: {
      type: Boolean,
      default: false
    },
    editData: {
      type: Object,
      default: {
        tagId: "",
        name: "",
        color: "primary",
        status: true,
      }
    },
    refreshCallback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      tagTypes: [
        {
          value: "primary",
          text: "Primary"
        },
        {
          value: "warning",
          text: "Warning"
        },
        {
          value: "success",
          text: "Success"
        },
        {
          value: "danger",
          text: "Danger"
        }
      ],
      tagData: {
        name: "",
        color: "primary",
        status: true,
      }
    }
  },
  methods: {
    handleTypeSelect(value) {
      this.tagData.color = value;
    },
    onAction() {
      if (this.editMode) {
        this.handleEditTag();
      } else {
        this.handleAddTag();
      }
    },
    handleAddTag() {
      if (!this.tagData.name || this.loading) {
        return;
      }

      this.loading = true;
      tagService.addTag(this.tagData).then(response => {
        this.loading = false;
        this.$snackbar.add({
          text: "Tag added successfully",
          type: "success"
        });
        this.tagData = {
          name: "",
          color: "primary",
          status: true,
        };
        this.refreshCallback();
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
        this.loading = false;
      })
    },
    handleEditTag() {
      if (!this.tagData.tagId || !this.tagData.name || this.loading) {
        return;
      }

      this.loading = true;
      tagService.updateTag(this.tagData).then(response => {
        this.loading = false;
        this.$snackbar.add({
          text: "Tag updated successfully",
          type: "success"
        });
        this.refreshCallback();
      }).catch(error => {
        handleErrorResponse(error, this.$snackbar);
        this.loading = false;
      })
    }
  },
  watch: {
    editData: {
      handler: function (val) {
        if (this.editMode) {
          this.tagData = val;
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 20px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:12px
}

.modalheader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.modalheader h2 {
  color: #040C2D;
  text-align: left;
  font-family: euclid_medium, sans-serif;
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
}

.close-btn {
  height: 24px;
  width: 24px;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cross{
  height: 10px !important;
  width: 10px !important;
}

.modalbody {
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.modalbody p {
  color: #667085;
  text-align: center;
  align-items: center;
  font-family: euclid_regular, sans-serif;
  font-size: 16px;
  font-style: normal;
  line-height: 24px; /* 150% */
}


.modalbutton span {
  color: #FFF;
  text-align: center;
  font-family: euclid_regular, sans-serif;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.modalbutton.cancel span {
  color: #667085;
  text-align: center;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
}

.action-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.status-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;
}

.status-text {
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  color: #040C2D;
}

.form-input, .select-box {
  width: 100%;
}

</style>
