<template>
  <table>
    <thead>
      <tr>
        <th v-for="label in data.labels" :key="label">{{ label }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="row in data.content" :key="row">
        <td v-for="cell in Object.keys(row)" :key="cell">
          <div class="inner-table-wrapper" v-if="row[cell]?.icon" >
            <img :src="row[cell]?.icon" />
            <span>{{ row[cell]?.name }}</span>
          </div>
          <div class="inner-table-wrapper" v-else >
            <span>{{truncate(row[cell], 50)}}</span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>


<script>
import {truncate} from "../../utils/utils";

export default {
  methods: {truncate},
  props: {
    data: {
      type: Object,
      default: {
        labels: [],
        content: [[]],
      },
    },
  },
}
</script>

<style scoped>
thead {
  background-color: #F5F6F8;
}

tr td {
  border-bottom: 1px solid #F2F4F7;

}

.inner-table-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.inner-table-wrapper span {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #101828;
  padding: 10px;
}

.inner-table-wrapper img {
  width: 24px;
  height: 24px;
}
</style>
