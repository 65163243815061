import axios from '../axios';
import {convertBooleanValuesToNumbers} from "@/utils/utils";

const blogService = {
    getBlogs(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/blog/store?page=${page}&limit=20${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/blog/store?page=${page}&limit=20`);
        }
    },
    getBlogList() {
        return axios.get(`/v1/admin/blog/store?list=1`);
    },
    getBlogDetail(id) {
        return axios.get(`/v1/admin/blog/getFromId/${id}`);
    },
    addBlog(data) {
        data = convertBooleanValuesToNumbers(data);


        return axios.post('/v1/admin/blog/add', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateBlog(data) {
        data = convertBooleanValuesToNumbers(data);

        return axios.post('/v1/admin/blog/update', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    deleteBlog(id) {
        return axios.delete(`/v1/admin/blog/delete/${id}`);
    }
}

export default blogService;
