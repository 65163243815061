import { createRouter, createWebHistory } from 'vue-router';
import routes from "@/router/routes";
import authService from "@/service/authService";

const router = createRouter({
    history: createWebHistory(),
    routes
});


router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        const token = localStorage.getItem('authToken');
        if (token) {
            next();
        } else {
            next('/login');
        }
    } else if (to.name === 'Login') {
        const token = localStorage.getItem('authToken');

        if (token) {
            next('/');
        } else {
            next();
        }
    } else {
        const clientTokenResponse = authService.clientToken();

        clientTokenResponse.then((response) => {
            const clientToken = response.data.data.access_token;
            localStorage.setItem('clientToken', clientToken);
        });

        next();
    }
});

export default router;
