
<template>
  <div class="chart-wrapper">
    <apexchart type="bar" height="350" :options="{
      ...chartOptions,
      xaxis: {
        categories: data.labels,
      },
    }" :series="[
      {
        name: '',
        data: data.series,
      }
    ]"></apexchart>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: {
        series: {},
        labels: [],
      },
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          height: 350,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top',
            },
          }
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
          }
        },
      },
    }
  }
}
</script>
