<template>
  <div class="flex-row">
    <div class="row-item no-text-select" v-if="showDateRangeFilter" v-click-outside="() => closeFilter('date_range')">
      <div @click="toggleDateRangeFilter">
        <img src="../../../assets/images/filterdaterange.svg" class="row-item-icon"/>
        <span class="row-item-txt">
          {{$t('calendar')}}{{dateRangeFilterData.startDate || dateRangeFilterData.endDate ? ':' : ''}} <span class="show-filters"> {{dateRangeFilterData.startDate ? dateRangeFilterData.startDate : ''}}{{dateRangeFilterData.startDate && dateRangeFilterData.endDate ? ' / ' : ''}}{{dateRangeFilterData.endDate ? dateRangeFilterData.endDate : ''}}</span>
          </span>
      </div>
      <DateFilter
          v-if="showDateRangeDropdown"
          :visible.sync="showDateRangeDropdown"
          :on-filter="handleApplyDateFilters"
      />
      <img src="../../../assets/images/cross.svg" @click="() => removeFilter('date_range')" />
    </div>

    <div class="row-item filter" v-click-outside="() => closeFilter('main')" @click="toggleFilter">
      <img src="../../../assets/images/filter.svg">
      <span class="row-item-txt no-text-select">
            {{ $t('filter_therapy') }}
        </span>
      <FilterModal :visible.sync="showFilter" @filter-selected="handleFilterSelected"/>
    </div>
  </div>
</template>

<script>
import DateFilter from "@/components/table-filters/DateFilter.vue";
import FilterModal from "@/components/tables/Blog/BlogFilterModal.vue";
import MinMaxNumberFilter from "@/components/table-filters/MinMaxNumberFilter.vue";

export default {
  components: {
    MinMaxNumberFilter,
    DateFilter,
    FilterModal
  },
  watch: {
    '$route.query': {
      handler(newQuery, oldQuery) {

        if (this.$route.query.addedDate) {
          this.showDateRangeFilter = true;
          this.dateRangeFilterData.startDate = this.$route.query.addedDate;
        } else {
          this.dateRangeFilterData.startDate = undefined
        }

        if (this.$route.query.addedDateEnd) {
          this.showDateRangeFilter = true;
          this.dateRangeFilterData.endDate = this.$route.query.addedDateEnd;
        } else {
          this.dateRangeFilterData.endDate = undefined
        }
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      showFilter:false,

      showDateRangeFilter: false,
      showDateRangeDropdown: false,
      dateRangeFilterData: [{
        startDate: undefined,
        endDate: undefined,
      }],
    }
  },
  methods: {
    handleFilterSelected(filterName) {
      this.closeFilter('main');
      if (filterName === 'date_range') {
        this.showDateRangeFilter = true;
        this.showDateRangeDropdown = true;
      }
    },
    formatDateForBackend(dateString) {
      if (!dateString) return undefined;
      const [day, month, year] = dateString.split('-');
      return `${year}-${month}-${day}`;
    },
    handleApplyDateFilters(filters){
      this.dateRangeFilterData.startDate = filters.startDate;
      this.dateRangeFilterData.endDate = filters.endDate;
      this.showDateRangeDropdown = false;

      this.$router.push({
        query: {
          ...this.$route.query,
          addedDate: this.formatDateForBackend(filters.startDate),
          addedDateEnd: this.formatDateForBackend(filters.endDate)
        }
      });
    },

    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    toggleDateRangeFilter() {
      this.showDateRangeDropdown = !this.showDateRangeDropdown;
    },

    closeFilter(filter) {
      if (filter === 'main' && this.showFilter === true) {
        this.showFilter = false;
      } if (filter === 'date_range' && this.showDateRangeDropdown === true) {
        this.showDateRangeDropdown = false;
      }
    },
    removeFilter(filter) {
      if (filter === 'date_range') {
        this.showDateRangeFilter = false;
        this.dateRangeFilterData = [{
          start: undefined,
          end: undefined,
        }];
        this.$router.push({
          query: {
            ...this.$route.query,
            addedDate: undefined,
            addedDateEnd: undefined
          }
        })
      }
    },
  }
}
</script>

<style scoped>

.flex-row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
}
.row-item{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}

.row-item.filter {
  background-color: transparent;
}

.row-item-txt{
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
}

.show-filters{
  font-family: euclid_medium, sans-serif;
  color: #4A68F8;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
}
</style>
