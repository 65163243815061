import axios from '../axios';

const roleService = {
    getMenuModules() {
        return axios.get(`/v1/admin/module/store`);
    },
    getRoles() {
        return axios.get(`/v1/admin/role/store?list=1`);
    },
    getRole(id) {
        return axios.get(`/v1/admin/permission/getFromId?roleId=${id}`);
    },
    updatePermissions(data) {
        return axios.post(`/v1/admin/permission/add`, data);
    },
    addRole(data) {
        return axios.post(`/v1/admin/role/add`, data);
    },
    updateRole(data) {
        return axios.post(`/v1/admin/role/update`, data);
    },
    deleteRole(roleId) {
        return axios.delete(`/v1/admin/role/delete/${roleId}`);
    }
}

export default roleService;
