<template>
  <div class="table-container">
    <table class="content-table">
      <thead class="header-table">
      <tr class="trhead">
        <th>{{$t('name_journeys')}}</th>
        <th>{{$t('content')}}</th>
        <th>{{$t('types')}}</th>
        <th>{{$t('status')}}</th>
        <th>{{$t('send_date')}}</th>
      </tr>
      </thead>

      <tbody class="tbody-container">
      <tr class="tr-class" v-if="!loading" v-for="(item, index) in data" :key="index">
        <router-link class="td-style td-row" :to="'/notification/' + item.notificationId">
          <img v-if="item?.content?.type === 'video'" src="@/assets/images/videoOutline.svg" />
          <img v-else-if="item?.content?.type === 'blog'"  src="@/assets/images/blogOutline.svg" />
          <img v-else-if="item?.content?.type === 'text'" src="@/assets/images/documentOutline.svg" />
          <img v-else-if="item?.content?.type === 'documentDownload'" src="@/assets/images/downloadOutline.svg" />
          <img v-else-if="item?.content?.type === 'documentUpload'" src="@/assets/images/uploadOutline.svg" />
          <img v-else-if="item?.content?.type === 'link'" src="@/assets/images/linkOutline.svg" />
          <img v-else-if="item?.content?.type === 'audio'" src="@/assets/images/audioOutline.svg" />

          <div class="td-col">
            <span class="notif-title" v-if="item?.content?.label">{{item?.content?.label}}</span>
            <i class="notif-title" v-else>
              {{$t('no_label')}}
            </i>
            <span class="notif-subject">{{ item?.content?.subject }}</span>
          </div>
        </router-link>

        <td class="td-style">
          <span class="test-class">{{ truncate(stripHtmlTags(item?.content?.content), 50) }}</span>
        </td>
        <td class="td-style td-row">
          <span class="type-tag" v-for="type in item.types">{{type}}</span>
        </td>

        <td class="td-style">
          <div class="status-tag" :class="item.status ? 'Active' : 'Draft'">
            <div class="status-dot" :class="item.status ? 'Active' : 'Draft'"></div>
            <span>{{item.status ? this.$t('active') : this.$t('draft')}}</span>
          </div>
        </td>

        <td class="td-style">
          <span class="test-class" v-if="item.sendDate">
            {{item.sendDate}}
          </span>
          <i class="test-class" v-else>
            {{$t('no_send_date')}}
          </i>
        </td>
      </tr>

      <tr v-else>
        <td colspan="7" class="table-loading">
          <VueSpinner size="16" height="100%" color="#8c8c8c" />
          <span class="text">{{$t('loading')}}</span>
        </td>
      </tr>
      <tr v-if="!loading && data.length === 0 ">
        <td colspan="7" class="table-loading">
          <span class="text">{{$t('no_data_found')}}</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import AddUserModal from '@/components/modals/InviteUserModal.vue';
import FilterModal from '@/components/tables/Company/CompanyFilterModal.vue';
import CompanyFieldFilter from '@/components/table-filters/CompanyFieldFilter.vue';
import TherapyFilter from "@/components/table-filters/NumberFilter.vue";
import UserAmountFilter from "@/components/table-filters/MinMaxNumberFilter.vue";
import PaymentVolume from "@/components/table-filters/PaymentVolume.vue";
import DateFilter from "@/components/table-filters/DateFilter.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import {VueSpinner} from "vue3-spinners";
import {truncate, stripHtmlTags} from "@/utils/utils";

export default {
  components: {
    DateFilter,
    PaymentVolume,
    UserAmountFilter,
    AddUserModal,
    FilterModal,
    CompanyFieldFilter,
    TherapyFilter,
    ExpandableSearch,
    VueSpinner
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    data: {
      type: Array,
      required: true
    },
    rightAction: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showModal: false,
      selectAll: false,
      showFilter: false,
      searchText: '',
    }
  },

  methods: {
    stripHtmlTags,
    truncate,
    handleSearch(event) {
      this.searchText = event.target.value;
    }
  },

}
</script>

<style>
.table-container {
  width: 100%;
  height: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: left;
  padding: 8px;
}

.trhead {
  background-color: transparent;
}

.tr-class {
  border-bottom-width: 1px;
  border-bottom-color: #F2F4F7;
}

tr td {
  padding-bottom: 12px;
  padding-top: 12px;
  align-items: center;
}

.table-container {
  width: 100%;
}

tr th {
  align-items: center;
  font-size: 12px;
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.header-table,
.content-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.header-table {
  background-color: #F9FAFB;
  border-radius: 8px;
}

th, td {
  text-align: left;
  padding: 8px;
  border: 1px solid #F2F4F7;
}

.tbody-container {
  overflow-y: auto;
}

.tr-class td tr {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.test-class {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
}

.name-row span {
  font-family: euclid_medium, sans-serif;
  color: #000;
}

.name-row:hover.name-row span {
  color: #4A68F8;
}

.td-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  height: 100%;
}

.td-col {
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: flex-start;
}

.notif-title {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #000000;
}

.notif-subject {
  font-family: euclid_regular, sans-serif;
  font-size: 10px;
  color: #344054;
}


.status-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 10px;
  color: #040c2d;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
}

.status-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.status-tag.Draft {
  background-color: #fad9c6;
}

.status-tag.Active {
  background-color: #ddfbde;
}

.status-dot.Draft {
  background-color: #f09460;
}

.status-dot.Active {
  background-color: #3da33e;
}

.type-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 10px;
  color: #040c2d;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  background-color: #f2f2f2;
  text-transform: capitalize;
}
</style>
