<template>
  <div v-if="visible" class="filter-modal">
    <div class="filter-div">
      <div
          class="filter-item"
          v-for="item in filterItems"
          :key="item.id"
          @click="toggleCheck(item.name)"
      >
        <span class="filter-title">{{ item.name }}</span>
        <img v-if="selectedFilter === item.name" src="../../assets/images/checkboxchecked-circle.svg">
        <img v-else src="../../assets/images/checkbox-circle.svg">
      </div>
      <div
          class="filter-item"
          @click="toggleCustomSelect"
      >
        <span class="filter-title">{{$t('custom')}}</span>
        <img src="../../assets/images/arrowdown.svg">

      </div>
      <div  v-if="isCustom" class="text-input">
        <input class="text-input-primary textinp" placeholder="Start Date (DD-MM-YYYY)" @input="handleStartDate" :value="startDate" type="date"/>
        <input class="text-input-primary textinp" placeholder="End Date (DD-MM-YYYY)" @input="handleEndDate" :value="endDate" type="date"/>
      </div>
      <div class="btn-style" @click="applyFilters">
        <span class="filter-txt">{{$t('filter_therapy')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onFilter: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      selectedFilter:null,
      isCustom:false,
      startDate: "",
      endDate: "",
      filterItems:[
        {
          id: 1,
          name: this.$t('today'),
        },
        {
          id: 2,
          name: this.$t('this_week'),
        },
        {
          id: 3,
          name: this.$t('this_month'),
        },
        {
          id: 4,
          name: this.$t('last_three_months'),
        },
      ]
    };
  },
  methods: {
    toggleCustomSelect(){
      this.isCustom = !this.isCustom;
    },
    toggleCheck(itemName) {
      if (this.selectedFilter === itemName) {
        this.selectedFilter = null;
      } else {
        this.selectedFilter = itemName;

        let today = new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
        let startOfWeek = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() - today.getUTCDay()));
        let startOfMonth = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1));
        let threeMonthsAgo = new Date(Date.UTC(today.getFullYear(), today.getMonth() - 3, 1));

        if (this.selectedFilter === "Today") {
          this.startDate = today.toISOString().split('T')[0];
          this.endDate = today.toISOString().split('T')[0];
        } else if (this.selectedFilter === "This week") {
          this.startDate = startOfWeek.toISOString().split('T')[0];
          this.endDate = today.toISOString().split('T')[0];
        } else if (this.selectedFilter === "This month") {
          this.startDate = startOfMonth.toISOString().split('T')[0];
          this.endDate = today.toISOString().split('T')[0];
        } else if (this.selectedFilter === "Last 3 months") {
          this.startDate = threeMonthsAgo.toISOString().split('T')[0];
          this.endDate = today.toISOString().split('T')[0];
        }
      }

      event.stopPropagation();
    },
    applyFilters() {
      this.onFilter({
        startDate: this.startDate,
        endDate: this.endDate,
      });
    },
    handleStartDate(event) {
      this.startDate = event.target.value;
    },
    handleEndDate(event) {
      this.endDate = event.target.value;
    },
  },
};
</script>

<style scoped>
.filter-modal {
  position: absolute;
  top: 40px;
  display: flex;
  left: 0;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.08);
  min-width: 230px;
  padding: 12px;
}
.filter-div{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap:4px;
  overflow: visible;
  width: 100%;
}
.filter-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap:4px;
  padding: 12px;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
}
.filter-title{
  color: #475467;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}
.btn-style{
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: #040C2D;
  margin: 12px;
  cursor:pointer;
}
.filter-txt{
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.text-input{
  display: flex;
  padding: 12px;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  gap:12px
}
.textinp{
  height: 32px;
}
</style>
