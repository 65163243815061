<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content" v-if="page === 1">
        <div class="modal-header">
          <span class="modal-header-txt">Edit Certification</span>
        </div>

        <button class="add-button" @click="page = 2">
          {{$t('add_new_certificate')}} <img src="@/assets/images/addWhite.svg" />
        </button>

        <div class="info-list">
          <div class="info-card" v-for="item in certificationData">
            <div class="info-left">
              <img src="https://i.hizliresim.com/gzzvvnq.png" />
              <div class="info">
                <span class="title">{{ item.name }}</span>
                <span class="description">{{ item.institution }}</span>
                <span class="date">
                {{item.finishAt}}
              </span>
              </div>
            </div>
            <img src="@/assets/images/editPen.svg" class="edit-icon" @click="editCert(item.certificateId)"/>
          </div>
        </div>


        <div class="btn-div2">
          <div></div>
          <div class="action-button" @click="onUpdate">
            <span v-if="!isLoading">{{$t('save_button')}}</span>
            <VueSpinnerIos v-if="isLoading" size="20" height="100%" color="#fff" />
          </div>
        </div>

        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>

      <div class="modal-content" v-if="page === 2">
        <div class="modal-header">
          <img src="@/assets/images/arrowLeft.svg" @click="page = 1" />
          <span class="modal-header-txt">Add New Certificate</span>
        </div>

        <div class="school-form">
          <span class="form-label">Name</span>
          <input type="text" class="form-input" placeholder="Name" v-model="certData.name"/>
          <span class="form-label">Institution</span>
          <input type="text" class="form-input" placeholder="Institution" v-model="certData.institution"/>


          <div class="double-form">
            <div class="w-100">
              <span class="form-label">Start Date</span>
              <input type="date" class="form-input" placeholder="Start Date" @input="handleAddStartDate" />
            </div>

            <div class="w-100">
              <span class="form-label">Finish Date</span>
              <input type="date" class="form-input" placeholder="Finish Date" @input="handleAddEndDate" />
            </div>
          </div>

        </div>

        <div class="btn-div2">
          <div></div>
          <div class="action-button" @click="onAddCert">
            <span v-if="!isLoading">Save</span>
            <VueSpinnerIos v-if="isLoading" size="20" height="100%" color="#fff" />
          </div>
        </div>

        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>

      <div class="modal-content" v-if="page === 3">
        <div class="modal-header">
          <img src="@/assets/images/arrowLeft.svg" @click="page = 1" />
          <span class="modal-header-txt">Edit Certificate</span>
        </div>

        <div class="school-form">
          <span class="form-label">Name</span>
          <input type="text" class="form-input" placeholder="Name" v-model="editData.name"/>
          <span class="form-label">Institution</span>
          <input type="text" class="form-input" placeholder="Institution" v-model="editData.institution"/>

          <div class="double-form">
            <div class="w-100">
              <span class="form-label">Start Date</span>
              <input type="date"  class="form-input" placeholder="Start Date" :value="editData.startAt" @input="handleEditStartDate"/>
            </div>

            <div class="w-100">
              <span class="form-label">Finish Date</span>
              <input type="date" class="form-input" placeholder="Finish Date" :value="editData.finishAt" @input="handleEditEndDate" />
            </div>
          </div>
        </div>

        <div class="btn-div2">
          <div></div>
          <div class="action-button" @click="onUpdateCert">
            <span v-if="!isLoading">Save</span>
            <VueSpinnerIos v-if="isLoading" size="20" height="100%" color="#fff" />
          </div>
        </div>

        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>

    </div>
  </Transition>
</template>

<script>
import expertService from "@/service/expertService";

import {VueSpinnerIos} from 'vue3-spinners'
import SelectBox from "@/components/SelectBox.vue";
import commonService from "@/service/commonService";
import {handleErrorResponse} from "@/utils/utils";

export default {
  components: {
    SelectBox,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    expertId: {
      type: String,
      default: ""
    },
    certificationData: {
      type: Object,
      default: []
    }
  },
  data() {
    return {
      isLoading: false,
      page: 1,
      editId: "",
      certData: {
        name: "",
        institution: "",
        startAt: new Date(),
        finishAt: new Date(),
      },
      editData: {
        name: "",
        institution: "",
        startAt: new Date(),
        finishAt: new Date(),
      }
    };
  },
  methods: {
    onUpdate() {
      this.isLoading = true;
    },
    editCert(id) {
      this.page = 3;
      this.editId = id;
      const tempData = this.certificationData.find(item => item.certificateId === id);
      this.editData = {
        certificateId: tempData.certificateId,
        name: tempData.name,
        institution: tempData.institution,
        startAt: this.convertDmyToYMD(tempData.startAt),
        finishAt: this.convertDmyToYMD(tempData.finishAt),
      }
    },
    onAddCert() {
      this.isLoading = true;

      const tempData = {
        expertId: this.expertId,
        name: this.certData.name,
        institution: this.certData.institution,
        startAt: this.convertYMDToDmy(this.certData.startAt),
        finishAt: this.convertYMDToDmy(this.certData.finishAt),
      }

      expertService
          .addCertificate(tempData)
          .then(() => {
            this.isLoading = false;

            this.$snackbar.add({
              text: "Certificate added successfully",
              type: "success",
            });
          })
          .catch((error) => {
            this.isLoading = false;
            handleErrorResponse(error, this.$snackbar);
          });
    },
    onUpdateCert() {
      this.isLoading = true;

      const tempData = {
        certificateId: this.editId,
        expertId: this.expertId,
        name: this.editData.name,
        institution: this.editData.institution,
        startAt: this.convertYMDToDmy(this.editData.startAt),
        finishAt: this.convertYMDToDmy(this.editData.finishAt),
      }

      expertService
          .updateCertificate(tempData)
          .then(() => {
            this.isLoading = false;

            this.$snackbar.add({
              text: "Certificate updated successfully",
              type: "success",
            });
          })
          .catch((error) => {
            this.isLoading = false;
            handleErrorResponse(error, this.$snackbar);
          });
    },
    formatDateToDmy(inputDate) {
      if (!(inputDate instanceof Date)) {
        console.error('Invalid input. Please provide a valid Date object.');
        return;
      }

      const day = String(inputDate.getDate()).padStart(2, '0');
      const month = String(inputDate.getMonth() + 1).padStart(2, '0');
      const year = inputDate.getFullYear();

      return `${day}.${month}.${year}`;
    },
    convertDmyToYMD(dateString) {
      const [day, month, year] = dateString.split('.');

      const dateObject = new Date(`${year}-${month}-${day}`);

      const yearResult = dateObject.getFullYear();
      const monthResult = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const dayResult = String(dateObject.getDate()).padStart(2, '0');

      return `${yearResult}-${monthResult}-${dayResult}`;
    },
    convertYMDToDmy(dateString) {
      const dateObject = new Date(dateString);

      const day = String(dateObject.getDate()).padStart(2, '0');
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const year = dateObject.getFullYear();

      return `${day}.${month}.${year}`;
    },
    handleAddStartDate(event) {
      this.certData.startAt = event.target.value;
    },
    handleAddEndDate(event) {
      this.certData.finishAt = event.target.value;
    },
    handleEditStartDate(event) {
      this.editData.startAt = event.target.value;
    },
    handleEditEndDate(event) {
      this.editData.finishAt = event.target.value;
    }
  },

};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
.action-button{
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 48px;
  cursor: pointer;
}

.action-button span {
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.btn-div2{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  bottom: 30px;
  left: 24px;
  right: 24px;
}

.info-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.info-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.info-card .info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.info-card img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.info-card .title {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
}

.info-card .description {
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  color: #667085;
}

.info-card .date {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #667085;
}

.info-list {
  height: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.edit-icon {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}

.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #3DA33E;
  border-radius: 12px;
  padding: 5px 15px;
  border: none;
  cursor: pointer;
  color: white;
  width: fit-content;
  margin-top: 15px;
  margin-bottom: 15px;
}

.double-form{
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
}

.form-input {
  margin-bottom: 10px;
}
</style>
