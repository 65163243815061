import axios from '../axios';

const testDimensionService = {
    getDimensions(testId) {
        return axios.get(`/v1/admin/test/dimensional/store?list=1&testId=${testId}`);
    },
    updateDimension(data) {
        return axios.post(`/v1/admin/test/dimensional/update`, data);
    },
    addDimension(data) {
        return axios.post(`/v1/admin/test/dimensional/add`, data);
    },
    setSelectedQuestions(data) {
        return axios.post(`/v1/admin/test/dimensional/setQuestions`, data);
    },
    deleteDimension(id) {
        return axios.delete(`/v1/admin/test/dimensional/delete/${id}`);
    },
}

export default testDimensionService;
