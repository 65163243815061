<template>
  <div class="grade-wrapper">
    <div class="grade-row">
      <div class="grade-box" v-for="i in boxCount" :key="i" :class="{'selected': i === selected}">
        {{ i }}
      </div>
    </div>
    <div class="footer-row">
      <span>Min</span>
      <span>Max</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Number,
      default: -1
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10
    },
  },
  data () {
    return {

    }
  },
  methods: {

  },
  computed: {
    boxCount() {
      return this.max - this.min ;
    }
  }
};

</script>

<style scoped>
.grade-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.grade-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-evenly;
  width: 100%;
}

.grade-box {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.1s linear;
  font-size: 14px;
  color: #000000;
  font-family: euclid_medium, sans-serif;
}

.grade-box:hover {
  background-color: #F5F6FA;
}

.grade-box.selected {
  background-color: #EAECF0;
}

.footer-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 12px;
  color: #000000;
}
</style>
