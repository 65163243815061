<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">

        <div class="modal-header">
          <span class="modal-header-txt">Edit specialties</span>
        </div>

        <div class="btn-div2">
          <div class="search-speciality-div">
            <span class="speciality-txt">Search Speciality</span>
          </div>
        </div>
        <div class="search-speciality">
          <input type="text" placeholder="Ex: Communication" style="font-size:14px;">
          <img class="search" src="../../assets/images/search.svg" style="height: 24px; width: 24px;" />
        </div>

        <div class="specialities-tags">

          <span class="category-tag" v-for="item in tagData">{{item.name}}
            <img class="cross" src="../../assets/images/cross.svg" />
          </span>

        </div>

        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import expertService from "@/service/expertService";

import {
  VueSpinnerIos
} from 'vue3-spinners'
import {handleErrorResponse} from "@/utils/utils";

export default {
  components: {
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    expertId: {
      type: String,
      default: ""
    },
    tagData: {
      type: Object,
      default: [],
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    onUpdate() {
      this.isLoading = true;

    }
  },

};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 3000px;
}

.cross{
  padding: 8px;
}

search{
 width:24px;
  height:24px;
}

.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}

.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.btn-div2{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  bottom: 30px;
  left: 24px;
  right: 24px;
}

.search-speciality{
  display:flex;
  height:40px;
  width:100%;
  background-color:#F5F5F5;
  border-radius:8px;
  padding-right:20px;
  justify-content: center;
  align-items: center;
}

.speciality-txt{
  color: #040C2D;
  font-size: 14px;
  font-style: normal;
  line-height:20px;
}

.search-speciality input[type="text"] {
  flex: 1;
  padding: 0 10px;
  border: none;
  background-color: transparent;
  border-radius: 8px;
  outline: none;
}

.specialities-tags {
  margin-top:50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.category-tag {
  padding: 2px 10px;
}

</style>
