<template>
  <Layout>
    <div class="table-holder">
      <h3 class="table-title">{{$t('all_users')}}</h3>
      <JourneyUserTable :users="tableData.data" :loading="isLoading" />

      <div class="pagination-align-center">
        <Pagination :total-items="tableData.total" :per-page="50" :current-page="currentPage" :on-page-change="handlePage"/>
      </div>
    </div>
  </Layout>
</template>

<script>
import BarChart from '@/components/charts/BarChart.vue'
import Layout from "@/components/layouts/Layout.vue";
import AvatarArray from "@/components/AvatarArray.vue";
import Pagination from "@/components/Pagination.vue";
import JourneyUserTable from "@/components/tables/Journey/JourneyUserTable.vue";
import UserTable from "@/components/tables/User/UserTable.vue";
import journeyService from "@/service/journeyService";
import {handleErrorResponse} from "@/utils/utils";

export default {
  name: 'Journeys',
  components: {
    UserTable,
    JourneyUserTable,
    Pagination,
    AvatarArray,
    Layout,
    BarChart,
  },
  data() {
    return {
      isLoading: true,
      data: {},
      tableData: {
        data: [],
        total: 0
      },
      currentPage: 1,
    }
  },
  watch: {
    $route() {
      this.handlePage(1);
    }
  },
  methods: {
    handlePage(page) {
      if (this.isLoading && this.currentPage !== 1) return;

      this.isLoading = true;
      this.currentPage = page;

      const routeQuery = this.$route.query;
      const tempFilters = [];

      if (routeQuery.searchText) {
        tempFilters.push({
          key: 'searchText',
          value: routeQuery.searchText
        });
      }

      if (routeQuery.addedDate) {
        tempFilters.push({
          key: 'addedDate',
          value: routeQuery.addedDate
        });
      }

      if (routeQuery.addedDateEnd) {
        tempFilters.push({
          key: 'addedDateEnd',
          value: routeQuery.addedDateEnd
        });
      }

      journeyService.getJourneys(page, tempFilters).then((res) => {
        this.tableData = res.data.data;
        this.isLoading = false;
      }).catch((err) => {
        handleErrorResponse(error, this.$snackbar);
      });
    }
  }
}
</script>

<style scoped>
.icon-title span {
  font-family: 'euclid_medium', sans-serif;
  font-size: 18px;
  white-space: nowrap
}

.icon-holder img {
  cursor: default;
}



.table-title {
  font-family: 'euclid_medium', sans-serif;
  font-size: 20px;
  color: #040C2D;
  margin-bottom: 20px;
}
</style>
