<script setup>
import { Handle, Position } from '@vue-flow/core'
import testService from "@/service/testService";
import {onMounted, ref, watch} from "vue";
import {handleErrorResponse} from "@/utils/utils";

const props = defineProps(['id', 'data', 'testList', 'onSelect', 'editDisabled']);
const questionsLoading = ref(false);
const testQuestionList = ref([]);
const selectedTest = ref(null);
const selectedQuestion = ref(null);
const selectedCondition = ref(null);
const selectedOption = ref(null);
const mcqQuestion = ref(false);

const checkQuestionMCQ = () => {
  if (selectedQuestion.value) {
    mcqQuestion.value = selectedQuestion.value.type === 'single' || selectedQuestion.value.type === 'multiple' || selectedQuestion.value.type === 'yesNo';
  }
}

const handleTestSelect = async (event) => {
  const testId = event.target.value;
  if (testId === '-1') return;

  const item = props.testList.find((test) => test.id === testId);
  selectedTest.value = item;
  questionsLoading.value = true;

  await loadQuestions(item.id, item.type);
}

const loadQuestions = async (id, type) => {
  if (!id) return;
  await testService.getQuestion(id, type).then((res) => {
    testQuestionList.value = res.data.data.questions;
    questionsLoading.value = false;
  }).catch((err) => {
    handleErrorResponse(err, $snackbar);
  });
}

const handleQuestionSelect = (event) => {
  const questionId = event.target.value;
  if (questionId === '-1') return;
  console.log("questionId", questionId, "testQuestionList", testQuestionList.value, );

  selectedQuestion.value = testQuestionList.value.find((question) => question?.questionId === questionId);
  checkQuestionMCQ();
}

const handleConditionSelect = (event) => {
  selectedCondition.value = event.target.value;
}

const handleOptionSelect = (event) => {
  const option = event.target.value;
  if (option === '-1') return;

  selectedOption.value = option;
}

onMounted(async () => {
  if (props.data) {
    console.log("props.data", props.data);
    selectedTest.value = props.testList.find((test) => test.id === props.data.testId);
    if (!selectedTest.value) {
      return;
    }
    await loadQuestions(props.data.testId, selectedTest.value.type);

    selectedQuestion.value = testQuestionList.value.find((question) => question.questionId === props.data?.questionId);

    selectedCondition.value = props.data.condition ? props.data.condition : '=';
    selectedOption.value = props.data.option;
    checkQuestionMCQ();
  }
});

watch(
    [selectedTest, selectedQuestion, selectedCondition, selectedOption],
    ([newTest, newQuestion, newCondition, newOption], [oldTest, oldQuestion, oldCondition, oldOption]) => {
      if (newTest && newQuestion && newOption) {
        const selectedData = {
          testId: newTest.id,
          questionId: newQuestion?.questionId,
          option: newOption,
          condition: newCondition ? newCondition : '=',
        }
        props.onSelect(selectedData, props.id);
      }
    },
    { deep: true }
)


</script>

<template>
  <div class="node-wrapper">
    <Handle id="input" type="target" :position="Position.Top"/>
    <Handle id="output" type="source" :position="Position.Bottom"/>

    <div class="node-title-row">
      <img src="@/assets/images/testWhiteOutline.svg" />
      <span>Answer</span>
    </div>


    <div class="select-row">
      <select @change="handleTestSelect" :value="selectedTest?.id ? selectedTest?.id : -1" :disabled="editDisabled">
        <option value="-1">Select test/program</option>
        <option v-for="test in testList" :value="test.id">{{test.name}}</option>
      </select>

      <select @change="handleQuestionSelect" :value="selectedQuestion?.questionId ? selectedQuestion?.questionId : -1" :disabled="editDisabled">
        <option value="-1">Select question</option>
        <option v-for="question in testQuestionList" :value="question.questionId">{{ question.name }}</option>
      </select>

      <select @change="handleConditionSelect" :value="selectedCondition ? selectedCondition : '='" :disabled="editDisabled">
        <option value='='>=</option>
        <option value = '!='>!=</option>
        <option v-if="!mcqQuestion" value = '<'>&lt;</option>
        <option v-if="!mcqQuestion" value = '>'>></option>
        <option v-if="!mcqQuestion" value= '<='>&lt;=</option>
        <option v-if="!mcqQuestion" value = '>='>>=</option>
      </select>
    </div>

    <select v-if="mcqQuestion" @change="handleOptionSelect" :value="selectedOption ? selectedOption : '='" :disabled="editDisabled">
      <option value="-1">Select option</option>
      <option v-for="option in selectedQuestion.options" :value="option.id">{{option.name}}</option>
    </select>

    <input v-if="!mcqQuestion" @change="handleOptionSelect" type="number" placeholder="Enter value" :value="selectedOption" :disabled="editDisabled" />

  </div>
</template>

<style scoped>
.node-wrapper {
  height: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #101828;
  color: white;
  border: none;
  border-radius: 9px;
  box-shadow: none;
  font-size: 10px;
}

.node-wrapper.selected {
  background: #1C3CD5;
  border: none;
}

.select-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

select, input {
  border-radius: 8px;
  border: 1px solid #F5F5F5;
  background: transparent;
  color: #F5F5F5;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 10px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.node-title-row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.node-title-row img {
  width: 14px;
  height: 14px;
}
</style>
