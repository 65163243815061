<template>
  <Layout>
    <div class="filter-view">
      <div class="flex-row">
        <div class="row-item">
          <img src="@/assets/images/company.svg">
          <span class="row-item-txt">
           {{$t('all_reports')}}
          </span>
          <img src="@/assets/images/arrowdown.svg">
        </div>
      </div>
      <div class="justify-end-div">
        <div class="justify-end-search">
          <ExpandableSearch :searchText="searchText" />
        </div>
      </div>
    </div>
    <div class="table-holder">
      <ReportsTable :data="data" :loading="loading" />
    </div>

    <div class="pagination-align-center">
      <Pagination :total-items="10" :per-page="20" :current-page="1" />
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import ActionDropdown from "@/components/ActionDropdown.vue";
import Pagination from "@/components/Pagination.vue";
import ReportsTable from "@/components/tables/ReportsTable.vue";

export default {
  name: 'Files',
  components: {
    ReportsTable,
    Pagination,
    ActionDropdown,
    ExpandableSearch,
    Layout

  },
  data() {
    return {
      searchText: '',
      data: [{
        name: 'Report name #1',
        status: true,
      }],
      loading: false,
    }
  },
  methods: {

  },
  mounted() {

  }
}
</script>

<style scoped>
.filter-view{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  width: 100%;
  min-height: 52px;
}
.justify-end-div{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.justify-end-search{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
}
.flex-row{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
}
.row-item{
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}
.row-item.filter {
  background-color: transparent;
}


.select-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.selected-count {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #667085;
}
</style>
