<script setup>
import { Handle, Position } from '@vue-flow/core'
import {onMounted, ref, watch} from "vue";
import testDimensionService from "@/service/testDimensionService";

const props = defineProps(['id', 'data', 'testList', 'onSelect', 'editDisabled']);
const questionsLoading = ref(false);
const dimensionsList = ref([]);
const selectedTest = ref(null);
const selectedDimension = ref(null);
const selectedCondition = ref(null);
const selectedScore = ref(null);

const handleTestSelect = async (event) => {
  const testId = event.target.value;
  if (testId === '-1') return;

  const item = props.testList.find((test) => test.id === testId);
  selectedTest.value = item;
  questionsLoading.value = true;

  loadDimensions(item.id);
}

const loadDimensions = async (testId) => {
  if (!testId) return;
  await testDimensionService.getDimensions(testId).then((res) => {
    dimensionsList.value = res.data.data.data;
    questionsLoading.value = false;
  }).catch((err) => {
    console.log(err);
  });
}

const handleDimensionSelect = (event) => {
  const dimensionId = event.target.value;
  if (dimensionId === '-1') return;

  selectedDimension.value = dimensionsList.value.find((dimension) => dimension.dimensionalId === dimensionId);
}

const handleConditionSelect = (event) => {
  selectedCondition.value = event.target.value;
}

const handleOptionSelect = (event) => {
  const option = event.target.value;
  if (option === '-1') return;

  selectedScore.value = option;
}


onMounted(async () => {

  if (props.data) {
    selectedTest.value = props.testList.find((test) => test.id === props.data.testId);
    await loadDimensions(props.data.testId);
    selectedDimension.value = props.data.dimensionalId;
    selectedCondition.value = props.data.condition;
    selectedScore.value = props.data.score;
  }

});

watch(
    [selectedTest, selectedDimension, selectedCondition, selectedScore],
    ([newTest, newDimension, newCondition, newScore], [oldTest, oldDimension, oldCondition, oldScore]) => {
      if (newTest && newCondition && newScore) {
        const selectedData = {
          testId: newTest.id,
          dimensionalId: newDimension?.dimensionalId,
          score: newScore,
          condition: newCondition,
        }
        props.onSelect(selectedData, props.id);
      }
    },
    { deep: true }
)



</script>

<template>
  <div class="node-wrapper">
    <Handle id="input" type="target" :position="Position.Top"/>


    <div class="node-title-row">
      <img src="@/assets/images/medalWhiteOutline.svg" />
      <span>Test Score</span>
    </div>


    <div class="select-row">
      <select @change="handleTestSelect" :value="selectedTest?.id ? selectedTest?.id : -1" :disabled="editDisabled">
        <option value="-1">Select a test</option>
        <option v-for="test in testList.filter(item => item.type === 'test')" :value="test.id">{{test.name}}</option>
      </select>

      <select @change="handleDimensionSelect" :value="selectedDimension?.dimensionalId ? selectedDimension.dimensionalId : -1" :disabled="editDisabled">
        <option value="-1">Select dimension</option>
        <option v-for="dimension in dimensionsList" :value="dimension.dimensionalId">{{ dimension.name }}</option>
      </select>

      <select @change="handleConditionSelect" :value="selectedCondition ? selectedCondition : '='" :disabled="editDisabled">
        <option value='='>=</option>
        <option value = '<'>&lt;</option>
        <option value = '>'>></option>
        <option value= '<='>&lt;=</option>
        <option value = '>='>>=</option>
      </select>
    </div>

    <input @change="handleOptionSelect" type="number" placeholder="Enter score" :value="selectedScore" :disabled="editDisabled"/>

    <Handle id="output" type="source" :position="Position.Bottom" class="handle-true"/>
    <Handle id="false" type="source" :position="Position.Bottom" class="handle-false"/>
  </div>
</template>

<style scoped>
.node-wrapper {
  height: 100%;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #101828;
  color: white;
  border: none;
  border-radius: 9px;
  box-shadow: none;
  font-size: 10px;
}

.node-wrapper.selected {
  background: #1C3CD5;
  border: none;
}

.select-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

select, input {
  border-radius: 8px;
  border: 1px solid #F5F5F5;
  background: transparent;
  color: #F5F5F5;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 10px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.node-title-row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.node-title-row img {
  width: 14px;
  height: 14px;
}

.handle-true {
  position: absolute;
  bottom: -6px;
  left: calc(50% - 10px) ;
  transform: translateX(-50%);
}

.handle-false {
  position: absolute;
  bottom: -6px;
  right: calc(50% - 10px) ;
  transform: translateX(50%);
  background-color: #F44336 !important;
}
</style>
