<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">
        <div class="modal-top">
          <div class="modal-header">
            <span class="modal-header-txt">Edit roles</span>
            <span class="modal-header-description">
              Create or update roles.
            </span>
          </div>

          <table>
            <thead>
            <tr>
              <th>Name</th>
              <th>Key</th>
              <th>Action</th>
            </tr>
            </thead>

            <tbody>
            <tr v-for="role in roles">
              <td><input class="form-input-sm" v-model="role.name"/></td>
              <td><input class="form-input-sm" v-model="role.key"/></td>
              <td class="td-action">
                <span class="save-btn" @click="updateRole(role)">
                  Save
                </span>
                <img src="@/assets/images/trash.svg" @click="deleteRole(role)" />
              </td>
            </tr>
            <tr v-for="role in newRoles">
              <td><input class="form-input-sm" v-model="role.name"/></td>
              <td><input class="form-input-sm" v-model="role.key"/></td>
              <td class="td-action">
                <span class="save-btn" @click="addRole(role)">
                  Save
                </span>
                <img src="@/assets/images/trash.svg" @click="deleteNewRole(role)" />
              </td>
            </tr>
            </tbody>
          </table>
          <button class="add-new-button" @click="addNewRole">
            <img src="@/assets/images/add.svg">
            <span>Add new</span>
          </button>


          <div @click="this.onHide" class="close-btn">
            <img class="cross" src="../../assets/images/cross.svg" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Switch from "@/components/Switch.vue";
import {VueSpinnerIos} from 'vue3-spinners';
import FileDrop from "@/components/FileDrop.vue";
import journeyService from "@/service/journeyService";
import {formatBytes, handleErrorResponse} from "@/utils/utils";
import roleService from "@/service/roleService";

export default {
  components: {
    FileDrop,
    Switch,
    Multiselect,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    roles: {
      type: Array,
      default: []
    },
    refreshHook: {
      type: Function,
      default: () => {}
    },
    onHide: {
      type: Function,
      default: () => {}
    },
  },
  data () {
    return {
      sendLoading: false,
      newRoles: [],
    }
  },
  methods: {
    updateRole(role) {
      roleService.updateRole({
        roleId: role.id,
        name: role.name,
        key: role.key
      })
        .then(() => {
          this.$snackbar.add({
            text: 'Role updated',
            type: 'success'
          });
          this.refreshHook();
        })
        .catch(err => {
          handleErrorResponse(err, this.$snackbar);
        });
    },
    addRole(role) {
      roleService.addRole({
        name: role.name,
        key: role.key
      })
          .then(() => {
            this.$snackbar.add({
              text: 'Role added',
              type: 'success'
            });
            this.refreshHook();
          })
          .catch(err => {
            handleErrorResponse(err, this.$snackbar);
          });
    },
    addNewRole() {
      this.newRoles.push({
        name: '',
        key: '',
      })
    },
    deleteNewRole(role) {
      this.newRoles = this.newRoles.filter(r => r !== role);
    },
    deleteRole(role) {
      roleService.deleteRole(role.id)
        .then(() => {
          this.$snackbar.add({
            text: 'Role deleted',
            type: 'success'
          });
          this.refreshHook();
        })
        .catch(err => {
          handleErrorResponse(err, this.$snackbar);
        });
    }
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  z-index: 99999;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}

.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}
.modal-header-description {
  font-family: euclid_medium, sans-serif;
  color: #667085;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
h5 {
  font-family: euclid_medium, sans-serif;
  color: #010511;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: 20px;
}


.modal-top {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.save-btn {
  font-size: 12px;
  font-family: euclid_medium, sans-serif;
  color: #060B27;
  cursor: pointer;
}

.add-new-button {
  margin-top: 10px;
  background-color: transparent;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 8px;
}

.add-new-button:hover {
  background-color: #F5F5F5;
}

.add-new-button span {
  color: #667085;
  font-size: 12px;
  font-family: euclid_medium, sans-serif;
}

.td-action {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

</style>
