<script setup>
import { Handle, Position } from '@vue-flow/core'
import {truncate} from "@/utils/utils";

const props = defineProps(['id', 'data']);


const getType = (type) => {
  if (type === 'multiple') {
    return "Multiple";
  } else if (type === 'single') {
    return "Single";
  } else  if (type === 'yesNo') {
    return "Yes / No";
  } else if (type === 'single') {
    return "Single";
  } else if (type === 'number') {
    return "Number";
  } else if (type === 'text') {
    return "Text";
  } else if (type === 'grading') {
    return "Grading";
  }  else if (type === 'rating') {
    return "Rating"
  } else if (type === 'dragAndDrop') {
    return "Drag & Drop";
  } else {
    return "Undefined type";
  }
};
</script>

<template>
  <div class="question-node">
    <Handle id="input" type="target" :position="Position.Top"/>

    <div class="node-info">
      <span class="node-title">{{props.data?.name ? truncate(props.data?.name, 50) : 'No name'}}</span>
      <span class="node-description">{{ getType(props.data.content?.type) }}</span>

    </div>

    <div class="handles">
      <div class="handle-row" v-for="(option, index) in props.data.content?.options">
        <span>{{option.name}}</span>
        <Handle :id="index" type="source" :position="Position.Right" class="handle"/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.question-node {
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #101828;
  color: white;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  font-size: 10px;
}

.question-node.selected {
  background: #1C3CD5;
  border: none;
}

.handles {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.node-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 10px;
}

.node-title {
  font-size: 10px;
  font-weight: 500;
}

.node-description {
  font-size: 8px;
  color: #cacaca;
}

.handle-row {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  padding: 0 10px 10px 10px;
  min-width: 140px;
}

.handle-row span {
  font-size: 8px;
  color: #00D68F;
}

.handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -6px;
}
</style>
