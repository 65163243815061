<template>
  <Layout>
    <h3 class="page-title">{{$t('visual_test')}}</h3>
    <div class="filter-wrapper">
      <GameLabFilters />

      <div class="justify-end-div">
        <ExpandableSearch />

        <button class="outline-action-button" @click="() => gameLabGroupsModal = true">
          {{$t('edit_groups')}}
        </button>

        <router-link to="/game/new">
          <div class="justify-end-add-btn no-text-select">
            <img src="../assets/images/plus.svg" />
            <span class="justify-end-addbtn-txt">{{$t('add_new')}}</span>
          </div>
        </router-link>
      </div>
    </div>

    <div class="cards-grid" >
      <router-link v-if="!isLoading" v-for="item in gameLabs.data" :to="`/game/${item.gameLabsId}`">
      <div class="game-labs-card no-text-select"  :style="`background-color: ${item.color || '#EAECF0'}`">
        <div class="status-tag" :class="item.isPublish ? 'Published' : 'Draft'">
          <div class="status-dot" :class="item.isPublish ? 'Published' : 'Draft'"></div>
          <span>{{item.isPublish ? $t('published') : $t('draft')}}</span>
        </div>

        <div class="game-date">
          <span>{{formatDate(item.startAt)}}</span>
          -
          <span>{{formatDate(item.finishAt)}}</span>
          <div class="dot"></div>

          <div class="gameLabs-time-row" v-if="item.remaining.isPast === 0">
            <img
                src="@/assets/images/clockOutlineRed.svg"
                class="gameLabs-info-icon"
            />
            <span class="gameLabs-info-text red">{{$t('time_is_up')}}</span>
          </div>

        <div class="gameLabs-time-row"  v-if="item.remaining.isPast === 1">
          <img
              src="@/assets/images/clockOutlineGreen.svg"
              class="gameLabs-info-icon"
          />
          <span class="gameLabs-info-text green">
              {{ item.remaining.time }}
            </span>
        </div>

        <div class="gameLabs-time-row"  v-else-if="item.remaining.isPast === 2">
          <img
              src="@/assets/images/clockOutlineYellow.svg"
              class="gameLabs-info-icon"
          />
          <span class="gameLabs-info-text yellow">
              {{ item.remaining.time }}
            </span>
        </div>

        </div>

        <span class="game-title">{{item.name}}</span>
        <span class="game-description">{{item.description}}</span>

        <div class="info-row">
          <span class="info-tag">{{ item.cardCount }} {{$t('cards_gamelab')}}</span>
        </div>

        <img :src="item.avatarUrl" class="card-image" />
      </div>
      </router-link>

      <free-style-shimmer
          :is-loading="isLoading"
          height="240px"
          width="100%"
          border-radius="20px"
          color="#EAECF0"
      />

      <free-style-shimmer
          :is-loading="isLoading"
          height="240px"
          width="100%"
          border-radius="20px"
          color="#EAECF0"
      />

      <free-style-shimmer
          :is-loading="isLoading"
          height="240px"
          width="100%"
          border-radius="20px"
          color="#EAECF0"
      />
    </div>

    <div class="pagination-align-center">
      <Pagination :current-page="currentPage" :total-items="gameLabs.total" :per-page="20" :on-page-change="handlePage"/>
    </div>

    <GameLabGroupsModal :visible="gameLabGroupsModal" :onHide="onHideGameLabGroupsModal" />
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import Pagination from "@/components/Pagination.vue";
import gameLabService from "@/service/gameLabService";
import {handleErrorResponse} from "@/utils/utils";
import GameLabGroupsModal from "@/components/modals/GameLabGroupsModal.vue";
import GameLabFilters from "@/components/tables/GameLab/GameLabFilters.vue";

export default {
  components: {
    GameLabFilters,
    GameLabGroupsModal,
    Pagination,
    ExpandableSearch,
    Layout
  },
  data() {
    return {
      isLoading: false,
      gameLabs: {},
      gameLabGroupsModal: false,
      currentPage: 1,
    }
  },
  watch: {
    $route() {
      this.handlePage(1);
    }
  },
  methods: {
    formatDate(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        return date.toLocaleDateString("en", options);
      } catch (e) {
        return '-';
      }
    },
    handlePage(page) {
      if (this.isLoading && this.currentPage !== 1) return;

      this.isLoading = true;
      this.currentPage = page;

      const routeQuery = this.$route.query;
      const tempFilters = [];

      if (routeQuery.searchText) {
        tempFilters.push({
          key: 'searchText',
          value: routeQuery.searchText
        });
      }

      if (routeQuery.addedDate) {
        tempFilters.push({
          key: 'addedDate',
          value: routeQuery.addedDate
        });
      }

      if (routeQuery.addedDateEnd) {
        tempFilters.push({
          key: 'addedDateEnd',
          value: routeQuery.addedDateEnd
        });
      }

      gameLabService.getGameLabs(page, tempFilters).then((response) => {
        this.gameLabs = response.data.data;
      }).catch((error) => {
        handleErrorResponse(error, this.$snackbar);
      }).finally(() => {
        this.isLoading = false;
      });
    },
    onHideGameLabGroupsModal() {
      this.gameLabGroupsModal = false;
    }
  }
}
</script>

<style scoped>
.page-title {
  font-family: euclid_medium, sans-serif;
  font-size: 20px;
  color: #040C2D;
  margin-bottom: 20px;
}
.filter-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.filter-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.filter-dropdown {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}
.dropdown-text {
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
}


.status-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #040C2D;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.status-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.status-tag.Draft {
  background-color: #FAD9C6;
}

.status-tag.Published {
  background-color: #DDFBDE;
}

.status-dot.Draft {
  background-color: #F09460;
}

.status-dot.Published {
  background-color: #3DA33E;
}


.game-labs-card {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #EAECF0;
}

.game-labs-card .card-image {
  position: absolute;
  bottom: 20px;
  right: 20px;
  height: 140px;
  object-fit: cover;
  border-radius: 20px;
  z-index: 0;
  opacity: 0.4;
}

.game-date {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #667085;
  margin-top: 30px;
  z-index: 1;
  display:flex;
  flex-direction:row;
  gap:5px;
  align-items:center;
  justify-content:center;
}

.game-title {
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  color: #040C2D;
  margin-bottom: 5px;
  z-index: 1;
}

.game-description {
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  color: #667085;
  margin-bottom: 30px;
  z-index: 1;
}

.info-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  z-index: 1;
}

.info-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 10px;
  color: #040C2D;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 12px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.cards-grid {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

@media (max-width: 1200px) {
  .cards-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .cards-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.gameLabs-info-text.red {
  color: #ff2121;
}

.gameLabs-info-text.yellow {
  color: #f1c40f;
}

.gameLabs-time-row {
  display: flex;
  height:50px;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
</style>
