<template>
  <Layout>
    <div>
      <TagTable :loading="isLoading" :tags="tags" :add-new-tag="() => tagModalVisible = true" :edit-tag="handleEdit" :delete-tag="handleDeleteTag" />
    </div>

    <TagModal :is-visible="tagModalVisible" :on-close="() => tagModalVisible = false" :refresh-callback="refreshData" />
    <TagModal :is-visible="tagEditModalVisible" :on-close="() => tagEditModalVisible = false" :edit-mode="true" :edit-data="editData" :refresh-callback="refreshData" />
    <ConfirmDeleteModal :is-visible="confirmDeleteModalVisible" :on-close="onTagDeleteCancel" message="Tag will be deleted permanently" :on-action="handleDeleteRequest" />

  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import TagTable from "@/components/tables/TagTable.vue";
import tagService from "@/service/tagService";
import TagModal from "@/components/modals/TagModal.vue";
import ConfirmDeleteModal from "@/components/modals/ConfirmDeleteModal.vue";

export default {
  components: {
    ConfirmDeleteModal,
    TagModal,
    TagTable,
    Layout
  },
  data() {
    return {
      isLoading: true,
      tags: [],
      currentPage: 1,
      tagModalVisible: false,
      tagEditModalVisible: false,
      confirmDeleteModalVisible: false,
      editData: {},
      deleteId: null,
    }
  },
  mounted() {
    this.getTags(1);
  },
  methods: {
    getTags(page) {
      this.isLoading = true;
      tagService.getTags(page).then(response => {
        this.tags = response.data.data.data;
        this.isLoading = false;
      })
    },
    refreshData() {
      this.getTags(this.currentPage);
    },
    handleEdit(data) {
      this.editData = data;
      this.tagEditModalVisible = true;
    },
    handleDeleteTag(tagId) {
      event.stopPropagation();
      console.log(tagId);
      this.deleteId = tagId;
      this.confirmDeleteModalVisible = true;
    },
    onTagDeleteCancel() {
      this.confirmDeleteModalVisible = false;
      this.deleteId = null;
    },
    handleDeleteRequest() {
      if (!this.deleteId) {
        return;
      }

      this.confirmDeleteModalVisible = false;
      this.isLoading = true;

      tagService.deleteTag(this.deleteId).then(response => {
        this.isLoading = false;
        this.deleteId = null;
        this.refreshData();
      })
    }
  },

}

</script>

<style scoped>

</style>
