<template>
  <div class="bar-chart">
    <div
        v-if="data !== undefined && data.length > 0"
        class="bar"
        v-for="(item, index) in normalizedData"
        :key="index"
        :style="barStyle(item.value, index)"
        @mouseover="barMouseOver(index)"
        @mouseout="barMouseOut(index)"
    >
      <div class="chart-tooltip" :class="{ 'active': activeTooltip === index }">
        {{ data[index].value }} {{ suffix }}
        <img src="../../assets/images/arrowTooltip.svg" />
      </div>
    </div>
  </div>
  <div class="bar-dates">
    <span>{{ startDate }}</span>
    <span>{{ endDate }}</span>
  </div>
</template>

<script>
export default {
  props: {
    suffix: {
      type: String,
      required: false,
      default: '',
    },
    data: {
      type: Array,
      default: [],
    },
    barColor: {
      type: String,
      required: false,
      default: '#9fd3f6',
    },
    barColorActive: {
      type: String,
      required: false,
      default: '#3b99e1',
    }
  },
  data() {
    return {
      min: 0,
      max: 0,
      activeTooltip: null,
      startDate: '',
      endDate: '',
      activeBar: null,
    };
  },
  created() {
    if (this.data.length === 0) return;
    this.getStartDate();
  },
  computed: {
    normalizedData() {
      if (this.data.length === 0) return [];
      if (this.data.length === 0) return [];
      const values = this.data.map((item) => item.value);
      const max = Math.max(...values);

      return this.data.map((item) => ({
        name: item.name,
        value: this.normalizeValue(item.value, this.min, max),
      }));
    },
  },
  methods: {
    normalizeValue(value, min, max) {
      const range = max - min;
      return range === 0 ? 50 : ((value - min) / range) * 100;
    },
    barStyle(height, index) {
      return {
        height: `${height}%`,
        backgroundColor: this.activeBar === index ? this.barColorActive : this.barColor,
      };
    },
    barMouseOver(index) {
      this.activeTooltip = index;
      this.activeBar = index;
    },
    barMouseOut(index) {
      this.activeTooltip = null;
      this.activeBar = null;
    },
    getStartDate() {
      if (this.data.length === 0) {
        this.startDate = '';
        this.endDate = '';
      }

      if (this.data.length === 1) {
        this.startDate = this.data[0].name;
        this.endDate = '';
      }

      this.startDate = this.data[0].name;
      this.endDate = this.data[this.data.length - 1].name;
    }
  },
};
</script>

<style scoped>
.bar-chart {
  display: flex;
  height: 92px;
  justify-content: space-evenly;
  align-items: flex-end;
  border-bottom: 1px solid #EAECF0;
}
.bar {
  position: relative;
  width: 8px;
  transition: height 0.3s;
  border-radius: 2px;
}
.chart-tooltip {
  position: absolute;
  padding: 5px 10px 5px 10px;
  top: -33px;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: var(--primary-dark);
  color: white;
  font-size: 10px;
  border-radius: 8px;
  white-space: nowrap;
}
.chart-tooltip.active {
  display: flex;
}
.chart-tooltip img {
  position: absolute;
  bottom: -5px;
}
.bar-dates {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  font-family: euclid_medium, sans-serif;
  color: #98A2B3;
  margin-top: 5px;
}
</style>
