import axios from '../axios';

const fileService = {
    getFiles(page) {
        return axios.get(`/v1/admin/files/store?page=${page}&limit=20`);
    },
    setFileStatus(data) {
        return axios.put(`/v1/admin/files/updateStatus`, data);
    }
}

export default fileService;
