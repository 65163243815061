<script setup>
import Sidebar from "@/components/Sidebar.vue";
import Header from "@/components/Header.vue";
import {ref} from "vue";

const props = defineProps({
  padding: {
    type: Number,
    default: 20,
  },
});
const sideBarRef = ref();

const onMenuClick = () => {
  sideBarRef.value.toggleCollapse();
};

</script>

<template>
  <div id="app" class="app-container">
    <Sidebar ref="sideBarRef" />
    <div class="main-content">
      <Header :on-menu-click="onMenuClick"/>
      <div class="content" :style="{ padding: `${padding}px` }">
        <slot/>
      </div>
    </div>
  </div>
</template>

<style scoped>
.app-container {
  display: flex;
  margin: 0;
  padding: 0;
}
.main-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.content {
  height: calc(100vh - 72px);
  flex-shrink: 0;
  overflow-y: auto;
}

</style>
