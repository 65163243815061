import axios from '../axios';
import {convertBooleanValuesToNumbers} from "@/utils/utils";

const journeyService = {
    getJourneys(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/journey/store?limit=20&page=${page}${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/journey/store?limit=20&page=${page}`);
        }
    },
    getJourneyDetails(id) {
        return axios.get(`/v1/admin/journey/getFromDetail/${id}`);
    },
    addJourneyNote(data) {
        data = convertBooleanValuesToNumbers(data);

        return axios.post(`/v1/admin/journey/note/add`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    endJourney(journeyIds) {
        return axios.post(`/v1/admin/journey/endJourney`, {
            journeyIds: journeyIds
        });
    },
    updateInterviewStatus(notificationId, status) {
        return axios.post(`/v1/admin/journey/interview/changeStatus`, {
            notificationId: notificationId,
            status: status
        });
    }
}

export default journeyService;
