<template>
  <Layout padding="0">
    <UserHeader
        :is-loading="isLoading"
        :title="details.name + ' ' + details.surname"
        :status="details.status"
        :session-count="details.onlineSessionCount"
        :therapy-count="details.therapyCount"
        :created-at="details.createdAt"
        :photo="details.avatarUrl"
        :on-edit="showEditModal"
        :on-delete="showDeleteModal"
    />
    <div class="main-content">
      <div class="tab-panel-row">
        <div class="tab-panel">
          <button class="tab-button" :class="{'active': tabIndex === 0}" @click="() => changeTab(0)">
            <span>{{ $t('general_tab') }}</span>
          </button>
          <button class="tab-button" :class="{'active': tabIndex === 1}" @click="() => changeTab(1)">
            <span>{{ $t('therapies_tab') }}</span>
          </button>
          <button class="tab-button" :class="{'active': tabIndex === 2}" @click="() => changeTab(2)">
            <span>{{ $t('tests_tab') }}</span>
          </button>
        </div>

        <div class="tab-actions">
          <div class="action-row" v-if="tabIndex === 1">
            <button class="dark-button-sm" @click="openAssignModal">{{ $t('assign_new') }}</button>
          </div>

          <div class="action-row" v-if="tabIndex === 2">
            <button class="dark-button-sm" @click="openAssignTestModal">{{ $t('assign_new') }}</button>
          </div>
        </div>
      </div>

      <div v-if="tabIndex === 0" class="tab-container">
        <h3>{{ $t('user_details') }}</h3>
        <div class="company-details">
          <div class="company-details-col">

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/avatar.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{ $t('full_name') }}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{ details.name }} {{details.surname}}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/message.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{ $t('email_address') }}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{ details.email }}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/buildings.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title" v-if="!isLoading">
                  {{ $t('company_name') }}
                </span>
                <span class="detail-text">
                  {{ details.companyName }}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

          </div>

          <div class="company-details-col">

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/phone.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{ $t('phone_number_person') }}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{ details.phone }}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

            <div class="company-detail-row">
              <div class="company-detail-icon">
                <img src="@/assets/images/cake.svg" />
              </div>
              <div class="company-detail-body">
                <span class="detail-title">
                  {{ $t('birthday') }}
                </span>
                <span class="detail-text" v-if="!isLoading">
                  {{  formatDate(details.birthday) }}
                </span>

                <free-style-shimmer
                    :is-loading="isLoading"
                    height="7px"
                    width="120px"
                    border-radius="50px"
                    color="#EAECF0"
                />
              </div>
            </div>

          </div>
        </div>
      </div>

      <div v-if="tabIndex === 1" class="tab-container">
        <h3>{{ $t('assigned_therapies') }}</h3>

        <div class="therapies-row" v-if="isLoading">
          <TherapyCard />
          <TherapyCard />
          <TherapyCard />
        </div>

        <div class="therapies-row" v-else>
          <TherapyCard
              v-for="therapy in details.therapies"
              :image="therapy.avatar"
              :is-loading="false"
              :title="therapy.name"
              :description="therapy.shortDescription"
              :tags="therapy.tags.map((x) => x.name)"
              :remaining-days="therapy.remainingDay"
              :assigned-at="therapy.assignedAt"
              :to="`/therapy/${therapy.therapyId}`"
              :remaining="therapy.remaining"
              :start-at="therapy.startAt"
              :finish-at="therapy.finishAt"
              :is-publish="therapy.isPublish"
          />
        </div>

        <div v-if="!isLoading && details.therapies.length === 0" class="no-tests-wrapper">
          <img src="../assets/images/noTests.png" class="no-tests-image">
          <span class="no-tests-text">{{$t('not_assigned_therapies_yet')}}</span>
          <span class="no-tests-description">{{$t('first_therapy_assign')}}</span>
        </div>
      </div>

      <div v-if="tabIndex === 2" class="tab-container">
        <h3>{{ $t('assigned_tests') }}</h3>

        <div v-if="!isLoading && details.tests.length === 0" class="no-tests-wrapper">
          <img src="../assets/images/noTests.png" class="no-tests-image">
          <span class="no-tests-text">{{$t('not_assigned_tests_yet')}}</span>
          <span class="no-tests-description">{{ $t('first_test_assign') }}</span>
        </div>

        <div class="tests-grid">
          <router-link v-for="item in details.tests"  v-if="!isLoading" :to="`/test/${item.testId}`">
            <div  class="test-card no-text-select">
              <div class="status-tag" :class="item.isPublish ? 'Published' : 'Draft'">
                <div class="status-dot" :class="item.isPublish ? 'Published' : 'Draft'"></div>
                <span>{{item.isPublish ? $t('published') : $t('draft')}}</span>
              </div>

              <span class="test-date">{{formatDate(item.created_at)}}</span>
              <span class="test-title">{{item.name}}</span>

              <div class="test-info">
                <img src="@/assets/images/datepicker.svg" class="test-info-icon"/>
                <span class="test-info-text">
                  {{ formatDate(item.startAt) }} - {{ formatDate(item.finishAt) }}
                </span>

                <div class="dot"></div>

                <div class="test-time-row" v-if="item.remaining.isPast === 0">
                  <img
                      src="@/assets/images/clockOutlineRed.svg"
                      class="test-info-icon"
                  />
                  <span class="test-info-text red">
                {{ item.remaining.time }} {{ $t('time_is_up') }}
            </span>
                </div>

                <div class="test-time-row"  v-if="item.remaining.isPast === 1">
                  <img
                      src="@/assets/images/clockOutlineGreen.svg"
                      class="test-info-icon"
                  />
                  <span class="test-info-text green">
              {{ item.remaining.time }}
            </span>
                </div>

                <div class="test-time-row"  v-else-if="item.remaining.isPast === 2">
                  <img
                      src="@/assets/images/clockOutlineYellow.svg"
                      class="test-info-icon"
                  />
                  <span class="test-info-text yellow">
              {{ item.remaining.time }}
            </span>
                </div>
              </div>

              <div class="info-row">
                <span class="info-tag">{{$t('companies_count', {companiesCount: item.compainesCount})}}</span>
                <span class="info-tag">{{$t('question_count', {questionCount: item.questionsCount})}}</span>
              </div>
            </div>
          </router-link>

          <free-style-shimmer
              :is-loading="isLoading"
              height="200px"
              width="100%"
              border-radius="20px"
              color="#EAECF0"
          />

          <free-style-shimmer
              :is-loading="isLoading"
              height="200px"
              width="100%"
              border-radius="20px"
              color="#EAECF0"
          />

          <free-style-shimmer
              :is-loading="isLoading"
              height="200px"
              width="100%"
              border-radius="20px"
              color="#EAECF0"
          />

        </div>

      </div>
    </div>

    <UserModal
        :edit-mode="true"
        :edit-data="details"
        :visible="editModalVisible"
        :on-hide="hideEditModal"
        :refresh-callback="getDetails"
    />

    <ConfirmDeleteModal
        :title="this.$t('alert_messages')"
        :message="this.$t('alert_messages_two')"
        :is-visible="deleteModalVisible"
        :on-action="handleDelete"
        :on-close="handleDeleteModalClose"
    />

    <AssignTherapyModal
        :visible="assignmentModal"
        :pre-data="details"
        type="user"
        :on-hide="hideAssignModal"
        :refresh-callback="getDetails"
    />

    <AssignTestModal
        :visible="assignTestModal"
        :pre-data="details"
        type="user"
        :on-hide="hideAssignTestModal"
        :refresh-callback="getDetails"
    />
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import CompanyHeader from "@/components/CompanyHeader.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import UserTable from "@/components/tables/User/UserTable.vue";
import TherapyCard from "@/components/TherapyCard.vue";
import TransactionTable from "@/components/tables/TransactionTable.vue";
import AddUserToCompanyModal from "@/components/modals/InviteUserModal.vue";
import UserModal from "@/components/modals/UserModal.vue";
import userService from "@/service/userService";
import UserHeader from "@/components/UserHeader.vue";
import ConfirmDeleteModal from "@/components/modals/ConfirmDeleteModal.vue";
import AssignTherapyModal from "@/components/modals/AssignTherapyModal.vue";
import AssignTestModal from "@/components/modals/AssignTestModal.vue";

export default {
  components: {
    AssignTestModal,
    AssignTherapyModal,
    ConfirmDeleteModal,
    UserHeader,
    UserModal,
    AddUserToCompanyModal,
    TransactionTable,
    TherapyCard,
    UserTable,
    ExpandableSearch,
    CompanyHeader,
    Layout
  },
  data() {
    return {
      searchText: '',
      tabIndex: 0,
      editModalVisible: false,
      deleteModalVisible: false,
      addUserModal: false,
      assignmentModal: false,
      isLoading: true,
      details: {},
      assignTestModal: false,
    }
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    handleSearch(e) {
      this.searchText = e.target.value;
    },
    changeTab(index) {
      this.tabIndex = index;
    },
    showEditModal() {
      this.editModalVisible = true;
    },
    hideEditModal() {
      this.editModalVisible = false;
    },
    showDeleteModal() {
      this.deleteModalVisible = true;
      event.stopPropagation();
    },
    hideAssignModal() {
      this.assignmentModal = false;
    },
    handleDeleteModalClose() {
      this.deleteModalVisible = false;
    },
    openAssignModal() {
      this.assignmentModal = true;
    },
    openAssignTestModal() {
      this.assignTestModal = true;
    },
    hideAssignTestModal() {
      this.assignTestModal = false;
    },
    hideShowUserModal() {
      this.addUserModal = false;
    },
    getDetails() {
      const routeParams = this.$route.params;
      this.isLoading = true;

      userService.getUserDetails(routeParams.id).then((response) => {
        this.details = response.data.data;
        this.isLoading = false;
      });
    },
    formatDate(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        return date.toLocaleDateString("en", options);
      } catch (e) {
        return '-';
      }
    },
    handleDelete() {
      this.deleteModalVisible = false;


      userService.deleteUser(this.details.userId).then(() => {
        this.$router.push('/users');
      }).catch(() => {
        this.$snackbar.add({
          text: 'An error occurred while deleting the user',
          type: 'error',
        });
      })
    },
  }
}
</script>

<style scoped>
.tab-panel {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}
.main-content {
  padding: 25px;
}
.tab-panel-row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #F2F4F7;
  border-bottom: 1px solid #F2F4F7;
  padding: 10px 0;
  margin-bottom: 20px;
}
.tab-button {
  padding: 6px 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.tab-button span {
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;
  color: #344054;
}
.tab-button.active, .tab-button:hover {
  background-color: #F2F4F7;
}
h3 {
  font-family: euclid_medium, sans-serif;
  font-size: 18px;
  color: #040C2D;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.company-details-col {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 300px;
}
.company-detail-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.company-detail-icon {
  background-color: #F5F5F5;
  border-radius: 16px;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-detail-icon img {
  cursor: default;
  width: 20px;
  height: 20px;
}
.detail-title {
  font-family: euclid_medium, sans-serif;
  display: block;
  color: #667085;
  font-size: 12px;
}
.detail-text {
  font-family: euclid_medium, sans-serif;
  display: block;
  color: #101828;
  font-size: 14px;
}
.company-detail-body {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.company-details {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.therapies-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.tests-grid {
  width: 100%;
  display: inline-grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.test-card {
  width: 100%;
  border: 1px solid #EAECF0;
  background-color: #FCFCFD;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.status-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #040C2D;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  align-items: center;

}

.status-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.status-tag.Draft {
  background-color: #FAD9C6;
}

.status-tag.Published {
  background-color: #DDFBDE;
}

.status-dot.Draft {
  background-color: #F09460;
}

.status-dot.Published {
  background-color: #3DA33E;
}

.test-date {
  font-family: euclid_medium, sans-serif;
  font-size: 10px;
  color: #667085;
  margin-top: 30px;
}

.test-title {
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  color: #040C2D;
  margin-bottom: 30px;
}

.info-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.info-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 10px;
  color: #040C2D;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 12px;
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: #F2F4F7;
}


.test-info-text {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #667085;
}

.test-info-text.red {
  color: #ff2121;
}

.test-info-text.yellow {
  color: #f1c40f;
}

.dot {
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #d0d5dd;
}

.test-info {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  margin-bottom: 30px;
}

.test-info-icon {
  width: 24px;
  height: 24px;
}

.test-time-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}
</style>
