import axios from '../axios';

const tagService = {
    getTags(page) {
        return axios.get(`/v1/admin/tag/store?limit=20&page=${page}`);
    },
    addTag(data) {
        return axios.post('/v1/admin/tag/add', data);
    },
    updateTag(data) {
        return axios.put('/v1/admin/tag/update', data);
    },
    deleteTag(id) {
        return axios.delete(`/v1/admin/tag/delete/${id}`);
    }
}

export default tagService;
