<template>
  <div class="star-wrapper">
    <div class="star-row">
      <div class="star-box" v-for="i in boxCount" :key="i" :class="{'selected': i === selected}">
        <img src="@/assets/images/starOutline.svg" />
        {{ i }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Number,
      default: -1
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 10
    },
  },
  data () {
    return {

    }
  },
  methods: {

  },
  computed: {
    boxCount() {
      return this.max - this.min ;
    }
  }
};

</script>

<style scoped>
.star-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.star-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-evenly;
  width: 100%;
}
.star-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #667085;
}

.star-box img {
  width: 24px;
  height: 24px;
}
</style>
