export default {
    mounted(el, binding) {
        if (binding.value) {
            el.focus();
        }

        el.__focusEventHandler__ = () => {
            if (binding.value) {
                el.focus();
            } else {
                el.blur();
            }
        };

        el.addEventListener('focus', el.__focusEventHandler__);
    },
    updated(el, binding) {
        el.__focusEventHandler__();
    },
    unmounted(el) {
        el.removeEventListener('focus', el.__focusEventHandler__);
        delete el.__focusEventHandler__;
    },
};
