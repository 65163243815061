<template>
  <div v-if="visible" class="filter-modal">
    <div class="filter-div">
      <div class="text-input">
        <input class="text-input-primary textinp" :placeholder="'Search'" v-model="searchText" type="text" />
      </div>
      <div
          class="filter-item"
          v-for="item in filteredItems"
          :key="item.id"
          @click="toggleCheck(item.id)"
      >
        <span class="filter-title">{{ item.name }}</span>
        <img v-if="!item.selected" src="../../assets/images/checkbox.svg">
        <img v-else src="../../assets/images/selectedcheckbox.svg">
      </div>
      <div class="btn-style" @click="() => onFilter(itemsTemp)">
        <span class="filter-txt">{{$t('filter_therapy')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/TextInput.vue";

export default {
  components: {
    TextInput
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: []
    },
    onFilter: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      searchText: "",
      itemsTemp: [],
    };
  },
  watch: {
    items: {
      immediate: true,
      handler(newItems, oldItems) {
        this.itemsTemp = JSON.parse(JSON.stringify(newItems));
      }
    },
  },
  methods: {
    toggleCheck(filterId) {
      this.itemsTemp.map(item => {
        if (item.id === filterId) {
          item.selected = !item.selected;
        }
        return item;
      });

      event.stopPropagation();
    },
  },
  computed:{
    filteredItems() {
      return this.searchText
          ? this.itemsTemp.filter(item =>
              item.name.toLowerCase().includes(this.searchText.toLowerCase())
          )
          : this.itemsTemp;
    },
  },
};
</script>

<style scoped>
.filter-modal {
  position: absolute;
  top: 40px;
  display: flex;
  left: 0;
  justify-content: flex-start;
  align-items: center;
  z-index: 999;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.08);
  min-width: 200px;
}
.filter-div{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  overflow: visible;
  width: 100%;
}
.filter-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2px;
  padding: 0 12px 0 12px;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
}
.filter-title{
  color: #475467;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.btn-style{
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: #040C2D;
  margin: 12px;
  cursor:pointer;
}
.filter-txt{
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.text-input{
  display: flex;
  padding: 12px;
  align-items: center;
  align-self: stretch;
}
.textinp{
  height: 32px;
  border-radius: 24px;
  font-size: 12px;
}
</style>
