<script setup>
import { Handle, Position } from '@vue-flow/core'
import { ref } from 'vue'
import {truncate} from "@/utils/utils";

const props = defineProps(['id', 'data']);

const getType = (type) => {
  if (type === 'welcomeText') {
    return "Welcome Text";
  } else if (type === 'audio') {
    return "Audio";
  } else  if (type === 'video') {
    return "Video";
  } else if (type === 'summary') {
    return "Summary";
  } else if (type === 'selfChat') {
    return "Self Chat";
  } else if (type === 'success') {
    return "Success";
  } else {
    return "Undefined type";
  }
};
</script>

<template>
  <div class="question-node">
    <Handle id="input" type="target" :position="Position.Top"/>

    <div class="node-info">
      <span class="node-title">{{props.data?.name ? truncate(props.data?.name, 50) : 'No name'}}</span>
      <span class="node-description">{{getType(props.data.content?.type)}}</span>
    </div>

    <Handle id="output" type="source" :position="Position.Bottom"/>
  </div>
</template>

<style scoped>
.question-node {
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #101828;
  color: white;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  font-size: 10px;
}

.question-node.selected {
  background: #1C3CD5;
  border: none;
}

.node-info {
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding: 10px;
}

.node-title {
  font-size: 10px;
  font-weight: 500;
}

.node-description {
  font-size: 8px;
  color: #cacaca;
}

</style>
