<template>
  <div class="page-card">
    <router-link :to="to">
      <div class="page-card-body">
        <div class="status-tag" v-if="!isLoading" :class="isPublish ? 'Published' : 'Draft'">
          <div class="status-dot" :class="isPublish ? 'Published' : 'Draft'"></div>
          <span>{{isPublish ? this.$t('published_status') : this.$t('draft')}}</span>
        </div>

        <free-style-shimmer  v-else
            height="22px"
            width="100px"
            border-radius="8px"
            color="#EAECF0" />


        <h2 v-if="!isLoading">
          {{ title }}
        </h2>

        <h4 v-if="!isLoading">
          {{ description }}
        </h4>

        <free-style-shimmer
            class="paragraph-shimmer top"
            :is-loading="isLoading"
            height="12px"
            width="170px"
            border-radius="50px"
            color="#EAECF0" />

        <free-style-shimmer
            class="paragraph-shimmer"
            :is-loading="isLoading"
            height="8px"
            width="140px"
            border-radius="50px"
            color="#EAECF0" />

      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: true
    },
    isPublish: {
      type: Boolean,
      default: false
    },
    image : {
      type: String,
      default: 'https://i.hizliresim.com/lyhe30g.jpg'
    },
    title: {
      type: String,
      default: 'Blog Name'
    },
    description: {
      type: String,
      default: 'Lorem ipsum dolor sit amet consectetur. Elit suspendisse habitant vitae...'
    },
    to: {
      type: String,
      default: '/therapies'
    }
  },
  methods: {
    formatDate(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        return date.toLocaleDateString("en", options);
      } catch (e) {
        return '-';
      }
    },
  }
};
</script>

<style scoped>
.page-card {
  width: 100%;
  height: 100%;
  background-color: #F9FAFB;
  border: 1px solid #EAECF0;
  border-radius: 16px;
}


.page-card-body {
  padding: 15px;
}

h2 {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
  margin-bottom: 10px;
}

h4 {
  font-family: euclid_regular, sans-serif;
  color: #667085;
  font-size: 12px;
}

.paragraph-shimmer.top {
  margin-top: 15px;
}

.paragraph-shimmer {
  margin-bottom: 5px;
}

.status-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #040c2d;
  border-radius: 8px;
  padding: 3px 15px;
  margin-bottom: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
}

.status-dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.status-tag.Draft {
  background-color: #fad9c6;
}

.status-tag.Published {
  background-color: #ddfbde;
}

.status-dot.Draft {
  background-color: #f09460;
}

.status-dot.Published {
  background-color: #3da33e;
}
</style>
