<script setup>
import { Handle, Position } from '@vue-flow/core'
import {ref} from "vue";

const props = defineProps(['id', 'data', 'testList', 'onSelect', 'editDisabled']);


const handleTestSelect = async (event) => {
  const testId = event.target.value;
  if (testId === '-1') return;

  const item = props.testList.find((test) => test.id === testId);

  props.onSelect(item, props.id);
}

</script>

<template>
  <div class="node-wrapper">
    <Handle id="input" type="target" :position="Position.Top"/>
    <Handle id="output" type="source" :position="Position.Bottom"/>

    <div class="node-title-row">
      <img src="@/assets/images/linkWhiteOutline.svg" />
      <span>Assign</span>
    </div>


    <div class="select-row">
      <select @change="handleTestSelect" :disabled="editDisabled">
        <option value="-1">Select test/program</option>
        <option v-for="test in testList" :value="test.id" :selected="props.data?.id === test.id">{{test.name}}</option>
      </select>
    </div>
  </div>
</template>

<style scoped>
.node-wrapper {
  height: 100%;
  width: 100%;
  min-width: 150px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: #101828;
  color: white;
  border: none;
  border-radius: 9px;
  box-shadow: none;
  font-size: 10px;
}

.node-wrapper.selected {
  background: #1C3CD5;
  border: none;
}

.select-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

select, input {
  border-radius: 8px;
  border: 1px solid #F5F5F5;
  background: transparent;
  color: #F5F5F5;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 5px 10px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.node-title-row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.node-title-row img {
  width: 14px;
  height: 14px;
}
</style>
