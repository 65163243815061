<template>
  <div v-if="visible" class="filter-modal">
    <div class="filter-div">
      <input type="number" class="text-input-primary" :placeholder="this.$t('therapy_number')" v-model="programNumberValue" />

      <div class="btn-style" @click="applyFilter">
        <span class="filter-txt">{{$t('filter_therapy')}}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onFilter: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      selectedFilter: null,
      programNumberValue: '',
    };
  },
  methods: {
    applyFilter() {
      this.onFilter({
        programNumber: this.programNumberValue,
      });
    }
  },
};
</script>

<style scoped>
.filter-modal {
  position: absolute;
  top: 40px;
  display: flex;
  left: 0;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.08);
  min-width: 350px;
}
.filter-div{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
  width: 100%;
  margin: 15px;
  gap: 10px;
}
.btn-style{
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: #040C2D;
  cursor:pointer;
}
.filter-txt{
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

</style>
