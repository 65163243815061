<template>
  <div v-if="visible" class="filter-modal">
    <div class="filter-div">
      <div class="text-input">
        <input type="number" class="text-input-primary" placeholder="Min number" @input="handleMin" :value="inputMin" />
        <input type="number" class="text-input-primary" placeholder="Max number"  @input="handleMax" :value="inputMax" />
      </div>
      <div
          class="filter-item"
          v-for="item in filterItems"
          :key="item.id"
          @click="toggleCheck(item.name)"
      >
        <span class="filter-title">{{ item.name }}</span>
        <img v-if="selectedFilter === item.name" src="../../assets/images/checkboxchecked-circle.svg">
        <img v-else src="../../assets/images/checkbox-circle.svg">
      </div>
      <div class="btn-style" @click="applyFilter">
        <span class="filter-txt">{{$t('filter_therapy')}}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    filterItems: {
      type: Array,
      default: [
        {
        id: 1,
        name: "0-21",
        min: 0,
        max: 21,
      },
        {
          id: 2,
          name: "21-49",
          min: 21,
          max: 49,
        },
        {
          id: 3,
          name: "50-99",
          min: 50,
          max: 99,
        },
        {
          id: 4,
          name: "100-199",
          min: 100,
          max: 199,
        },
        {
          id: 5,
          name: "200-499",
          min: 200,
          max: 499,
        },
        {
          id: 6,
          name: "500+",
          min: 500,
        },
      ]
    },
    onFilter: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      selectedFilter: null,
      inputMin: '',
      inputMax: '',
    };
  },
  methods: {
    selectFilter(filterName) {
      this.$emit('filter-selected', filterName);
    },
    toggleCheck(itemName) {
      if (this.selectedFilter === itemName) {
        this.selectedFilter = null;
      } else {
        this.selectedFilter = itemName;
      }
      this.inputMax = '';
      this.inputMin = '';
      event.stopPropagation();
    },
    handleMin(event) {
      this.selectedFilter = undefined;
      this.inputMin = event.target.value;
    },
    handleMax(event) {
      this.selectedFilter = undefined;
      this.inputMax = event.target.value;
    },
    applyFilter() {
      if (this.inputMin !== '' || this.inputMax !== '') {
        this.onFilter({
          min: this.inputMin !== '' ? parseInt(this.inputMin) : undefined,
          max: this.inputMax !== '' ? parseInt(this.inputMax) : undefined,
        });
      } else if (this.selectedFilter) {
        this.filterItems.map(item => {
          if (item.name === this.selectedFilter) {
            this.onFilter({
              min: item.min,
              max: item.max,
            });
          }
        });
      }
    }
  },
};
</script>

<style scoped>
.filter-modal {
  position: absolute;
  top: 40px;
  display: flex;
  left: 0;
  justify-content: flex-start;
  align-items: center;
  z-index: 9999;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.08);
  min-width: 350px;
}
.filter-div{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  overflow: visible;
  width: 100%;
}
.filter-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px 0 12px;
  border-radius: 12px;
  cursor: pointer;
  width: 100%;
}
.filter-title{
  width: 100%;
  color: #475467;
  font-size: 12px;
  font-style: normal;
  line-height: normal;
}
.btn-style{
  display: flex;
  height: 32px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: #040C2D;
  margin: 12px;
  cursor:pointer;
}
.filter-txt{
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}
.text-input{
  display: flex;
  padding: 12px;
  align-items: center;
  align-self: stretch;
  flex-direction: row;
  gap:12px
}
.textinp{
  height: 32px;
}
</style>
