<template>
  <main class="login-main">
    <header>
      <img alt="Ibility logo" class="logo" src="../assets/images/iblity_black.svg" width="60" height="40" />
    </header>
    <div class="login-container">
      <div class="left">

        <h1 class="main-text">
          {{$t('enhance_your_inner')}}<br/>
          {{$t('world_with_end_to_end')}}<br/>
          {{$t('personal_therapy')}}<br/>
          {{$t('solutions')}}
        </h1>

        <img class="main-image" alt="Illustration" src="../assets/images/login_vector.svg" />

        <div class="trusted-by">
          <div class="trusted-by-line"></div>
          <span class="trusted-by-text">
            {{$t('Trusted_by')}}
          </span>
        </div>

        <img class="trusted-by-logos" src="../assets/images/trusted_by_demo.svg" alt="Trusted by logos" />
      </div>
      <div class="right">
        <div class="login-form">
          <div class="welcome-back">
            <img alt="Ibility dots" class="login-image" src="../assets/images/login_dots.svg" />
            <h2 class="login-form-title">{{$t('welcome_back')}}</h2>
          </div>
          <span class="form-label">{{$t('e_mail')}}</span>
          <div class="input-holder">
            <input class="form-input" v-model="email" :placeholder="this.$t('enter_your_work_e-mail')" />
            <img v-if="isEmailVerified" class="icon" alt="Check" src="../assets/images/check.svg"/>
          </div>
          <span class="form-label">{{$t('password')}}</span>
          <div class="input-holder">
            <input type="password" v-model="password" class="form-input" :placeholder="this.$t('enter_your_password')" />
            <img v-if="password.length > 3" class="icon" alt="Check" src="../assets/images/check.svg" />
          </div>
          <router-link to="/" style="width: 100%">
          <button class="circular-btn" @click="login">
            <span v-if="!loading">{{$t('log_in')}}</span>
            <VueSpinnerIos v-else size="20" height="100%" color="#fff" />
          </button>
          </router-link>
          <div class="policy-text">
            {{$t('accept')}}
          </div>
          <div class="forgot-password">
            {{$t('forgot_password')}} <a href="#">{{$t('reset_password')}}</a>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>


<script>
import authService from "@/service/authService";
import {
  VueSpinnerIos
} from 'vue3-spinners'
import {handleErrorResponse} from "@/utils/utils";

export default {
  name: 'Login',
  components: {
    VueSpinnerIos
  },
  data() {
    return {
      email: '',
      password: '',
      isEmailVerified: false,
      loading: false,
    }
  },
  watch: {
    email(newValue) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      this.isEmailVerified = emailRegex.test(newValue);
    }
  },
  methods: {
    login() {
      if (this.isEmailVerified && this.password.length > 3 && !this.loading) {
        this.loading = true;

        authService.login(this.email,this.password).then(response => {
          const data = response.data.data;

          if (data.status === true) {
            localStorage.setItem('authToken', data.token);
            localStorage.setItem('user', JSON.stringify(data));
            this.$router.push('/');
          }

          this.loading = false;
        }).catch(error => {
          this.loading = false;
          handleErrorResponse(error, this.$snackbar);
        })
      }
      else{
        this.$snackbar.add({
          text: this.$t('InvalidEmail'),
          type:"error",
        })
      }
    }
  },
}
</script>

<style scoped>
.login-main {
  background-color: var(--vt-c-light-yellow);
}

header {
  position: fixed;
  display: flex;
  width: 100%;
  height: 104px;
  align-items: center;
  margin: 0;

  .logo {
    margin-left: 2.5rem;
  }
}

.login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  align-items: center;
  margin: 0;

  .left, .right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}

.main-text {
  font-family: euclid_semi_bold, 'sans-serif';
  font-size: 40px;
  margin-bottom: 1rem;
}

.main-image {
  margin-top: 1rem;
  max-height: 264px;
}

.trusted-by {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 454px;
  margin-top: 3rem;

  .trusted-by-line {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: #98A2B3;
    opacity: 0.2;
  }

  .trusted-by-text {
    padding: 10px 15px;
    background-color: #F9FAFB;
    color: #98A2B3;
    z-index: 9;
    font-family: euclid_regular, 'sans-serif';
    font-size: 12px;
  }
}

.trusted-by-logos {
  margin-top: 1.5rem;
  width: 100%;
  max-width: 454px;
}

.login-form {
  background-color: #FFFFFF;
  box-shadow: 0 192px 77px rgba(171, 171, 171, 0.01), 0 108px 65px rgba(171, 171, 171, 0.05), 0 48px 48px rgba(171, 171, 171, 0.09), 0 12px 26px rgba(171, 171, 171, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 80px 32px;
  width: 60%;
}

.circular-btn {
  margin-top: 1rem;
}

.welcome-back {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.login-form-title {
  font-family: euclid_semi_bold, 'sans-serif';
  font-size: 32px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.policy-text {
  color: #98a2b3;
  font-family: 'euclid_regular', sans-serif;
  font-size: 12px;
  text-align: center;
  margin-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.forgot-password {
  width: 100%;
  padding: 1rem;
  margin-top: 2rem;
  color: #667085;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  font-family: 'euclid_regular', sans-serif;
}

.forgot-password a {
  color: #4A68F8;
  text-decoration: none;
  font-family: 'euclid_medium', sans-serif;

}

.forgot-password a:hover {
  text-decoration: underline;
}

.forgot-password a:active {
  color: #4A68F8;
  text-decoration: none;
}

@media (max-width: 1200px) {
  .login-form {
    width: 70%;
  }

  .main-text {
    font-size: 36px;
  }

  .trusted-by {
    max-width: 400px;
  }

  .trusted-by-logos {
    max-width: 400px;
  }
}

@media (max-width: 996px) {
  .login-form {
    width: 80%;
  }

  .main-text {
    font-size: 32px;
  }

  .trusted-by {
    max-width: 350px;
  }

  .trusted-by-logos {
    max-width: 350px;
  }
}

@media (max-width: 876px) {
  .login-container {
    height: 100%;

    .left {
      display: none;
    }

    .right {
      width: 100%;
    }
  }

  header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      margin-left: 0;

    }
  }

  .login-form {
    padding: 30px 20px;
  }
}

@media (max-width: 576px) {
  .login-form {
    width: 90%;
  }

  .login-form-title {
    font-size: 26px;
  }

  .login-image {
    width: 100px;
  }

  .circular-btn {
    font-size: 15px;
    height: 40px;
  }

  .policy-text {
    font-size: 10px;
  }

  .forgot-password {
    margin-top: 1rem;
    font-size: 12px;
  }
}

@media (max-width: 376px) {
  .policy-text {
    font-size: 9px;
  }

  .forgot-password {
    font-size: 9px;
  }
}

</style>
