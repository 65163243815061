<template>
  <div class="action-wrapper">
    <div class="go-dropdown-button" @click="dropdownActive = !dropdownActive" v-click-outside="actionDropdownOutsideClick">
      {{ placeholder }}
      <img src="@/assets/images/arrowdown.svg" class="dropdown-arrow" :class="dropdownActive && 'arrow-up'"/>
    </div>

    <div class="action-dropdown right" v-if="dropdownActive">
      <div class="action-list" v-for="action in actions" @click="onAction(action.action)">
        {{ action.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: 'Actions'
    },
    actions: {
      type: Array,
      default: [
        {
          name: 'Action 1',
          action: '01'
        },
        {
          name: 'Action 2',
          action: '02'
        }
      ]
    },
    onAction: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      dropdownActive: false
    }
  },
  methods: {
    actionDropdownOutsideClick() {
      this.dropdownActive = false;
    }
  }

}
</script>

<style scoped>
.action-wrapper {
  position: relative;
}

.go-dropdown-button {
  cursor: pointer;
  font-size: 12px;
  padding: 7px 15px;
  border-radius: 8px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.action-dropdown {
  position: absolute;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 8px;
  top: 40px;
  min-width: 160px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 99999;
}

.action-list {
  cursor: pointer;
  font-size: 12px;
  padding: 7px 15px;
  display: block;
  border-radius: 8px;

}

.action-list:hover {
  background: #ffffff;

}

.dropdown-arrow {
  transition: transform 0.3s;
}

.arrow-up {
  transform: rotate(180deg);
}
</style>
