<template>
  <Layout>
    <h3 class="page-title">{{$t('blogs_title')}}</h3>
    <div class="filter-wrapper">
      <BlogFilters />

      <div class="justify-end-div">
        <ExpandableSearch />
        <router-link to="/blog/new">
          <div class="justify-end-add-btn no-text-select">
            <img src="../assets/images/plus.svg" />
            <span class="justify-end-addbtn-txt">{{$t('add_new')}}</span>
          </div>
        </router-link>
      </div>
    </div>

    <div class="blog-list-grid">
      <BlogCard
          v-for="item in blogData.data"
          :is-loading="isLoading"
          :image="item.avatarUrl"
          :title="item.name"
          :description="stripHtmlTags(truncate(item.description, 100))"
          :tags="item.tags.map((x) => x.name)"
          :to="`/blog/${item.blogId}`"
      />

      <BlogCard v-if="isLoading" />
      <BlogCard v-if="isLoading" />
      <BlogCard v-if="isLoading" />
    </div>


    <div class="pagination-align-center">
      <Pagination :current-page="currentPage" :total-items="blogData.total" :per-page="20" :on-page-change="handlePage"/>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import Pagination from "@/components/Pagination.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import BlogCard from "@/components/BlogCard.vue";
import blogService from "@/service/blogService";
import {handleErrorResponse, stripHtmlTags, truncate} from "@/utils/utils";
import PageCard from "@/components/PageCard.vue";
import BlogFilters from "@/components/tables/Blog/BlogFilters.vue";

export default {
  name: 'Blogs',
  components: {
    BlogFilters,
    PageCard,
    BlogCard,
    ExpandableSearch,
    Pagination,
    Layout
  },
  data() {
    return {
      isLoading: true,
      blogData: {
        data: [],
        total: 0
      },
      currentPage: 1,
    }
  },
  watch: {
    $route() {
      this.handlePage(1);
    }
  },
  methods: {
    truncate,
    stripHtmlTags,
    handlePage(page) {
      if (this.isLoading && this.currentPage !== 1) return;

      this.isLoading = true;
      this.currentPage = page;

      const routeQuery = this.$route.query;
      const tempFilters = [];

      if (routeQuery.searchText) {
        tempFilters.push({
          key: 'searchText',
          value: routeQuery.searchText
        });
      }

      if (routeQuery.addedDate) {
        tempFilters.push({
          key: 'addedDate',
          value: routeQuery.addedDate
        });
      }

      if (routeQuery.addedDateEnd) {
        tempFilters.push({
          key: 'addedDateEnd',
          value: routeQuery.addedDateEnd
        });
      }

      blogService.getBlogs(page, tempFilters).then((res) => {
        this.blogData = res.data.data;
      }).catch((err) => {
        handleErrorResponse(err, this.$snackbar);
      }).finally(() => {
        this.isLoading = false;
      });
    },
  }
}
</script>

<style scoped>
.page-title {
  font-family: euclid_medium, sans-serif;
  font-size: 20px;
  color: #040C2D;
  margin-bottom: 20px;
}
.filter-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.filter-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}
.filter-dropdown {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 8px;
  padding: 8px;
  background-color: #f2f2f2;
  position: relative;
  cursor: pointer;
}
.dropdown-text {
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-weight: 500;
  font-style: normal;
  white-space: nowrap;
}

.blog-list-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

@media (max-width: 1024px) {
  .blog-list-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .blog-list-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
