import axios from '../axios';
import {convertBooleanValuesToNumbers} from "@/utils/utils";

const notificationContentService = {
    getNotificationContent(page, filters = []) {
        if (filters && filters.length > 0) {
            const tempFilters = filters.map((filter) => {
                return `&${filter.key}=${filter.value}`;
            }).join('');

            return axios.get(`/v1/admin/notification/content/store?page=${page}&limit=20${tempFilters}`);
        } else {
            return axios.get(`/v1/admin/notification/content/store?page=${page}&limit=20`);
        }
    },
    getNotificationContentList() {
        return axios.get(`/v1/admin/notification/content/store?list=true`);
    },
    getNotificationDetails(id) {
        return axios.get(`/v1/admin/notification/content/getFromId/${id}`);
    },
    addNotificationContent(data) {
        data = convertBooleanValuesToNumbers(data);

        return axios.post(`/v1/admin/notification/content/add`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    updateNotificationContent(data) {
        data = convertBooleanValuesToNumbers(data);

        return axios.post(`/v1/admin/notification/content/update`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
}

export default notificationContentService;
