import axios from '../axios';

const inviteService = {
    getInvitedUsers(page, companyId) {
        return axios.get(`/v1/admin/user/invited/store?limit=20&page=${page}&companyId=${companyId}`);
    },
    inviteUser(data) {
        return axios.post('/v1/admin/user/invited/add', data);
    },
    deleteInvite(id) {
        return axios.delete(`/v1/admin/user/invited/delete/${id}`);
    }
}

export default inviteService;
