<template>
  <div class="linear-chart">
    <div
        v-for="(item, index) in data.list"
        :class="index === 0 ? 'chart-start' : index === (data.list.length - 1) ? 'chart-end' : 'chart-middle'"
        :style="{width: item.percentage + '%', background: item.color}"
    />
  </div>

  <div class="list-view">
    <div class="list-item" v-for="item in data.list">
      <div class="list-left">
        <span class="dot" :style="{background: item.color}"></span>
        <span class="list-percent">{{ item.percentage }}%</span>
        <span class="company-title">{{ item.name }}</span>
      </div>

      <div class="list-right">
        <span class="list-total">{{ item.count }} {{ $t('users_button') }}</span>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    data: {
      type: Object,
      default: {
        list: [],
      },
    },
  },
}
</script>

<style scoped>

.linear-chart {
  height: 24px;
  width: 100%;
  background: transparent;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.chart-start {
  height: 100%;
  width: 48%;
  background: #F09460;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.chart-middle {
  height: 100%;
  width: 30%;
  background: #3DA33E;
}

.chart-end {
  height: 100%;
  width: 22%;
  background: #4A68F8;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.list-view {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border-bottom: 1px solid #EAECF0;
  padding: 10px 0;
  margin-top: 20px;
}

.list-item.no-mt {
  margin-top: 0;
}

.list-item img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.list-item .dot {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.list-item .list-percent{
  font-family: euclid_medium, sans-serif;
  font-size: 24px;
  color: #101828;
  margin-right: 15px;
  line-height: 1;
}

.list-item .company-title {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #101828;
  margin-right: 15px;
  line-height: 1;
}

.list-left, .list-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.list-item .list-total {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #101828;
  line-height: 1;
}


</style>
