<script setup>
const props = defineProps(['options', 'selectedValue', 'placeholder', 'onSelect', 'loading']);

const handleChange = (event) => {
  props.onSelect(event.target.value);
};
</script>

<template>
  <div class="select-style">
    <select @change="handleChange" :disabled="loading">
      <option disabled value="" :selected="!selectedValue || selectedValue === ''">{{ loading ? "Loading..." : placeholder }}</option>
      <option v-for="option in options" :key="option.value" :value="option.value" :selected="option.value === selectedValue">
        {{ option.text }}
      </option>
    </select>
  </div>
</template>

<style scoped>
.select-style select {
  border-radius: 8px;
  border: 1px solid #F5F5F5;
  background: #F5F5F5;
  color: #282A36;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 15px 0px 13px 16px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select-style {
  position: relative;
}

.select-style:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #282A36;
  pointer-events: none;
}
</style>
