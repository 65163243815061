export default {
    mounted(el, binding) {
        const resizeTextarea = () => {
            el.style.height = '0';
            el.style.height = el.scrollHeight + 'px';
        };

        el.style.minHeight = '52px';
        el.style.overflow = 'hidden';

        el.addEventListener('input', resizeTextarea);
        window.addEventListener('resize', resizeTextarea);

        resizeTextarea();
    },

    updated(el) {
        const resizeTextarea = () => {
            el.style.height = '0';
            el.style.height = el.scrollHeight + 'px';
        };

        resizeTextarea();
    },

    unmounted(el) {
        const resizeTextarea = () => {
            el.style.height = '0';
            el.style.height = el.scrollHeight + 'px';
        };

        el.removeEventListener('input', resizeTextarea);
        window.removeEventListener('resize', resizeTextarea);
    }
};
