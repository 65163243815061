<template>
  <div class="session-wrapper">
    <div class="session-header" :class="sessionType">
      <img src="https://i.hizliresim.com/n8tzxgk.png" class="avatar"/>
      <div class="header-info">
        <span class="countdown">{{ timeLeft }}</span>
        <div v-if="sessionType === 'upcoming'" class="header-tag upcoming">Upcoming</div>
        <div v-if="sessionType === 'pending'" class="header-tag pending">Pending</div>
        <div v-if="sessionType === 'done'" class="header-tag done">Completed</div>
      </div>
    </div>
    <div class="session-body">
      <span class="name">Bessie Copper</span>
      <span class="title">Member</span>

      <div class="card-footer">
        <div class="session-info">
          <div class="info-card">
            <div class="icon-bg">
              <img src="@/assets/images/calendarFill.svg" />
            </div>
            <div class="session-info-row">
              <span class="session-info-title">20 Aug 2023, Wed</span>
              <span class="session-info-text">Session Date</span>
            </div>
          </div>

          <div class="info-card">
            <div class="icon-bg">
              <img src="@/assets/images/timer.svg" />
            </div>
            <div class="session-info-row">
              <span class="session-info-title">4pm - 5pm</span>
              <span class="session-info-text">Time</span>
            </div>
          </div>

          <div class="info-card" v-if="sessionType === 'upcoming'">
            <img src="@/assets/images/zoom.png" class="zoom-icon" />
            <div class="session-info-row">
              <span class="session-info-title">Meeting Link</span>
              <span class="session-info-link">us.zoom/ronalrichards</span>
            </div>
          </div>


          <div class="action-wrapper">
            <button class="primary-button" v-if="sessionType === 'upcoming'">
              Join
            </button>

            <button class="primary-button-outline" v-if="sessionType === 'pending'">
              Reject
            </button>

            <button class="primary-button" v-if="sessionType === 'pending'">
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sessionType: {
      type: String,
      required: true
    },
    timeLeft: {
      type: String,
      required: false,
      default: ""
    },
  },
  data() {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped>
.session-wrapper {
  width: 728px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
}
.session-header {
  position: relative;
  width: 100%;
  height: 80px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 15px;
  object-fit: cover;
  background-repeat: no-repeat;
}

.avatar {
  position: absolute;
  height: 80px;
  width: 80px;
  bottom: -30px;
  left: 20px;
  border-radius: 50%;
  border: 5px solid #FFFFFF;
}

.session-header.upcoming {
  background-color: #F8FDFB;
  background-image: url("../assets/images/upcomingHeader.svg");

}

.session-header.pending {
  background-color: #FEF5F1;
  background-image: url("../assets/images/pendingHeader.svg");
}

.session-header.done {
  background-color: #EBEDFB;
  background-image: url("../assets/images/completedHeader.svg");
}

.session-body {
  padding: 45px 15px 15px 15px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.name {
  font-family: 'euclid_medium', sans-serif;
  font-size: 16px;
  color: #101828;
}

.title {
  font-family: 'euclid_regular', sans-serif;
  font-size: 12px;
  color: #667085;
}

.header-info {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

.countdown {
  font-family: euclid_medium, sans-serif;
  font-size: 18px;
  color: #3DA33E;
}

.header-tag {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #FFFFFF;
  border-radius: 8px;
  padding: 2px 15px;
}

.header-tag.upcoming {
  background-color: #3DA33E;
}

.header-tag.pending {
  background-color: #F09460;
}

.header-tag.done {
  background-color: #4A68F8;
}

.session-info {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  margin-top: 20px;
}

.info-card {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.info-card .icon-bg {
  background-color: #F5F5F5;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-bg img {
  cursor: default;
  height: 24px;
  width: 24px;
}

.zoom-icon {
  height: 36px;
}

.session-info-row {
  display: flex;
  flex-direction: column;
}

.session-info-title {
  font-family: euclid_medium, sans-serif;
  font-size: 12px;
  color: #101828;
}

.session-info-text {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #98A2B3;
}

.session-info-link {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #4A68F8;
}

.action-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 5px;
}
</style>
