<template>
  <input
      class="style"
      :type="type"
      :placeholder="placeholder"
      @input="handleInput"
      :value="value"
  />
</template>

<script>
export default {
  name: "TextInput",
  props: {
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    }
  },
  methods: {
    handleInput(event) {
      this.$emit('update:value', event.target.value);
    }
  }
};
</script>

<style scoped>
.style{
  border-radius: 8px;
  border: 1px solid #F5F5F5;
  background: #F5F5F5;
  color: #282A36;
  font-feature-settings: 'clig' off, 'liga' off;
  /* B2/Regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding: 15px 0px 13px 16px;
  width: 100%;
}

</style>
