<template>
  <Layout padding="0">
    <ExpertHeader
        :is-loading="isLoading"
        :title="data.name + ' ' + data.surname"
        :photo="data.avatarUrl"
        :created-at="data.createdAt"
        :on-edit="showEditModal"
        :on-delete="showDeleteModal"
    />
    <div class="main-content">
      <div class="tab-panel-row">
        <div class="tab-panel">
          <button class="tab-button" :class="{'active': tabIndex === 0}" @click="() => changeTab(0)">
            <span>{{$t('general')}}</span>
          </button>
          <button class="tab-button" :class="{'active': tabIndex === 1}" @click="() => changeTab(1)">
            <span>{{$t('online_sessions')}}</span>
            <span class="badge">2</span>
          </button>
          <button class="tab-button" :class="{'active': tabIndex === 2}" @click="() => changeTab(2)">
            <span>Q/A</span>
          </button>
        </div>

        <div class="tab-actions">
          <div class="action-row" v-if="tabIndex === 1">
            <button class="primary-button" @click="sessionModal = true">{{ $t('create_new') }}</button>
          </div>
        </div>
      </div>


      <div v-if="tabIndex === 0" class="tab-container">
        <div class="tab-row">
          <div class="tab-row-left">
            <h3>{{$t('expert_summary')}}</h3>
            <div class="company-details">
              <div class="company-details-col">

                <div class="company-detail-row">
                  <div class="company-detail-icon">
                    <img src="@/assets/images/avatar.svg" />
                  </div>
                  <div class="company-detail-body">
                    <span class="detail-title">{{$t('full_name')}}</span>
                    <span class="detail-text" v-if="!isLoading">{{data.name}} {{data.surname}}</span>

                    <free-style-shimmer
                        :is-loading="isLoading"
                        height="7px"
                        width="120px"
                        border-radius="50px"
                        color="#EAECF0"
                    />
                  </div>
                </div>

                <div class="company-detail-row">
                  <div class="company-detail-icon">
                    <img src="@/assets/images/avatar.svg" />
                  </div>
                  <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('title_expert')}}
                </span>
                    <span class="detail-text" v-if="!isLoading">{{data.title}}</span>

                    <free-style-shimmer
                        :is-loading="isLoading"
                        height="7px"
                        width="120px"
                        border-radius="50px"
                        color="#EAECF0"
                    />
                  </div>
                </div>

                <div class="company-detail-row">
                  <div class="company-detail-icon">
                    <img src="@/assets/images/buildings.svg" />
                  </div>
                  <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('graduated_from')}}
                </span>
                    <span class="detail-text" v-if="!isLoading && data.defaultEducation">{{  data.defaultEducations.schoole.name }}</span>

                    <free-style-shimmer
                        :is-loading="isLoading"
                        height="7px"
                        width="120px"
                        border-radius="50px"
                        color="#EAECF0"
                    />
                  </div>
                </div>

                <div class="company-detail-row">
                  <div class="company-detail-icon">
                    <img src="@/assets/images/tag.svg" />
                  </div>
                  <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('experience')}}
                </span>
                    <span class="detail-text" v-if="!isLoading">{{data.experiance}} {{$t('years_experience')}}</span>

                    <free-style-shimmer
                        :is-loading="isLoading"
                        height="7px"
                        width="120px"
                        border-radius="50px"
                        color="#EAECF0"
                    />
                  </div>
                </div>

              </div>

              <div class="company-details-col">
                <div class="company-detail-row">
                  <div class="company-detail-icon">
                    <img src="@/assets/images/message.svg" />
                  </div>
                  <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('e_mail')}}
                </span>
                    <span class="detail-text" v-if="!isLoading">{{ data.email }}</span>

                    <free-style-shimmer
                        :is-loading="isLoading"
                        height="7px"
                        width="120px"
                        border-radius="50px"
                        color="#EAECF0"
                    />
                  </div>
                </div>

                <div class="company-detail-row">
                  <div class="company-detail-icon">
                    <img src="@/assets/images/phone.svg" />
                  </div>
                  <div class="company-detail-body">
                <span class="detail-title">
                   {{$t('phone')}}
                </span>
                    <span class="detail-text" v-if="!isLoading">{{ data.phone }}</span>

                    <free-style-shimmer
                        :is-loading="isLoading"
                        height="7px"
                        width="120px"
                        border-radius="50px"
                        color="#EAECF0"
                    />
                  </div>
                </div>

                <div class="company-detail-row">
                  <div class="company-detail-icon">
                    <img src="@/assets/images/cake.svg" />
                  </div>
                  <div class="company-detail-body">
                <span class="detail-title">
                  {{$t('birthday')}}
                </span>
                    <span class="detail-text" v-if="!isLoading">{{ formatDate(data.birthday) }}</span>

                    <free-style-shimmer
                        :is-loading="isLoading"
                        height="7px"
                        width="120px"
                        border-radius="50px"
                        color="#EAECF0"
                    />
                  </div>
                </div>

              </div>
            </div>

            <div class="title-icon">
              <h3>{{$t('about_expert')}}</h3>
              <img src="@/assets/images/editPen.svg" class="edit-icon" @click="editAboutModal = true" />
            </div>

            <p v-if="!isLoading">
              {{data.about}}
            </p>

            <free-style-shimmer
                :is-loading="isLoading"
                height="7px"
                width="200px"
                border-radius="50px"
                color="#EAECF0"
                style="margin-bottom: 10px"
            />

            <free-style-shimmer
                :is-loading="isLoading"
                height="7px"
                width="160px"
                border-radius="50px"
                color="#EAECF0"
            />


            <div class="title-icon">
              <h3>{{$t('education')}}</h3>
              <img src="@/assets/images/editPen.svg" class="edit-icon" @click="editEducationModal = true"/>
            </div>

            <div class="info-list">

              <div class="info-card" v-if="!isLoading" v-for="item in data.educations">
                <div class="info-left">
                  <img src="https://i.hizliresim.com/48wveyk.png" />
                  <div class="info">
                    <span class="title">{{ item.schoole.name }}, {{ item.schoole.country }}</span>
                    <span class="description">{{ item.degree.name }}</span>
                  </div>
                </div>
                <span class="date">{{item.finishAt}}</span>
              </div>

              <div class="info-card" v-else>
                <div class="info-left">
                  <free-style-shimmer
                      :is-loading="true"
                      height="40px"
                      width="40px"
                      border-radius="50%"
                      color="#EAECF0"
                  />
                  <div class="info">
                    <free-style-shimmer
                        :is-loading="true"
                        height="7px"
                        width="160px"
                        border-radius="50px"
                        color="#EAECF0"
                    />
                    <free-style-shimmer
                        :is-loading="true"
                        height="7px"
                        width="240px"
                        border-radius="50px"
                        color="#EAECF0"
                        style="margin-top: 5px"
                    />
                  </div>
                </div>
                <span class="date">
                   <free-style-shimmer
                       :is-loading="true"
                       height="7px"
                       width="40px"
                       border-radius="50px"
                       color="#EAECF0"
                       style="margin-top: 5px"
                   />
              </span>
              </div>
            </div>


            <div class="title-icon">
              <h3>{{$t('certifications')}}</h3>
              <img src="@/assets/images/editPen.svg" class="edit-icon" @click="showCertificationModal"/>
            </div>

            <div class="info-list">

              <div class="info-card" v-if="!isLoading" v-for="item in data.certificates">
                <div class="info-left">
                  <img src="https://i.hizliresim.com/gzzvvnq.png" />
                  <div class="info">
                    <span class="title">{{item.name}}</span>
                    <span class="description">{{ item.institution }}</span>
                  </div>
                </div>
                <span class="date">{{item.finishAt}}</span>
              </div>

              <div class="info-card" v-else>
                <div class="info-left">
                  <free-style-shimmer
                      :is-loading="true"
                      height="40px"
                      width="40px"
                      border-radius="50%"
                      color="#EAECF0"
                  />
                  <div class="info">
                    <free-style-shimmer
                        :is-loading="true"
                        height="7px"
                        width="160px"
                        border-radius="50px"
                        color="#EAECF0"
                    />
                    <free-style-shimmer
                        :is-loading="true"
                        height="7px"
                        width="240px"
                        border-radius="50px"
                        color="#EAECF0"
                        style="margin-top: 5px"
                    />
                  </div>
                </div>
                <span class="date">
                   <free-style-shimmer
                       :is-loading="true"
                       height="7px"
                       width="40px"
                       border-radius="50px"
                       color="#EAECF0"
                       style="margin-top: 5px"
                   />
              </span>
              </div>

            </div>

            <div class="title-icon">
              <h3>{{$t('specialties')}}</h3>
              <img src="@/assets/images/editPen.svg" class="edit-icon" @click="showTagModal"/>
            </div>

            <div class="specialities-tags">
              <span class="category-tag" v-for="tag in data.tags">{{tag.name}}</span>
            </div>


          </div>

          <div class="tab-row-right">
            <div class="left-title-row">
              <h4>{{$t('availability')}}</h4>
              <img src="@/assets/images/editPen.svg" @click="availabilityModal = true" />
            </div>

            <div class="availability-row">
              <div class="availability-box" v-for="item in availableDates" :class="{'checked': item.selected}" @click="() => handleDateSelect(item.id)">
                <span class="date-name">{{item.name}}</span>
                <span class="date-number">{{item.date}}</span>
              </div>
            </div>

            <div class="availability-times-list">
              <div class="availability-check" v-for="item in availableTimes" @click="() => toggleTime(item.id)">
                <div>
                  <img v-if="item.checked" src="@/assets/images/checkboxchecked-circle.svg" />
                  <img v-else src="@/assets/images/checkbox-circle.svg" />
                </div>
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="tabIndex === 1" class="tab-container">
        <div class="tab-row">
          <div class="tab-row-left">
            <h3>{{$t('online_sessions')}}</h3>

            <div class="session-tab-panel no-text-select">
              <div class="session-tab-pane" :class="{'active': activeSessionTab === 0}" @click="() => changeSessionTab(0)">
                {{$t('upcoming')}} <span class="badge">3</span>
              </div>

              <div class="session-tab-pane" :class="{'active': activeSessionTab === 1}" @click="() => changeSessionTab(1)">
                {{$t('pending')}} <span class="badge gray">2</span>
              </div>

              <div class="session-tab-pane" :class="{'active': activeSessionTab === 2}" @click="() => changeSessionTab(2)">
                {{$t('completed')}} <span class="badge gray">4</span>
              </div>
            </div>


            <div v-if="activeSessionTab === 0">
              <div class="session-list">
                <SessionCard session-type="upcoming" time-left="00 : 00 : 05" />
                <SessionCard session-type="upcoming" time-left="00 : 02 : 30"/>
                <SessionCard session-type="upcoming" time-left="00 : 05 : 00" />
              </div>
            </div>

            <div v-if="activeSessionTab === 1">
              <div class="session-list">
                <SessionCard session-type="pending" />
                <SessionCard session-type="pending" />
              </div>
            </div>

            <div v-if="activeSessionTab === 2">
              <div class="session-list">
                <SessionCard session-type="done" />
                <SessionCard session-type="done" />
                <SessionCard session-type="done" />
                <SessionCard session-type="done" />
              </div>
            </div>
          </div>

          <div class="tab-row-right">
            <div class="left-title-row">
              <h4>{{$t('availability')}}</h4>
              <img src="@/assets/images/editPen.svg" @click="availabilityModal = true" />
            </div>

            <div class="availability-row">
              <div class="availability-box" v-for="item in availableDates" :class="{'checked': item.selected}" @click="() => handleDateSelect(item.id)">
                <span class="date-name">{{item.name}}</span>
                <span class="date-number">{{item.date}}</span>
              </div>
            </div>

            <div class="availability-times-list">
              <div class="availability-check" v-for="item in availableTimes" @click="() => toggleTime(item.id)">
                <div>
                  <img v-if="item.checked" src="@/assets/images/checkboxchecked-circle.svg" />
                  <img v-else src="@/assets/images/checkbox-circle.svg" />
                </div>
                <span>{{ item.name }}</span>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div v-if="tabIndex === 2" class="tab-container">
        <h3>{{$t('questions')}}</h3>

        <div class="session-tab-panel no-text-select">
          <div class="session-tab-pane" :class="{'active-orange': activeChatTab === 0}" @click="() => changeChatTab(0)">
            {{$t('pending')}}
          </div>

          <div class="session-tab-pane" :class="{'active-orange': activeChatTab === 1}" @click="() => changeChatTab(1)">
            {{$t('answered')}}
          </div>
        </div>

        <div class="chat-wrapper">
          <div class="chat-list scroll-design">

            <div class="no-messages-wrapper" v-if=" !chatLoading && chatData.length === 0">
              <span>{{$t('no_messages_fount')}}</span>
            </div>
            <div class="chat-list-item" v-if="!chatLoading" v-for="item in chatData" :class="{'active': activeChatId === item.chatId}" @click="getMessages(item.chatId)">
              <div class="left-border" v-if="activeChatId === item.id"></div>
              <img :src="item.avatar" class="chat-avatar" />
              <div class="chat-info">
                <span class="chat-name">{{item.user}}</span>
                <span class="last-message"></span>
                <span class="chat-date">{{ formatDateTime(item.time) }}</span>
              </div>
              <div class="badge orange top-right">{{item.unSeenMessageCount}}</div>
            </div>

            <div class="chat-list-item" v-if="chatLoading">
              <free-style-shimmer height="64px" width="64px" border-radius="50%" color="#EAECF0" />

              <div class="chat-info">
                <div class="chat-name">
                  <free-style-shimmer  height="10px" width="40%" border-radius="12px" color="#EAECF0" />
                </div>
                <div class="last-message">
                  <free-style-shimmer  height="8px" width="80%" border-radius="12px" color="#EAECF0" />
                </div>
              </div>
            </div>

            <div class="chat-list-item" v-if="chatLoading">
              <free-style-shimmer height="64px" width="64px" border-radius="50%" color="#EAECF0" />

              <div class="chat-info">
                <div class="chat-name">
                  <free-style-shimmer  height="10px" width="40%" border-radius="12px" color="#EAECF0" />
                </div>
                <div class="last-message">
                  <free-style-shimmer  height="8px" width="80%" border-radius="12px" color="#EAECF0" />
                </div>
              </div>
            </div>

            <div class="chat-list-item" v-if="chatLoading">
              <free-style-shimmer height="64px" width="64px" border-radius="50%" color="#EAECF0" />

              <div class="chat-info">
                <div class="chat-name">
                  <free-style-shimmer  height="10px" width="40%" border-radius="12px" color="#EAECF0" />
                </div>
                <div class="last-message">
                  <free-style-shimmer  height="8px" width="80%" border-radius="12px" color="#EAECF0" />
                </div>
              </div>
            </div>

          </div>

          <div class="chat">
            <div class="chat-header" v-if="!chatLoading && activeChatId !== -1">
              <img :src="getActiveChat.avatar" class="chat-avatar" />
              <div class="chat-info">
                <span class="chat-name">{{getActiveChat.user}}</span>
                <span class="chat-date">{{  formatDateTime(getActiveChat.time) }}</span>
              </div>
            </div>

            <div class="chat-content scroll-design"  v-if="!chatLoading && activeChatId !== -1">
              <div class="chat-message" v-for="item in currentMessages" :class="{'incoming': !item.isSent}">
                <div class="chat-message-wrapper" :class="{'incoming': !item.isSent}">
                  <span class="chat-message-text">{{item.textContent}}</span>
                  <span class="chat-message-time">{{formatDateTime(item.time)}}</span>
                </div>
              </div>
            </div>

            <div class="chat-content not-selected" v-if="activeChatId === -1">
              <span>{{$t('no_conversation_selected')}}</span>
            </div>

            <div class="chat-content not-selected" v-if="messagesLoading && activeChatId !== -1">
              <span>{{$t('messages_loading')}}</span>
            </div>

            <div class="chat-footer" v-if="!chatLoading && activeChatId !== -1">
              <input class="chat-input" placeholder="Type your message here" v-model="messageContent" :disabled="sendLoading" />
              <button class="send-button" @click="addMessage" :disabled="sendLoading">
                <img src="@/assets/images/sendArrow.svg" v-if="!sendLoading"/>
                <VueSpinnerIos v-if="sendLoading" size="20" height="100%" color="#fff" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ConfirmDeleteModal
        :title="this.$t('alert_expert_messages')"
        :message="this.$t('all_experts_deleted')"
        :is-visible="deleteModalVisible"
        :on-action="handleDelete"
        :on-close="handleDeleteModalClose"
    />

    <ExpertModal :visible="editModalVisible" :on-hide="hideEditModal" :edit-mode="true" :edit-data="data" :expert-id="data.expertId" :refresh-callback="getDetails"/>
    <EditAboutModal :visible="editAboutModal" :on-hide="hideEditAboutModal" :about-text="data.about" :expert-id="data.expertId"/>
    <EditEducationModal :visible="editEducationModal" :on-hide="hideEducationModal" :education-data="data.educations" :expert-id="data.expertId" />
    <EditCertificationModal :visible="certificationModal" :on-hide="hideCertificationModal" :certification-data="data.certificates" :expert-id="data.expertId" />
    <ExpertTagModal :visible="expertTagVisible" :on-hide="hideTagModal" :tag-data="data.tags"/>

    <SessionModal :visible="sessionModal" :on-hide="hideSessionModal" :expert-id="data.expertId"/>
    <AvailabilityModal :visible="availabilityModal" :on-hide="hideAvailabilityModal" :expert-id="data.expertId"/>
  </Layout>
</template>

<script>
import Layout from "@/components/layouts/Layout.vue";
import ExpandableSearch from "@/components/ExpandableSearch.vue";
import UserTable from "@/components/tables/User/UserTable.vue";
import TherapyCard from "@/components/TherapyCard.vue";
import TransactionTable from "@/components/tables/TransactionTable.vue";
import AddUserToCompanyModal from "@/components/modals/InviteUserModal.vue";
import SessionCard from "@/components/SessionCard.vue";
import TotalRating from "@/components/TotalRating.vue";
import ReviewCard from "@/components/ReviewCard.vue";
import expertService from "@/service/expertService";
import ExpertHeader from "@/components/ExpertHeader.vue";
import EditAboutModal from "@/components/modals/EditAboutModal.vue";
import EditEducationModal from "@/components/modals/EditEducationModal.vue";
import SessionModal from "@/components/modals/SessionModal.vue";
import AvailabilityModal from "@/components/modals/AvailabilityModal.vue";
import chatService from "@/service/chatService";
import ExpertModal from "@/components/modals/ExpertModal.vue";
import ConfirmDeleteModal from "@/components/modals/ConfirmDeleteModal.vue";
import {
  VueSpinnerIos
} from 'vue3-spinners'
import EditCertificationModal from "@/components/modals/EditCertificationModal.vue";
import ExpertTagModal from "@/components/modals/ExpertTagModal.vue";



export default {
  components: {
    ExpertTagModal,
    EditCertificationModal,
    ConfirmDeleteModal,
    ExpertModal,
    AvailabilityModal,
    SessionModal,
    EditEducationModal,
    EditAboutModal,
    ExpertHeader,
    ReviewCard,
    TotalRating,
    SessionCard,
    AddUserToCompanyModal,
    TransactionTable,
    TherapyCard,
    UserTable,
    ExpandableSearch,
    VueSpinnerIos,
    Layout
  },
  data() {
    return {
      isLoading: true,
      searchText: '',
      tabIndex: 0,
      editModalVisible: false,
      editAboutModal: false,
      editEducationModal: false,
      sessionModal: false,
      addUserModal: false,
      availabilityModal: false,
      deleteModalVisible: false,
      certificationModal: false,
      expertTagVisible: false,

      availableDates: [
        {
          id: 1,
          name: "Wed",
          date: "20",
          selected: false,
        },
        {
          id: 2,
          name: "Thu",
          date: "21",
          selected: false,
        },
        {
          id: 3,
          name: "Fri",
          date: "22",
          selected: false,
        },
        {
          id: 4,
          name: "Sat",
          date: "23",
          selected: false,
        },
        {
          id: 5,
          name: "Sun",
          date: "24",
          selected: false,
        },
      ],
      availableTimes: [
        {
          id: 1,
          name: "2pm - 3pm",
          checked: false,
        },
        {
          id: 2,
          name: "3pm - 4pm",
          checked: false,
        },
        {
          id: 3,
          name: "4pm - 5pm",
          checked: false,
        },
        {
          id: 4,
          name: "5pm - 6pm",
          checked: false,
        },
        {
          id: 5,
          name: "6pm - 7pm",
          checked: false,
        },
        {
          id: 6,
          name: "7pm - 8pm",
          checked: false,
        },
      ],

      activeSessionTab: 0,
      activeChatTab: 0,
      chatLoading: true,
      chatData: [],
      activeChatId: -1,
      currentChatPage: 1,
      currentChatData: {},
      currentMessages: [],
      messageContent: '',
      messagesLoading: true,
      sendLoading: false,

      data: {},
    }
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    changeTab(index) {
      this.tabIndex = index;
    },
    showEditModal() {
      this.editModalVisible = true;
    },
    hideEditModal() {
      this.editModalVisible = false;
    },
    hideSessionModal() {
      this.sessionModal = false;
    },
    hideAvailabilityModal() {
      this.availabilityModal = false;
    },
    showCertificationModal() {
      this.certificationModal = true;
    },
    hideCertificationModal() {
      this.certificationModal = false;
    },
    hideTagModal() {
      this.expertTagVisible = false;
    },
    showTagModal() {
      this.expertTagVisible = true;
    },
    showDeleteModal() {
      this.deleteModalVisible = true;;
      event.stopPropagation();
    },
    handleDeleteModalClose() {
      this.deleteModalVisible = false;
    },
    getDetails() {
      const routeParams = this.$route.params;

      this.isLoading = true;
      expertService.getExpertDetails(routeParams.id).then((response) => {
        this.isLoading = false;
        this.data = response.data.data;
        this.loadChatData();
      });
    },
    formatDate(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = {year: 'numeric', month: 'short', day: 'numeric'};
        return date.toLocaleDateString("en", options);
      } catch (e) {
        return '-';
      }
    },
    formatDateTime(dateStr) {
      try {
        const date = new Date(dateStr);
        const options = {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'};
        return date.toLocaleDateString("en", options);
      } catch (e) {
        return '-';
      }
    },
    toggleTime(id) {
      this.availableTimes = this.availableTimes.map(item => {
        if (item.id === id) {
          item.checked = !item.checked;
        } else {
          item.checked = false;
        }
        return item;
      })
    },
    handleDateSelect(id) {
      this.availableDates = this.availableDates.map(item => {
        if (item.id === id) {
          item.selected = !item.selected;
        } else {
          item.selected = false;
        }
        return item;
      })
    },
    changeSessionTab(index) {
      this.activeSessionTab = index;
    },
    changeChatTab(index) {
      this.activeChatTab = index;
    },
    getMessages(id) {
      this.activeChatId = id;
      this.messagesLoading = true;

      chatService.getChatDetails(id).then((response) => {
        this.currentChatData = response.data.data;
        this.currentMessages = response.data.data.data.reverse();
        this.messagesLoading = false;
      }).catch((error) => {
        this.$snackbar.add({
          text: 'An error occurred while loading chat messages',
          type: 'error',
        });
      });
    },
    hideEditAboutModal() {
      this.editAboutModal = false;
    },
    hideEducationModal() {
      this.editEducationModal = false;
    },
    loadChatData(status = 0) {
      this.chatLoading = true;

      chatService.getChats(this.currentChatPage, status, this.data.expertId).then((response) => {
        this.chatData = response.data.data.messages.data;
        console.log(this.chatData)
        this.chatLoading = false;
      }).catch((error) => {
        console.log(error);
      });

    },
    addMessage() {
      if (this.activeChatId === -1 || this.activeChatId === undefined || this.messageContent === '') {
        return;
      }

      this.sendLoading = true;

      chatService.addMessage({
        expertId: this.data.expertId,
        userId: this.getActiveChat.userId,
        message: this.messageContent
      }).then((response) => {
        this.messageContent = '';
        this.getMessages(this.activeChatId);
        this.sendLoading = false;
      }).catch((error) => {
        this.$snackbar.add({
          text: 'An error occurred while sending message',
          type: 'error',
        });
      });

    },
    handleDelete() {
      this.deleteModalVisible = false;

      expertService.deleteExpert(this.data.expertId).then(() => {
        this.$router.push('/experts');
      }).catch(() => {
        this.$snackbar.add({
          text: 'An error occurred while deleting the expert',
          type: 'error',
        });
      })
    },
  },
  watch: {
    activeChatTab() {
      this.chatLoading = true;
      this.activeChatId = -1;
      if (this.activeChatTab === 0) {
        this.loadChatData(0);
      } else {
        this.loadChatData(1);
      }
    },
  },
  computed: {
    getActiveChat() {
      return this.chatData.find(item => item.chatId === this.activeChatId);
    }
  }
}
</script>

<style scoped>
.tab-panel {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: row;
}
.main-content {
  padding: 25px;
}
.tab-panel-row {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #F2F4F7;
  border-bottom: 1px solid #F2F4F7;
  padding: 10px 0;
  margin-bottom: 20px;
}
.tab-button {
  padding: 6px 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.tab-button span {
  font-family: 'euclid_medium', sans-serif;
  font-size: 12px;
  color: #344054;
}
.tab-button.active, .tab-button:hover {
  background-color: #F2F4F7;
}
h3 {
  font-family: euclid_medium, sans-serif;
  font-size: 18px;
  color: #040C2D;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.company-details-col {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
}
.company-detail-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.company-detail-icon {
  background-color: #F5F5F5;
  border-radius: 16px;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-detail-icon img {
  cursor: default;
  width: 20px;
  height: 20px;
}
.detail-title {
  font-family: euclid_medium, sans-serif;
  display: block;
  color: #667085;
  font-size: 12px;
}
.detail-text {
  font-family: euclid_medium, sans-serif;
  display: block;
  color: #101828;
  font-size: 14px;
}
.company-detail-body {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.company-details {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.therapies-row {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.tab-row {
  display: flex;
  flex-direction: row;
}

.tab-row-left {
  display: flex;
  flex-direction: column;
  width: 65%;
}

.tab-row-right {
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 100%;
  border-left: 1px solid #F2F4F7;
  padding-left: 15px;
  padding-right: 15px;
}

h4 {
  margin: 0;
  padding: 0;
  font-family: euclid_medium, sans-serif;
  font-size: 18px;
  color: #040C2D;
}

.left-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.availability-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.availability-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #FFFFFF;
  border: 1px solid #D0D5DD;
  padding: 10px;
  width: 100%;
  cursor: pointer;
}

.availability-box.checked {
  background-color: #040C2D;
}

.availability-box.checked .date-name {
  color: #FFFFFF;
}

.availability-box .date-name {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
}

.availability-box .date-number {
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  color: #667085;
}

.availability-times-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.availability-check {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 8px;
  background-color:  #F5F5F5;
  cursor: pointer;
}

.availability-check span {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #040C2D;
}

p {
  color: #354055;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.title-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  margin-top: 20px
}

.info-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.info-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.info-card .info {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.info-card img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.info-card .title {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
}

.info-card .description {
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  color: #667085;
}

.info-card .date {
  font-family: euclid_regular, sans-serif;
  font-size: 16px;
  color: #667085;
}

.info-list {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.specialities-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.badge {
  background-color: #3DA33E;
  border-radius: 24px;
  color: #FFFFFF !important;
  font-family: euclid_medium, sans-serif;
  font-size: 10px !important;
  padding: 4px 10px;
  margin-left: 5px;
}

.badge.gray {
  background-color: #EAECF0;
  color: #667085 !important;
}

.badge.orange {
  background-color: #F09460;
}

.badge.top-right {
  position: absolute;
  top: 10px;
  right: 10px;
}

.session-tab-panel {
  width: 100%;
  border-bottom: 2px solid #F2F4F7;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.session-tab-pane {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #667085;
  border-bottom: 2px solid transparent;
  cursor: pointer;
}

.session-tab-pane.active {
  border-bottom: 2px solid #3DA33E;
  color: #101828;
}

.session-tab-pane.active-orange {
  border-bottom: 2px solid #F09460;
  color: #101828;
}

.session-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.reviews-grid {
  width: 100%;
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.chat-wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 20px;
}

.chat-list {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  height: 600px;
  overflow-y: auto;
}

.chat-list-item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 10px 10px 10px 16px;
  border-radius: 12px;
  background-color:  transparent;
  cursor: pointer;
}

.chat-list-item.active {
  background-color: #EFF5F5;
}

.chat-list-item .left-border {
  position: absolute;
  height: 100%;
  width: 6px;
  background-color: #3DA33E;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  top: 0;
  left: 0;
}

.chat-list-item .chat-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
}

.chat-info {
  display: flex;
  flex-direction: column;
  gap: 7px;
  width: 100%;
}

.chat-info .chat-name {
  font-family: euclid_medium, sans-serif;
  font-size: 16px;
  color: #101828;
  line-height: 1;
}

.chat-info .last-message {
  font-family: euclid_regular, sans-serif;
  font-size: 14px;
  color: #667085;
  line-height: 1;
}

.chat-info .chat-date {
  font-family: euclid_regular, sans-serif;
  font-size: 12px;
  color: #98A2B3;
  line-height: 1;
}

.chat {
  width: 65%;
  height: 600px;
}

.chat-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  padding: 0 15px;
  height: 70px;
}

.chat-header .chat-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.chat-content {
  width: 100%;
  height: calc(100% - 150px);
  overflow-y: auto;
  padding: 20px;
  background-color: #FCFCFC;
  border-left: 1px solid #EAECF0;
  border-right: 1px solid #EAECF0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.no-messages-wrapper{
  border: 1px solid #EAECF0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  height: calc(100% - 150px);
  color: #667085;
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
}

.chat-content.not-selected {
  border: 1px solid #EAECF0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.chat-content.not-selected span {
  font-family: euclid_medium, sans-serif;
  font-size: 14px;
  color: #667085;
}

.chat-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
  padding: 0 15px;
  height: 80px;
}

.chat-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
}

.chat-message.incoming {
  align-items: flex-start;
}


.chat-message-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat-message-wrapper.incoming {
  align-items: flex-start;
}


.chat-message-text {
  background-color: #EAECF0;
  color: #101828;
  padding: 5px 15px;
  border-radius: 24px;
  font-size: 14px;
}

.chat-message-wrapper.incoming .chat-message-text {
  background-color: #4A68F8;
  color: #FFFFFF;
}

.chat-message-time {
  font-size: 12px;
  color: #98A2B3;
  margin-right: 8px;
  margin-left: 8px;
}

.chat-input {
  width: 100%;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #F5F5F5;
  padding: 15px;
  font-family: euclid_medium, sans-serif;
  color: #010511;
}

.send-button {
  background-color: #4A68F8;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.send-button img {
  height: 22px;
  width: 22px;
}

.total-rating-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
}

.review-grid-item {
  width: 90%;
}
</style>
