<template>
  <Transition name="slide-fade" appear>
    <div v-if="visible" class="modal">
      <div class="modal-content">

        <div class="modal-header">
          <span class="modal-header-txt">Create a Session</span>
        </div>

        <div class="session-form">
          <span class="form-label">User</span>
          <SelectBox
              class="select-box"
              :options="userOptions"
              :on-select="onSelectUser"
              placeholder="Select a user for the session" />

          <div class="double-form">
            <div class="w-100">
              <span class="form-label">Date</span>
              <input type="date" class="form-input" placeholder="Date" @input="handleDate" v-model="selectedDate" />
            </div>

            <div class="w-100">
              <span class="form-label">Time</span>
              <SelectBox
                  class="select-box"
                  :options="timeOptions"
                  :loading="timesLoading"
                  :on-select="onSelectTime"
                  placeholder="Select a time for the session" />
            </div>
          </div>

          <span class="form-label">Notes (Optional)</span>
          <input class="form-input" placeholder="You can add your specific notes" v-model="note" />

        </div>


        <div class="btn-div2">
          <div></div>
          <div class="action-button" @click="handleSave">
            <span v-if="!isLoading">Save</span>
            <VueSpinnerIos v-if="isLoading" size="20" height="100%" color="#fff" />
          </div>
        </div>
        <div @click="this.onHide" class="close-btn">
          <img class="cross" src="../../assets/images/cross.svg" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import expertService from "@/service/expertService";

import {
  VueSpinnerIos
} from 'vue3-spinners'
import SelectBox from "@/components/SelectBox.vue";
import commonService from "@/service/commonService";
import {handleErrorResponse} from "@/utils/utils";

export default {
  components: {
    SelectBox,
    VueSpinnerIos
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onHide: {
      type: Function,
      default: () => {}
    },
    expertId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isLoading: false,
      userOptions: [],
      selectedUserId: null,
      selectedDate: null,
      timesLoading: false,
      timeOptions: [],
      selectedTimeSlotId: null,
      note: "",
    };
  },
  mounted() {
    commonService.getUsers().then((res) => {
      this.userOptions = res.data.data.users.map((item) => {
        return {
          value: item.id,
          text: item.name
        };
      });
    });
  },
  methods: {
    handleDate(event) {
      this.timesLoading = true;

      const date = this.convertYMDToDmy(event.target.value);

      expertService.getReservationAvailability(this.expertId, date).then((res) => {
        if (res.data.data.length > 0) {
          this.timeOptions = res.data.data.map((item) => {
            return {
              value: item.expertSlotTimeId,
              text: item.time
            };
          });
        }
        this.timesLoading = false;
      });

    },
    convertYMDToDmy(dateString) {
      const dateObject = new Date(dateString);

      const day = String(dateObject.getDate()).padStart(2, '0');
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const year = dateObject.getFullYear();

      return `${day}.${month}.${year}`;
    },
    onSelectUser(value) {
      this.selectedUserId = value;
    },
    onSelectTime(value) {
      this.selectedTimeSlotId = value;
    },
    handleSave() {
      if (!this.selectedUserId || !this.selectedDate || !this.selectedTimeSlotId || this.isLoading) {
        return;
      }

      this.isLoading = true;

      const data = {
        expertId: this.expertId,
        userId: this.selectedUserId,
        expertSlotTimeId: this.selectedTimeSlotId,
        date: this.formatDate(this.selectedDate),
        note: this.note,
      };

      expertService.addReservation(data).then((res) => {
        this.$snackbar.add({
          text: "Session created successfully",
          type: "success",
        });
        this.isLoading = false;
        this.onHide();
      }).catch((err) => {
        handleErrorResponse(err, this.$snackbar)
        this.isLoading = false;
      });
    },
    formatDate(date) {
      const dateObject = new Date(date);

      const day = String(dateObject.getDate()).padStart(2, '0');
      const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const year = dateObject.getFullYear();

      return `${day}.${month}.${year}`;
    }
  },

};
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
}

.modal-content {
  position: relative;
  background-color: white;
  width: 50%;
  height: 100%;
  border-radius: 12px;
  padding: 24px;
  overflow-y: auto;
  max-height: calc(100vh - 48px);
}

.modal-content::-webkit-scrollbar {
  width: 15px;
}

.modal-content::-webkit-scrollbar-track {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #FFF;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: rgba(4, 12, 45, 0.2);
  border-radius: 10px;
  border: 5px transparent solid;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background-color: rgba(4, 12, 45, 0.4);
}

.close-btn {
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  cursor: pointer;
  background-color: #F2F4F7;
  border-radius: 3000px;
}
.cross{
  padding: 8px;
}
.modal-header{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0 10px 0;
  border: none;
}
.modal-header-txt{
  font-family: euclid_semi_bold, sans-serif;
  color: #040C2D;
  font-size: 18px;
  font-style: normal;
  line-height: 24px;
}

.action-button{
  display: flex;
  border-radius: 24px;
  background: #040C2D;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 48px;
  cursor: pointer;
}

.action-button span {
  font-family: euclid_medium, sans-serif;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.btn-div2{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  bottom: 30px;
  left: 24px;
  right: 24px;
}


.double-form{
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;
}

.session-form {
  height: 100%;
}

.select-box {
  margin-bottom: 10px;
}
</style>
