<template>
  <div class="search-wrapper" :class="{'expanded': isExpanded}" @click="toggleSearch">
    <img v-if="isExpanded" src="@/assets/images/cross.svg" />
    <img v-if="!isExpanded" src="@/assets/images/search.svg" />
    <span class="search-txt" v-if="!isExpanded">{{ $t('search_text') }}</span>
    <input
        v-if="isExpanded"
        v-focus="isExpanded"
        @keyup.enter="onEnter"
        v-model="searchText"
        class="input"
        type="text"
        :placeholder="$t('search_text')"
        @click.stop />
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchText: '',
      isExpanded: false,
    }
  },
  watch: {
    '$route.query.searchText': {
      handler(newVal) {
        if (!newVal) return;
        this.searchText = newVal;
        this.isExpanded = newVal.length > 0;
      },
      immediate: true
    },
  },
  methods: {
    toggleSearch() {
      this.isExpanded = !this.isExpanded;

      if (!this.isExpanded) {
        this.searchText = '';
        this.$router.push({
          query: {
            ...this.$route.query,
            searchText: undefined
          }
        });
      }
    },
    onEnter() {
      if (this.searchText.length > 2) {
        this.$router.push({
          query: {
            ...this.$route.query,
            searchText: this.searchText
          }
        });
      }
    }
  }
}
</script>

<style scoped>
.search-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  min-height: 32px;
}
.search-txt {
  font-size: 12px;
  color: #344054;
  font-feature-settings: 'clig' off, 'liga' off;
  font-style: normal;
}
.expanded {
  background-color: #F9FAFB;
  border: 1px solid #EAECF0;
  border-radius: 24px;
  padding: 5px 10px;
  cursor: text;
}

input {
  font-size: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

input:focus {
  outline: none;
}
</style>
